<template>
  <div>
    <div class="overflow-hidden">
      <div class="position-absolute top-0 start-0 w-100 h-100">
        <video playsinline autoplay muted loop class="w-100 h-100 object-fit-cover video-banner">
          <source :src="require(`@/assets/images/team/hero-video.mp4`)"/>
        </video>
      </div>
      <div class="position-absolute top-0 start-0 w-100 h-50">
        <img :src="require(`@/assets/images/team/hero-bg.png`)" class="w-100 h-100 object-fit-cover" alt=""/>
      </div>
      <div class="container content-space-1 position-relative z-2">
        <div class="row justify-content-sm-center">
          <div class="col-sm-10 col-md-8 col-lg-7">
            <div class="text-center mb-7">
              <h1 class="display-5 text-white">
                {{t("general.about")}}
                {{ t("general.KnowledgeBased") }}
                <span class="text-sub d-block">{{ t("general.tarsim") }}</span>
              </h1>
              <p class="lead fs-7">
                {{ t("about.subTitle") }}
              </p>
            </div>
          </div>
        </div>
        <div class="glitch position-relative overflow-hidden mb-3 mt-5">
          <KinesisContainer>
            <div class="banner-zoom">
              <KinesisElement tag="img" strength="55" type="translate" class="img-fluid"
                              :src="require(`@/assets/images/team/final2.webp`)" alt=""/>
            </div>
          </KinesisContainer>
          <div class="glitch__layers">
            <div class="glitch__layer"></div>
            <div class="glitch__layer"></div>
            <div class="glitch__layer"></div>
          </div>
        </div>
        <div class="svg-anime">
          <svg width="100%" height="100%">
            <rect x="0" y="0" width="100%" height="100%" fill="url(#polka-dots)"></rect>
            <text x="50%" y="60%" text-anchor="middle">
              Think Out Of The Scope
            </text>
          </svg>
        </div>
      </div>
    </div>
    <div class="content-space-b-1 mt-5 pt-5 position-relative svg-parent">
      <div class="container">
        <div class="row justify-content-sm-center">
          <div class="col-sm-10 col-md-8 col-lg-7">
            <div class="mb-7">
              <h3>{{ t("about.goals") }}</h3>
              <p>
                {{t("about.goals_des")}}
              </p>
            </div>
            <div class="d-grid gap-5">
              <div class="d-flex">
                <div class="flex-shrink-0">
                <span class="svg-icon svg-icon-sm text-primary">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M18 10V20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20V10H18Z"
                          fill="#d6b36a"></path>
                    <path opacity="0.3" d="M11 10V17H6V10H4V20C4 20.6 4.4 21 5 21H12C12.6 21 13 20.6 13 20V10H11Z"
                          fill="#d6b36a"></path>
                    <path opacity="0.3" d="M10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10H10Z" fill="#d6b36a"></path>
                    <path opacity="0.3" d="M18 10C18 11.1 17.1 12 16 12C14.9 12 14 11.1 14 10H18Z"
                          fill="#d6b36a"></path>
                    <path opacity="0.3" d="M14 4H10V10H14V4Z" fill="#d6b36a"></path>
                    <path opacity="0.3" d="M17 4H20L22 10H18L17 4Z" fill="#d6b36a"></path>
                    <path opacity="0.3" d="M7 4H4L2 10H6L7 4Z" fill="#d6b36a"></path>
                    <path
                        d="M6 10C6 11.1 5.1 12 4 12C2.9 12 2 11.1 2 10H6ZM10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10H10ZM18 10C18 11.1 18.9 12 20 12C21.1 12 22 11.1 22 10H18ZM19 2H5C4.4 2 4 2.4 4 3V4H20V3C20 2.4 19.6 2 19 2ZM12 17C12 16.4 11.6 16 11 16H6C5.4 16 5 16.4 5 17C5 17.6 5.4 18 6 18H11C11.6 18 12 17.6 12 17Z"
                        fill="#d6b36a"></path>
                  </svg>
                </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <h6>{{t("about.goals_sub_one")}}</h6>
                  <p>
                    {{t("about.goals_sub_one_des")}}
                  </p>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0">
                <span class="svg-icon svg-icon-sm text-primary">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3"
                          d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                          fill="#d6b36a"></path>
                    <path
                        d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                        fill="#d6b36a"></path>
                  </svg>

                </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <h6>{{t("about.goals_sub_two")}}</h6>
                  <p>
                    {{t("about.goals_sub_two_des")}}
                  </p>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-shrink-0">
                <span class="svg-icon svg-icon-sm text-primary">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3"
                          d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM11.7 17.7L16.7 12.7C17.1 12.3 17.1 11.7 16.7 11.3C16.3 10.9 15.7 10.9 15.3 11.3L11 15.6L8.70001 13.3C8.30001 12.9 7.69999 12.9 7.29999 13.3C6.89999 13.7 6.89999 14.3 7.29999 14.7L10.3 17.7C10.5 17.9 10.8 18 11 18C11.2 18 11.5 17.9 11.7 17.7Z"
                          fill="#d6b36a"></path>
                    <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="#d6b36a"></path>
                  </svg>
                </span>
                </div>
                <div class="flex-grow-1 ms-4">
                  <h6>{{t("about.goals_sub_three")}}</h6>
                  <p>
                    {{t("about.goals_sub_three_des")}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-absolute top-0 opacity-25 start-0">
        <img class="svg-animation m-0 p-0" :src="require(`@/assets/images/team/svganime.svg`)" alt="">
      </div>
    </div>
    <div class="container-fluid mt-5">
      <div class="row align-items-end gx-2 gx-lg-3 mb-2 mb-lg-3">
        <div class="col-3 d-none d-md-block" style="margin-bottom: -5rem;">
          <img class="img-fluid w-100" :src="require(`@/assets/images/tarsim/a3.webp`)" alt="Image Description">
        </div>
        <div class="col-6 col-md-3">
          <img class="img-fluid w-100" :src="require(`@/assets/images/tarsim/a2.webp`)" alt="Image Description">
        </div>
        <div class="col-6 col-md-3">
          <img class="img-fluid w-100" :src="require(`@/assets/images/tarsim/a1.webp`)" alt="Image Description">
        </div>
        <div class="col-3 d-none d-md-block" style="margin-bottom: -5rem;">
          <img class="img-fluid w-100" :src="require(`@/assets/images/tarsim/a4.webp`)" alt="Image Description">
        </div>
      </div>
      <div class="row gx-2 gx-lg-3">
        <div class="col-6 col-md-3 offset-md-3">
          <img class="img-fluid w-100" :src="require(`@/assets/images/gallery/hamayeshEAM1403/33.webp`)" alt="Image Description">
        </div>
        <div class="col-6 col-md-3">
          <img class="img-fluid w-100" :src="require(`@/assets/images/tarsim/a5.webp`)" alt="Image Description">
        </div>
      </div>
    </div>
    <section class="container-fluid  py-5 my-md-3 my-lg-5 position-relative">
      <h2 class="h1 text-center pt-1 pb-3 mb-3 mb-lg-4">{{ t("about.team") }}</h2>
      <div class="d-flex  justify-content-center">
        <div class="grid">
          <div class="grid-baby"
               v-for="(member, idx) in randomTeamMembers"
               :key="idx">
            <div class="card card-hover border-0 bg-transparent">
              <div class="position-relative team-img mx-auto">
                <img :src="require(`@/assets/images/teamAvatar/${member.image}`)" class="rounded-circle"
                     :alt="member.name">
              </div>
              <div class="card-body text-white text-center p-3">
                <h3 class="fs-5 pt-1 mb-2" v-if="locale === 'fa'">{{ member.name }}</h3>
                <p class="fs-6 mb-0" v-if="locale === 'fa'">{{ member.role }}</p>
                <h3 class="fs-5 pt-1 mb-2" v-if="locale === 'en'">{{ member.name_en }}</h3>
                <p class="fs-6 mb-0" v-if="locale === 'en'">{{ member.role_en }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="glitch__layers team-glitch">
          <div class="glitch__layer" style="filter: grayscale(100);background-size: cover"
               :style="{ 'background-image': `url(${getRandomBackgroundImage()})` }"></div>
          <div class="glitch__layer" style="filter: grayscale(100);background-size: cover"
               :style="{ 'background-image': `url(${getRandomBackgroundImage()})` }"></div>
          <div class="glitch__layer" style="filter: grayscale(100);background-size: cover"
               :style="{ 'background-image': `url(${getRandomBackgroundImage()})` }"></div>
        </div>
      </div>
    </section>
    <div class="aa-co position-fixed end-0 m-3">
      <img class="aa-el" alt="" style="width: 50px;" :src="require(`@/assets/images/home/astro2.webp`)"/>
    </div>
  </div>
</template>

<script>
import {KinesisContainer, KinesisElement} from 'vue-kinesis'
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {useI18n} from "vue-i18n";

gsap.registerPlugin(ScrollTrigger);
export default {
  components: {KinesisContainer, KinesisElement},
  data() {
    return {
      teamMembers: [
        {
          name: 'کیوان محمودی',
          role: 'موسس، مدیرعامل، معمار نرم افزار',
          name_en: 'Kayvan Mahmoudi',
          role_en: 'Founder, CEO, Software Architect',
          email: 'ceo@tarsimdade.ir',
          linkedin: 'https://www.linkedin.com/in/kayvan-mahmoudi-60411227',
          image: 'k1.webp'
        },
        {
          name: 'محسن محمودی',
          role: 'مدیر تیم Front-End',
          name_en: 'Mohsen Mahmoudi',
          role_en: 'Front-End Team Manager',
          email: 'mohsen@tarsimdade.ir',
          linkedin: 'https://www.linkedin.com/in/mohesn-mahmoudi-203285213',
          image: 'mohsen-o.webp'
        },
        {
          name: 'مهسا تربتی',
          role: 'مدیر پروژه، تحلیل گر ارشد',
          name_en: 'Mahsa Torbati',
          role_en: 'Project Manager, Senior Analyst',
          email: 'torbati@tarsimdade.ir',
          linkedin: 'https://www.linkedin.com/in/mahsa-torbati-119b1b229',
          image: 'mahsa.webp'
        },
        {
          name: 'نیما زارعی',
          role: 'مدیر تیم استقرار نرم افزار',
          name_en: 'Nima Zarei',
          role_en: 'Software Deployment Team Manager',
          email: 'nima@tarsimdade.ir',
          linkedin: '', // No LinkedIn profile provided
          image: 'nima-o.webp'
        },
        {
          name: 'مهرداد جهانی',
          role: 'مدیر تیم پشتیبانی نرم افزار',
          name_en: 'Mehrdad Jahani',
          role_en: 'Software Support Team Manager',
          email: 'mehrdad@tarsimdade.ir',
          linkedin: 'https://www.linkedin.com/in/mehrdad-asgharnezhad-1951b321b',
          image: 'mehrdad-o.webp'
        },
        {
          name: 'مسعود دولت کیا',
          role: 'مدیر مالی',
          name_en: 'Masoud Dolat Kia',
          role_en: 'Financial Manager',
          email: 'masoud@tarsimdade.ir',
          linkedin: '', // No LinkedIn profile provided
          image: 'dolat-kia.webp'
        },
        {
          name: 'آروشا ارشدی',
          role: 'مدیر بازرگانی',
          name_en: 'Aroosha Arshadi',
          role_en: 'Commercial Manager',
          email: 'arshadi@tarsimdade.ir',
          linkedin: '',
          image: 'aroosha.webp'
        },
        {
          name: 'محمد روزبه',
          role: 'مدیر پروژه',
          name_en: 'Mohammad Rouzbeh',
          role_en: 'Project Manager',
          email: 'roozbeh@tarsimdade.ir',
          linkedin: '', // No LinkedIn profile provided
          image: 'mohammad.webp'
        },
        {
          name: 'مهدی قهرمان',
          role: 'برنامه نویس Back-End',
          name_en: 'Mehdi Ghahreman',
          role_en: 'Back-End Developer',
          email: 'mehdi@tarsimdade.ir',
          linkedin: '', // No LinkedIn profile provided
          image: 'mehdi-o.webp'
        },
        {
          name: 'ندا پور حیدری',
          role: 'برنامه نویس Back-End',
          name_en: 'Neda Pour Heydari',
          role_en: 'Back-End Developer',
          email: 'pourheydari@tarsimdade.ir',
          linkedin: 'https://www.linkedin.com/in/neda-pourheydari-ab606b21b',
          image: 'neda-o.webp'
        },
        {
          name: 'سیمین یارایی',
          role: 'گرافیست',
          name_en: 'Simin Yaraei',
          role_en: 'Graphic designer',
          email: 'yaraei@tarsimdade.ir',
          linkedin: '',
          image: 'simin.webp'
        },
        {
          name: "آریا آقاخانی",
          role: "برنامه نویس Front-End",
          name_en: 'Aria Aghakhani',
          role_en: 'Front-End Developer',
          image: "aria.webp",
          linkedin: '', // No LinkedIn profile provided
          email: "aria@tarsimdade.ir"
        },
        {
          name: 'عرفانه شکرآبی',
          role: 'تولید محتوا',
          name_en: 'Erfane Shekarabi',
          role_en: 'Content Producer',
          email: 'shekarabi@tarsimdade.ir',
          linkedin: 'https://www.linkedin.com/in/Erfane-sh-1b026b23b',
          image: 'erfane.webp'
        },
        {
          name: 'مریم نامی',
          role: 'مسئول دفتر مدیرعامل',
          name_en: 'Maryam Nami',
          role_en: 'Head of the CEO\'s office',
          email: 'nami@tarsimdade.ir',
          linkedin: '', // No LinkedIn profile provided
          image: 'nami-o.webp'
        },
        {
          name: 'سینا عربی',
          role: 'برنامه نویس Back-End',
          name_en: 'Sina Arabi',
          role_en: 'Back-End Developer',
          email: 'sina@tarsimdade.ir',
          linkedin: '', // No LinkedIn profile provided
          image: 'sina.webp'
        },
        {
          name: "علیرضا سرداری",
          role: "کارشناس پشتیبانی نرم افزار",
          name_en: 'Alireza Sardari',
          role_en: 'Software Support Expert',
          image: "sardari.webp",
          linkedin: '', // No LinkedIn profile provided
          email: "sardari@tarsimdade.ir"
        },
        {
          name: "علی غیاثیان",
          role: "کارشناس پشتیبانی نرم افزار",
          name_en: 'Ali Ghiasian',
          role_en: 'Software Support Expert',
          image: "aliq.webp",
          linkedin: '', // No LinkedIn profile provided
          email: "shayeste@tarsimdade.ir"
        },
        {
          name: "ملیحه ملائکه",
          role: "کارشناس پشتیبانی نرم افزار",
          name_en: 'Malihe Malaeke',
          role_en: 'Software Support Expert',
          image: "malihe.webp",
          linkedin: '', // No LinkedIn profile provided
          email: "malaeke@tarsimdade.ir"
        },
        {
          name: "محمد اوستاخ",
          role: "کارشناس پشتیبانی نرم افزار",
          name_en: 'Mohammad Avestakh',
          role_en: 'Software Support Expert',
          image: "avesta.webp",
          linkedin: 'https://www.linkedin.com/in/Mohammad_avestakh_a5b038151', // No LinkedIn profile provided
          email: "avestakh@tarsimdade.ir"
        },
        {
          name: "هومن میرزایی",
          role: "برنامه نویس Back-End",
          name_en: 'Hooman Mirzaei',
          role_en: 'Back-End Developer',
          image: "Hooman.webp",
          linkedin: 'https://www.linkedin.com/in/Mohammad_avestakh_a5b038151',
          email: "hooman@tarsimdade.ir"
        },
        {
          name: "امیرمسعود حیدری",
          role: "برنامه نویس Back-End",
          name_en: 'Amir Masoud Heydari',
          role_en: 'Back-End Developer',
          image: "amirmasud.webp",
          linkedin: 'https://www.linkedin.com/in/Mohammad_avestakh_a5b038151',
          email: "amirmasoud@tarsimdade.ir"
        },
        {
          name: "نیلا چناری",
          role: "برنامه نویس Back-End",
          name_en: 'Nila Chenari',
          role_en: 'Back-End Developer',
          image: "nila.webp",
          linkedin: '',
          email: "chenari@tarsimdade.ir"
        },
        {
          name: "پوریا رضوی",
          role: "کارشناس پشتیبانی نرم افزار",
          name_en: 'Pouria Razavi',
          role_en: 'Software Support Expert',
          image: "poorya.webp",
          linkedin: 'https://www.linkedin.com/in/Mr-pouria',
          email: "pooria@tarsimdade.ir"
        },
        {
          name: "مهران بهارلویی",
          role: "کارشناس پشتیبانی نرم افزار",
          name_en: 'Mehran Baharloo',
          role_en: 'Software Support Expert',
          image: "mehran.webp",
          linkedin: 'https://www.linkedin.com/in/Mehran-baharlooyi',
          email: "mehran@tarsimdade.ir"
        },
        {
          name: "مصطفی سهرابی",
          role: "کارشناس پشتیبانی نرم افزار",
          name_en: 'Mostafa Sohrabi',
          role_en: 'Software Support Expert',
          image: "mostafa.webp",
          linkedin: '', // No LinkedIn profile provided
          email: "sohrabi@tarsimdade.ir"
        },
        {
          name: 'نگار زارعی',
          role: ' ',
          name_en: 'Negar Zarei',
          role_en: '',
          email: 'zarei@tarsimdade.ir',
          linkedin: '', // No LinkedIn profile provided
          image: 'negar.webp'
        },
      ],
      randomTeamMembers: [],
      backgroundImages: [
        require('@/assets/images/team/bgt-1.webp'),
        require('@/assets/images/team/bgt-2.webp'),
        require('@/assets/images/team/bgt-3.webp'),
        require('@/assets/images/team/bgt-4.webp'),
        require('@/assets/images/team/bgt-5.webp'),
      ],
    };
  },
  setup() {
    const {t, locale} = useI18n();

    return {
      t,
      locale,
    };
  },
  mounted() {
    this.scrollAnimation2();
    this.randomizeTeamAppearance();
    setInterval(this.smoothShuffle, 5000);
  },
  methods: {
    getRandomBackgroundImage() {
      const randomIndex = Math.floor(Math.random() * this.backgroundImages.length);
      return this.backgroundImages[randomIndex];
    },
    randomizeTeamAppearance() {
      let shuffledTeam = this.teamMembers.slice();
      let currentIndex = shuffledTeam.length,
          randomIndex;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [shuffledTeam[currentIndex], shuffledTeam[randomIndex]] = [
          shuffledTeam[randomIndex],
          shuffledTeam[currentIndex]
        ];
      }
      this.randomTeamMembers = shuffledTeam.slice(0, 9);
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.randomTeamMembers = shuffledTeam.slice(0, 8);
      }
    },
    smoothShuffle() {
      const gridItems = document.querySelectorAll('.grid-baby');
      gridItems.forEach((item) => {
        item.classList.remove('fade-in');
        item.classList.add('fade-out');
      });

      setTimeout(() => {
        this.randomizeTeamAppearance();

        gridItems.forEach((item) => {
          item.classList.remove('fade-out');
          item.classList.add('fade-in');
        });
      }, 500);
    },
    scrollAnimation2() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        document.body.style.overflowX = 'hidden';
      }
      gsap.timeline({
        scrollTrigger: {
          trigger: ".svg-parent",
          start: '-100px center',
          end: '+200px center',
          scrub: true,
        }
      })
          .from(".svg-animation", {
            opacity: 0, scale: .2, x: -300,
            stagger: {
              amount: 9,
              ease: 'Power3.easeOut',
              from: "random"
            }
          });

      gsap.timeline({
        scrollTrigger: {
          trigger: "html",
          scrub: 0.2,
          start: "top top",
          end: "+=10000"
        }
      })
          .to(".aa-el", {
            rotation: 360 * 5,
            duration: 1,
            ease: "none"
          });
    }
  },
  watch: {
    $route(to, from) {
      this.scrollAnimation2();
    }
  }
}
</script>

<style lang="scss" scoped>
.aa-co {
  animation: float linear 2.5s infinite alternate;
  bottom: 28px;
  z-index: 11;
}

@keyframes float {
  0% {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(0, 30px, 0);
  }
}

.content-space-b-1 {
  padding-bottom: 4rem !important;
}

.content-space-1 {
  padding-top: 6rem !important;
  padding-bottom: 4rem !important;
}

.team-img {
  width: 150px;
  height: 150px;

  &:hover::before {
    opacity: 1;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: opacity .5s ease-out;
    transition: opacity .5s ease-out;
    background-color: #efcfc1;
    opacity: 0;
    mix-blend-mode: exclusion;
    z-index: 1;
    border-radius: 50%;
  }
}

.btn-social {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #fff !important;
    background-color: #0077b5 !important;
    box-shadow: 0 .375rem 1.125rem -0.375rem rgba(0, 119, 181, .8) !important;
  }
}

.card-img-overlay {
  z-index: 5;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.card-hover:hover .card-img-overlay {
  opacity: 1;
}

.banner-zoom {
  position: relative;
  z-index: 8;
  transition: all .5s;

  &:hover {
    transform: scale(1.2);
  }

  &:hover:before {
    opacity: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: opacity .5s ease-out;
    transition: opacity .5s ease-out;
    background-color: #efcfc1;
    opacity: 1;
    mix-blend-mode: exclusion;
    z-index: 1;
  }
}

.video-banner {
  filter: blur(100px) grayscale(100);
}

.svg-anime {
  svg text {
    font-family: Pelak, sans-serif;
    letter-spacing: 3px;
    stroke: #D6B36AFF;
    font-size: 70px;
    font-weight: 700;
    stroke-width: 3;
    animation: textAnimate 5s infinite alternate;
  }

  @keyframes textAnimate {
    0% {
      stroke-dasharray: 0 50%;
      stroke-dashoffset: 20%;
      fill: hsl(0, 0%, 100%)

    }

    100% {
      stroke-dasharray: 50% 0;
      stroke-dashoffstet: -20%;
      fill: hsla(189, 68%, 75%, 0%)
    }

  }
}

.glitch__layers {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.glitch__layer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url('@/assets/images/team/final2.webp');
  background-repeat: no-repeat;
  background-position: 0 0;
}

.glitch__layer:nth-child(1) {
  transform: translateX(-5%);
  animation: glitch-anim-1 2s infinite linear alternate;
}

.glitch__layer:nth-child(2) {
  transform: translateX(3%) translateY(3%);
  animation: glitch-anim-2 2.3s -.8s infinite linear alternate;
}

.glitch__layer:nth-child(3) {
  transform: translateX(5%);
  animation: glitch-anim-flash 1s infinite linear;
}

@keyframes glitch-anim-1 {
  0% {
    clip-path: polygon(0 0%, 100% 0%, 100% 5%, 0 5%);
  }
  10% {
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
  }
  20% {
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
  }
  30% {
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
  }
  40% {
    clip-path: polygon(0 35%, 100% 35%, 100% 35%, 0 35%);
  }
  50% {
    clip-path: polygon(0 45%, 100% 45%, 100% 46%, 0 46%);
  }
  60% {
    clip-path: polygon(0 50%, 100% 50%, 100% 70%, 0 70%);
  }
  70% {
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
  }
  80% {
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
  90% {
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }
  100% {
    clip-path: polygon(0 60%, 100% 60%, 100% 70%, 0 70%);
  }
}

@keyframes glitch-anim-2 {
  0% {
    clip-path: polygon(0 15%, 100% 15%, 100% 30%, 0 30%);
  }
  15% {
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
  }
  25% {
    clip-path: polygon(0 8%, 100% 8%, 100% 20%, 0 20%);
  }
  30% {
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  45% {
    clip-path: polygon(0 45%, 100% 45%, 100% 45%, 0 45%);
  }
  50% {
    clip-path: polygon(0 50%, 100% 50%, 100% 57%, 0 57%);
  }
  65% {
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  75% {
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
  80% {
    clip-path: polygon(0 40%, 100% 40%, 100% 60%, 0 60%);
  }
  95% {
    clip-path: polygon(0 45%, 100% 45%, 100% 60%, 0 60%);
  }
  100% {
    clip-path: polygon(0 11%, 100% 11%, 100% 15%, 0 15%);
  }
}

@keyframes glitch-anim-flash {
  0% {
    opacity: .2;
  }
  30%, 100% {
    opacity: 0;
  }
}

.glitch:hover {
  .glitch__layers {
    display: none;
  }
}


.grid {
  perspective: 600px;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-template-rows: repeat(3, auto);
  grid-gap: 20px;
}

.grid-baby {
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 500ms ease-in-out;
  filter: grayscale(100);
}

.grid-baby.fade-out {
  opacity: 0;
}

.grid-baby.fade-in {
  opacity: 1;
}


.grid-baby:nth-child(odd) {
  animation: pulse 1000ms ease-in-out infinite alternate;
}

.grid-baby:nth-child(even) {
  animation: pulse 1000ms 1000ms ease-in-out infinite alternate;
}


@keyframes pulse {
  to {
    transform: translate3d(0px, 0px, -60px);
    filter: none;
  }
}

@media only screen and (max-width: 991px) {
  .svg-anime svg text {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 644px) {
  .svg-anime svg text {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 740px) {
  .team-img {
    width: 100px;
    height: 100px;
  }
  .grid-baby .fs-5 {
    font-size: 1rem !important;
  }
  .grid-baby .fs-6 {
    font-size: 0.8rem !important;
  }
  .team-glitch {
    display: none;
  }
  .grid {
    grid-template-columns: repeat(2, 50%);
  }
}

@media only screen and (max-width: 444px) {
  .svg-anime svg text {
    font-size: 1rem;
  }
}
</style>