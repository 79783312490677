<template>
  <div class="d-xl-none d-block mt-5 p-2">
    <h1 class="h1 text-center">ESB</h1>
    <h2 class="h2 text-center">{{ t('esb.title') }}</h2>
    <img :src="require(`@/assets/images/esb/esb-1.webp`)" alt="">
    <p class="text-justify lh-lg">
      {{t('esb.section_one_para_one')}}
    </p>
    <p class="text-justify lh-lg">
      {{t('esb.section_one_para_two')}}
    </p>
    <h2 class="h2 text-center mt-2">{{t('esb.section_two_title')}}</h2>
    <img class="mt-3" :src="require(`@/assets/images/esb/output.gif`)" alt="">
    <p class="text-justify lh-lg">
      <span class="text-sub">{{t('esb.systemIntegration.span')}}</span>
      {{t('esb.systemIntegration.description')}}
    </p>
    <p class="text-justify lh-lg">
      <span class="text-sub">{{t('esb.dataExchange.span')}}</span>
      {{t('esb.dataExchange.description')}}
    </p>
    <p class="text-justify lh-lg">
      <span class="text-sub">{{t('esb.protocolManagement.span')}}</span>
      {{t('esb.protocolManagement.description')}}
    </p>
    <p class="text-justify lh-lg">
      <span class="text-sub">{{t('esb.eventManagement.span')}}</span>
      {{t('esb.eventManagement.description')}}
    </p>
    <p class="text-justify lh-lg">
      <span class="text-sub">{{t('esb.messageExchange.span')}}</span>
      {{t('esb.messageExchange.description')}}
    </p>
    <p class="text-justify lh-lg">
      <span class="text-sub">{{t('esb.securityManagement.span')}}</span>
      {{t('esb.securityManagement.description')}}
    </p>
    <p class="text-justify lh-lg">
      <span class="text-sub">{{t('esb.developmentAndFlexibility.span')}}</span>
      {{t('esb.developmentAndFlexibility.description')}}
    </p>
    <h2 class="h2 text-center mt-2">{{t('esb.section_three_title')}}</h2>
    <img class="mt-3" :src="require(`@/assets/images/esb/esb-2.webp`)" alt="">
    <p class="text-justify lh-lg">
      {{t('esb.section_three_para_extra')}}
    </p>
    <p class="text-justify lh-lg">
      {{t('esb.section_three_para_one')}}
    </p>
    <p class="text-justify lh-lg">
      {{t('esb.section_three_para_two')}}
    </p>
  </div>
  <div class="vh-100 overflow-hidden d-none d-xl-block">
    <div class="esb-background d-none d-xl-block">
      <video class="esb-background-video" ref="video0" :class="{'visible': activeIndex === -1}"
             :data-src="require(`@/assets/images/esb/intro.mp4`)"
             :poster="require(`@/assets/images/esb/intro.jpg`)"
             data-object-fit="cover" muted preload="auto" playsinline data-id="intro"
             :src="require(`@/assets/images/esb/intro.mp4`)">
      </video>
      <video class="esb-background-video" :class="{'visible':activeIndex === 1 && previousIndex === 0}" ref="video1"
             :data-src="require(`@/assets/images/esb/1-2.mp4`)"
             :poster="require(`@/assets/images/esb/intro.jpg`)"
             data-object-fit="cover" muted preload="auto" playsinline data-id="intro"
             :src="require(`@/assets/images/esb/1-2.mp4`)">
      </video>
      <video class="esb-background-video" :class="{'visible':activeIndex === 1 && previousIndex === 2}" ref="video32"
             :data-src="require(`@/assets/images/esb/1-2.mp4`)"
             :poster="require(`@/assets/images/esb/intro.jpg`)"
             data-object-fit="cover" muted preload="auto" playsinline data-id="intro"
             :src="require(`@/assets/images/esb/3-2.mp4`)">
      </video>
      <video class="esb-background-video" :class="{'visible':activeIndex === 2}" ref="video23"
             :data-src="require(`@/assets/images/esb/1-2.mp4`)"
             :poster="require(`@/assets/images/esb/intro.jpg`)"
             data-object-fit="cover" muted preload="auto" playsinline data-id="intro"
             :src="require(`@/assets/images/esb/2-3.mp4`)">
      </video>
      <video class="esb-background-video" :class="{'visible':activeIndex === 0}" ref="video21"
             :data-src="require(`@/assets/images/esb/1-2.mp4`)"
             :poster="require(`@/assets/images/esb/intro.jpg`)"
             data-object-fit="cover" muted preload="auto" playsinline data-id="intro"
             :src="require(`@/assets/images/esb/2-1.mp4`)">
      </video>
    </div>
    <div class="esb-index d-none d-xl-block">
      <span class="esb-index-total" style="opacity: 1;">
        <span class="esb-index-value" style="visibility: inherit; transform: matrix(1, 0, 0, 1, 0, 0);">3</span>
      </span>
      <span class="esb-index-separator ms-2 me-1" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);"> / </span>
      <span class="esb-index-current">
      <span class="esb-index-value"
            style="visibility: inherit; transform: matrix(1, 0, 0, 1, 0, 0);">{{ currentIndex + 1 }}</span>
    </span>
    </div>
    <div class="vert-text home__screen__vert-text overflow-hidden d-none d-xl-block">
      <div class="vert-text__inner" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
        <span class="vert-text__word">Tarsim Dadeh Afzar</span>
        <span class="vert-text__word">Tarsim Dadeh Afzar</span>
        <span class="vert-text__word">Tarsim Dadeh Afzar</span>
      </div>
    </div>
    <swiper
        :direction="'vertical'"
        :slidesPerView="1"
        :spaceBetween="0"
        :mousewheel="true"
        :keyboard="{
          enabled: true,
        }"
        :pagination="false"
        :modules="modules"
        class="mySwiper d-none d-xl-block"
        @swiper="intro"
        @slideChange="onSwiper">
      <swiper-slide>
        <div class="container h-100">
          <div class=" row d-flex flex-column flex-lg-row align-items-center h-100 position-relative z-2">
            <div class="col-xl-4" v-if="locale === 'en'"></div>
            <div class="col-xl-4">
              <h1 class="h1 text-center from-top">ESB</h1>
              <h2 class="h2 text-center from-start">{{ t('esb.title') }}</h2>
              <img class="from-bottom" :src="require(`@/assets/images/esb/esb-1.webp`)" alt="">
            </div>
            <div class="col-xl-4">
              <p class="text-justify lh-lg from-bottom">
                {{t('esb.section_one_para_one')}}
              </p>
              <p class="text-justify lh-lg from-bottom">
                {{t('esb.section_one_para_two')}}
              </p>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="container h-100">
          <div class="row d-flex flex-column flex-lg-row align-items-center h-100 position-relative z-2">
            <div class="col-xl-4" v-if="locale === 'en'"></div>
            <div class="col-xl-4">
              <h2 class="h2 text-center from-top">{{t('esb.section_two_title')}}</h2>
              <img class="from-bottom mt-3" :src="require(`@/assets/images/esb/output.gif`)" alt="">
            </div>
            <div class="col-xl-4">
              <p class="text-justify lh-lg from-bottom">
                <span class="text-sub">{{t('esb.systemIntegration.span')}}</span>
                {{t('esb.systemIntegration.description')}}
              </p>
              <p class="text-justify lh-lg from-bottom">
                <span class="text-sub">{{t('esb.dataExchange.span')}}</span>
                {{t('esb.dataExchange.description')}}
              </p>
              <p class="text-justify lh-lg from-bottom">
                <span class="text-sub">{{t('esb.protocolManagement.span')}}</span>
                {{t('esb.protocolManagement.description')}}
              </p>
              <p class="text-justify lh-lg from-bottom">
                <span class="text-sub">{{t('esb.eventManagement.span')}}</span>
                {{t('esb.eventManagement.description')}}
              </p>
              <p class="text-justify lh-lg from-bottom">
                <span class="text-sub">{{t('esb.messageExchange.span')}}</span>
                {{t('esb.messageExchange.description')}}
              </p>
              <p class="text-justify lh-lg from-bottom">
                <span class="text-sub">{{t('esb.securityManagement.span')}}</span>
                {{t('esb.securityManagement.description')}}
              </p>
              <p class="text-justify lh-lg from-bottom">
                <span class="text-sub">{{t('esb.developmentAndFlexibility.span')}}</span>
                {{t('esb.developmentAndFlexibility.description')}}
              </p>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="container h-100">
          <div class="row d-flex flex-column flex-lg-row align-items-center h-100 position-relative z-2">
            <div class="col-xl-4" v-if="locale === 'en'"></div>
            <div class="col-xl-4">
              <h2 class="h2 text-center from-top">{{t('esb.section_three_title')}}</h2>
              <img class="from-bottom mt-3" :src="require(`@/assets/images/esb/esb-2.webp`)" alt="">
            </div>
            <div class="col-xl-4">
              <p class="text-justify lh-lg from-bottom">
                {{t('esb.section_three_para_extra')}}
              </p>
              <p class="text-justify lh-lg from-bottom">
                {{t('esb.section_three_para_one')}}
              </p>
              <p class="text-justify lh-lg from-bottom">
                {{t('esb.section_three_para_two')}}
              </p>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="aa-co position-fixed end-0 m-3 z-3 d-none d-xl-block">
      <img class="aa-el" alt="" style="width: 50px;" :src="require(`@/assets/images/home/astro2.webp`)"/>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import {Autoplay, EffectCoverflow, Keyboard, Mousewheel, Navigation, Pagination} from 'swiper/modules';
import {gsap} from "gsap";
import {useI18n} from "vue-i18n";
import {ref} from "vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      activeIndex: -1,
      currentIndex: 0,
      previousIndex: 0,
      tween: null,
    }
  },
  setup() {
    const {t, locale} = useI18n();

    return {
      t,
      locale,
      modules: [Keyboard, Mousewheel, Pagination],
    };
  },
  mounted() {
    this.initAnimation();
    this.scrollAnimation2();
  },
  methods: {
    initAnimation() {
      this.tween = gsap.to(".vert-text__word", {
        xPercent: -100,
        repeat: -1,
        duration: 10,
        ease: "linear"
      }).totalProgress(0.5);
      gsap.set(".vert-text__inner", {xPercent: -50});
    },
    scrollAnimation2() {
      gsap.timeline({
        scrollTrigger: {
          trigger: "html",
          scrub: 0.2,
          start: "top top",
          end: "+=10000"
        }
      })
          .to(".aa-el", {
            rotation: 360 * 5,
            duration: 1,
            ease: "none"
          })
    },
    intro() {
      let video0 = this.$refs.video0;
      video0.play();
    },
    onSwiper(swiper) {
      this.activeIndex = swiper.activeIndex;
      this.currentIndex = swiper.activeIndex;
      this.previousIndex = swiper.previousIndex
      let video1 = this.$refs.video1;
      let video21 = this.$refs.video21;
      let video23 = this.$refs.video23;
      let video32 = this.$refs.video32;
      if (this.activeIndex === 0) {
        video21.play();
      }
      if (this.activeIndex === 1 && this.previousIndex === 0) {
        video1.play();
      }
      if (this.activeIndex === 1 && this.previousIndex === 2) {
        video32.play();
      }
      if (this.activeIndex === 2) {
        video23.play();
      }
    },
  },
  watch: {
    $route(to, from) {
      this.initAnimation();
      this.scrollAnimation2();
    }
  },
  beforeUnmount() {
    if (this.tween) {
      this.tween.kill();
    }
  }
}
</script>

<style lang="scss" scoped>
.aa-co {
  animation: float linear 2.5s infinite alternate;
  bottom: 28px;
  z-index: 11;
  filter: grayscale(100);
}

@keyframes float {
  0% {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(0, 30px, 0);
  }
}

.home__screen__vert-text {
  display: none
}

.vert-text {
  position: fixed;
  bottom: 0;
  display: block;
  font-weight: 300;
  line-height: 1em;
  color: hsla(0, 0%, 58.4%, .15);
  white-space: nowrap;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
  -webkit-transform: rotate(-90deg) translate(0, 100%);
  transform: rotate(-90deg) translate(0, 100%)
}

@supports (-webkit-text-stroke:1px #959595) {
  .vert-text {
    -webkit-text-stroke: 1px #959595;
    color: transparent
  }
}

.vert-text__word {
  display: inline-block
}

@media screen and (min-width: 600px) {
  .home__screen__vert-text {
    display: block;
    left: 10.775vw;
    font-size: 21vh
  }
}

@supports (-webkit-text-stroke:1px #676767) {
  .home__screen__vert-text {
    -webkit-text-stroke-color: #676767
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}

.from-top, .from-bottom, .from-start, .from-end, .scale-up, .scale-down {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 2.4s .8s ease-in-out;
}

.from-bottom {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 4.4s 1.4s ease-in-out;
}

.scale-up, .scale-down {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all .4s .4s ease-in-out;
}

.scale-up {
  transform: scale(0.85);

}

.from-end {
  transform: translateX(-12.25rem);
}

.from-top {
  transform: translateY(-12.25rem);
}

.from-bottom {
  transform: translateY(12.25rem);
}

.from-start {
  transform: translateX(12.25rem);
}

.swiper-slide-active .scale-up, .swiper-slide-active .scale-down, .active .scale-up, .active .scale-down {
  opacity: 1;
  transform: scale(1);
}

.swiper-slide-active .from-start, .swiper-slide-active .from-end, .active .from-start, .active .from-end {
  opacity: 1;
  transform: translateX(0);
}

.swiper-slide-active .from-top, .swiper-slide-active .from-bottom, .active .from-top, .active .from-bottom {
  opacity: 1;
  transform: translateY(0);
}

.esb-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba(42, 43, 46, .5);
  }
}

.esb-background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  visibility: hidden;
}

.esb-index {
  height: 50px;
  padding-left: 11.11vw;
  font-size: 11px;
  letter-spacing: .3em;
  line-height: 50px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  mask-image: linear-gradient(180deg, rgba(26, 27, 28, 0) 0, #1a1b1c 20px, #1a1b1c calc(100% - 20px), rgba(26, 27, 28, 0))
}

@media screen and (min-width: 600px) {
  .esb-index {
    position: absolute;
    top: calc(50% - 25px);
    right: 1.15vw;
    padding-left: 0;
    margin-bottom: 0
  }
}

.esb-index-current {
  color: #fff;
}

.esb-index-current,
.esb-index-total {
  position: relative;
  display: inline-block;
}

.esb-index-value {
  display: inline-block
}

.esb-index-separator {
  display: inline-block
}
</style>