<template>
  <div class="home-wrapper">
    <div class="main-title">
      <div
          class="container d-flex flex-column justify-content-center position-relative z-2 pt-sm-3 pt-md-4 pt-xl-5 pb-1 pb-sm-3 pb-lg-4 pb-xl-5">
        <KinesisContainer class="row flex-lg-nowrap align-items-center pb-5 pt-2 pt-lg-4 pt-xl-0 mt-lg-4 mt-xl-0">
          <div class="col-lg-7 order-lg-2 ms-lg-4 mb-2 mb-lg-0 position-relative">
            <div>
              <div id="astronaut">
                <img :src="require(`@/assets/images/astro/astro-two.webp`)" alt="" width="250">
              </div>

              <div id="astronaut2">
                <img :src="require(`@/assets/images/astro/astro-one.webp`)" alt="" width="250">
              </div>
            </div>
            <div class="parallax order-lg-2 mx-auto">
              <KinesisElement :strength="-15" type="depth" class="parallax-layer layer-general position-relative">
                <img :src="require(`@/assets/images/home/01.png`)" alt="Layer">
              </KinesisElement>
              <KinesisElement :strength="-5" type="depth" class="parallax-layer layer-general">
                <img :src="require(`@/assets/images/home/02.png`)" class="rotate-cw" alt="Layer">
              </KinesisElement>
            </div>
          </div>
          <div class="col-lg-5 order-lg-1 text-center text-lg-start me-xl-5 p-0">
            <div class="typewriter per">
              <h1>{{ t('general.KnowledgeBased') }}</h1>
            </div>
            <div class="typewriter">
              <h1>Tarsim Dade Afzar</h1>
            </div>
            <div class="typewriter per">
              <h1>ترسیم داده افزار</h1>
            </div>
            <div class="typewriter">
              <h1>Think out of the scope</h1>
            </div>
          </div>
        </KinesisContainer>
      </div>
    </div>
    <section class="py-5 position-relative z-3">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-9 d-flex align-items-center svg-parent position-relative">
            <div class="svg-animation p-5">
              <img :src="require(`@/assets/images/home/logo-fan2.webp`)" alt="" width="350">
              <p class="mt-3 pb-1 pb-lg-0 mb-4 mb-lg-5 text-justify fs-5 lh-lg">
                {{ t('home.aboutTarsim') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-5 position-relative z-3">
      <div class="container mt-1 py-md-2 py-lg-4 py-xxl-5">
        <h2 class="h1 text-center pt-1 pt-sm-4">
          {{ t('home.productIntroduction') }}
        </h2>
        <p class="text-center mx-auto pb-3 mb-3 mb-lg-4" style="max-width: 480px;" v-if="locale === 'fa'">
          در این بخش میتوانید با برخی از محصولات ما آشنا شوید، در صورت داشتن هرگونه سوال، نیاز به توضیحات بیشتر و یا
          درخواست دمو
          <router-link class="text-sub" to="/contact">با ما تماس</router-link>
          بگیرید.
        </p>
        <p class="text-center mx-auto pb-3 mb-3 mb-lg-4" style="max-width: 480px;" v-if="locale === 'en'">
          In this section, you can get acquainted with some of our products. If you have any questions, need further
          explanation, or request a demo, please
          <router-link class="text-sub" to="/contact">contact us</router-link>
          .
        </p>
        <div class="row g-4 pb-2 pb-sm-4 mb-sm-2">
          <div class="col-lg-5">
            <div class="card border-0 h-100">
              <div class="card-body d-flex flex-column justify-content-center">
                <router-link to="/xstone" class="opacity-background">
                  <img :src="require(`@/assets/images/home/01-dark.png`)" alt="Image">
                </router-link>
                <div class="pt-3 mt-2 mt-lg-3">
                  <h3>X-stone</h3>
                  <p class="mb-0 pb-3">
                    {{ t('home.xstone_des') }}
                  </p>
                  <router-link class="btn btn-link p-0" to="/xstone">
                    {{ t('general.moreInfo') }}
                    <span class="display-flex" v-show="locale === 'fa'">
                      <i class="fas fa-arrow-left ms-2"></i>
                    </span>
                    <span class="display-flex" v-show="locale === 'en'">
                      <i class="fas fa-arrow-right ms-2"></i>
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="card border-0 h-100">
              <div class="card-body overflow-hidden d-flex flex-column justify-content-center">
                <div class="d-sm-flex align-items-center">
                  <router-link class="d-block order-sm-2 flex-shrink-0 mt-n4 mb-2" to="/tarsimMate">
                    <img :src="require(`@/assets/images/home/02-light.png`)" width="308" alt="Image">
                  </router-link>
                  <div class="order-sm-1 pe-sm-3 me-xl-4">
                    <h3>{{ t('home.tarsimate') }}</h3>
                    <p class="pb-3 mb-1 mb-xl-3">
                      {{ t('home.tarsimate_des') }}
                    </p>
                    <router-link class="btn btn-link p-0" to="/tarsimMate">
                      {{ t('general.moreInfo') }}
                      <span class="display-flex" v-show="locale === 'fa'">
                      <i class="fas fa-arrow-left ms-2"></i>
                    </span>
                      <span class="display-flex" v-show="locale === 'en'">
                      <i class="fas fa-arrow-right ms-2"></i>
                    </span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="card border-0 h-100">
              <div class="card-body overflow-hidden d-flex flex-column justify-content-center">
                <div class="d-sm-flex align-items-center">
                  <router-link class="d-block order-sm-2 flex-shrink-0 mt-n4 mb-2" to="/tarsimSystem">
                    <img :src="require(`@/assets/images/home/03-dark.png`)" alt="Image" width="308">
                  </router-link>
                  <div class="order-sm-1 pe-sm-3 me-xl-4">
                    <h3>{{ t('home.tarsimSystem') }}</h3>
                    <p class="pb-3 mb-1 mb-xl-3">
                      {{ t('home.tarsimSystem_des') }}
                    </p>
                    <router-link class="btn btn-link p-0" to="/tarsimSystem">
                      {{ t('general.moreInfo') }}
                      <span class="display-flex" v-show="locale === 'fa'">
                      <i class="fas fa-arrow-left ms-2"></i>
                    </span>
                      <span class="display-flex" v-show="locale === 'en'">
                      <i class="fas fa-arrow-right ms-2"></i>
                    </span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="card border-0 h-100">
              <div class="card-body d-flex flex-column justify-content-center">
                <router-link to="/esb" class="opacity-background">
                  <img :src="require(`@/assets/images/home/04-dark.png`)" alt="Image">
                </router-link>
                <div class="pt-3 mt-2 mt-lg-3">
                  <h3>ESB (Enterprise Service Bus)</h3>
                  <p class="mb-0 pb-3">
                    {{ t('home.esb_des') }}
                  </p>
                  <router-link class="btn btn-link p-0" to="/esb">
                    {{ t('general.moreInfo') }}
                    <span class="display-flex" v-show="locale === 'fa'">
                      <i class="fas fa-arrow-left ms-2"></i>
                    </span>
                    <span class="display-flex" v-show="locale === 'en'">
                      <i class="fas fa-arrow-right ms-2"></i>
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="pin-wrapper z-3">
      <section class="py-5 position-relative z-3" id="panels">
        <div id="panels-container" ref="panelsContainer" style="width: 500%;">
          <article id="panel-1" class="panel full-screen position-relative">
            <video class="position-absolute video-faded" width="1920" playsinline autoplay="autoplay"
                   loop="loop" muted
                   :src="require(`@/assets/video/Site1.mp4`)"></video>
            <video class="position-relative z-2" width="1920" playsinline autoplay="autoplay" loop="loop" muted
                   :src="require(`@/assets/video/Site1.mp4`)"></video>
          </article>
          <article id="panel-2" class="panel full-screen">
            <video class="position-absolute video-faded" width="1920" height="1080" playsinline autoplay="autoplay"
                   loop="loop" muted
                   :src="require(`@/assets/video/Film02.mp4`)"></video>
            <video class="position-relative z-2" width="1920" playsinline autoplay="autoplay" loop="loop" muted
                   :src="require(`@/assets/video/Film02.mp4`)"></video>
          </article>
          <article id="panel-3" class="panel full-screen">
            <img class="object-fit-contain h-100" :src="require(`@/assets/images/home/para-1.webp`)" alt="">
            <img class="object-fit-contain h-100" :src="require(`@/assets/images/home/para-2.webp`)" alt="">
          </article>
          <article id="panel-4" class="panel full-screen">
            <img class="object-fit-contain h-100" :src="require(`@/assets/images/home/para-6.webp`)" alt="">
            <img class="object-fit-contain h-100" :src="require(`@/assets/images/home/para-5.webp`)" alt="">

          </article>
          <article id="panel-5" class="panel full-screen">
            <picture class="base-image">
              <img class="object-fit-contain h-100 ms-0" :src="require(`@/assets/images/home/para-4.webp`)" alt="">
            </picture>
          </article>
        </div>
      </section>
    </div>
    <section class="py-5  overflow-hidden position-relative z-3">
      <h2 class="h1 text-center pb-3 mb-3 mb-lg-4">{{ t("home.customers") }}</h2>
      <swiper
          dir="ltr"
          :slidesPerView="8"
          :spaceBetween="10"
          :pagination="false"
          :breakpoints="breakpoints"
          :modules="modules"
          :loop="true"
          :autoplay="{
          delay: 0,
          pauseOnMouseEnter: true,
          disableOnInteraction: false
          }"
          :speed="9000"
          class="mySwiperHome">
        <swiper-slide v-for="(item,index) in customersImageTop" :key="index">
          <img :src="require(`@/assets/images/customers/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
      <swiper
          dir="rtl"
          :slidesPerView="8"
          :spaceBetween="10"
          :pagination="false"
          :breakpoints="breakpoints"
          :modules="modules"
          :loop="true"
          :autoplay="{
          delay: 0,
          pauseOnMouseEnter: true,
          disableOnInteraction: false
          }"
          :speed="9000"
          class="mySwiperHome mt-4">
        <swiper-slide v-for="(item,index) in customersImageBottom" :key="index">
          <img :src="require(`@/assets/images/customers/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
    </section>
    <section class="py-5 container overflow-hidden position-relative z-3">
      <div class="row align-items-center mt-2 pt-2 pt-sm-4 pt-lg-5">
        <div class="col-md-5 text-center text-md-start pe-md-5 pe-lg-0 pe-xxl-4 mb-5 mb-md-0">
          <h2 class="h4 lh-base pb-3 pb-xl-2 mb-lg-4 mb-xl-5 text-justify">
            {{ t("home.aboutTarsimBottom") }}
          </h2>
        </div>
        <div class="col-md-7 col-lg-6 offset-lg-1">
          <div class="position-relative mx-auto" style="max-width: 510px;" ref="imageContainer">
            <img class="rotate-cw" :src="require(`@/assets/images/home/circle-light-1.png`)" alt="Circle">
            <img class="position-absolute top-0 start-0 rotate-ccw"
                 :src="require(`@/assets/images/home/circle-light-2.png`)"
                 alt="Circle">
            <img v-for="(image, index) in images" :key="index" :src="image.src" :alt="image.alt"
                 class="position-absolute top-0 start-0 z-2 fade-in">
          </div>
        </div>
      </div>
    </section>
    <section class="pt-2 pt-md-0 mt-5 last-section">
      <div class="position-relative pt-lg-3 pt-xl-4 pt-xxl-5">
        <img class="position-absolute top-0 z-2 astro" :src="require(`@/assets/images/home/astro2.webp`)" alt="">
        <div class="bg-primary position-absolute top-0 start-0 w-100 h-100 rounded-5 d-md-none"></div>
        <div class="bg-primary position-absolute top-0 start-0 w-100 h-100 d-none d-md-block"
             style="border-radius: 5rem 5rem 0 0;"></div>
        <div
            class="container px-4 px-sm-5 px-md-0 position-relative z-2 pt-md-4 pt-lg-5 pb-sm-2 pb-md-3 pb-xl-4 pb-xxl-5"
            data-bs-theme="dark">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8 col-xl-7 col-xxl-6 text-center py-5">
              <h2 class="display-1 mb-4">{{ t("home.experience") }}</h2>
              <p class="text-body fs-xl pb-3 mb-3 mb-lg-4">
                {{ t("home.experienceSub") }}
              </p>
              <div class="d-sm-flex justify-content-center">
                <router-link class="btn btn-lg btn-outline-warning rounded-pill  w-sm-auto" to="/contact">
                  {{ t("home.demo") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="position-relative z-2 px-md-5">
          <img class="mx-auto" :src="require(`@/assets/images/home/dash-dark.png`)" width="1076" alt="Image">
        </div>
      </div>
    </section>
    <div v-if="!isMobile">
      <LabBackground/>
    </div>
  </div>
</template>

<script>
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import {Autoplay, Mousewheel, Pagination} from 'swiper/modules';
import {KinesisContainer, KinesisElement} from 'vue-kinesis'
import LabBackground from "@/components/LabBackground";
import {useI18n} from 'vue-i18n';
import { nextTick } from 'vue';

export default {
  components: {
    LabBackground,
    KinesisContainer,
    KinesisElement,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      isMobile: false,
      breakpoints: {
        '320': {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        '640': {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        '768': {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 5,
          spaceBetween: 50,
        },
        '1600': {
          slidesPerView: 8,
          spaceBetween: 50,
        },
      },
      images: [
        {src: require('@/assets/images/home/icon-1.png'), alt: 'Icon'},
        {src: require('@/assets/images/home/icon-2.png'), alt: 'Icon'},
        {src: require('@/assets/images/home/icon-3.png'), alt: 'Icon'},
        {src: require('@/assets/images/home/icon-4.png'), alt: 'Icon'},
        {src: require('@/assets/images/home/icon-5.png'), alt: 'Icon'},
        {src: require('@/assets/images/home/icon-6.png'), alt: 'Icon'},
        {src: require('@/assets/images/home/icon-7.png'), alt: 'Icon'},
        {src: require('@/assets/images/home/icon-8.png'), alt: 'Icon'},
        {src: require('@/assets/images/home/icon-9.png'), alt: 'Icon'},
      ],
      customersImageTop: [
        {src: '05.webp'},
        {src: '06.webp'},
        {src: '07.webp'},
        {src: '08.webp'},
        {src: '10.webp'},
        {src: '20.webp'},
        {src: '22.webp'},
        {src: '17.webp'},
        {src: '18.webp'},
        {src: '19.webp'},
        {src: '13.webp'},
        {src: '24.webp'},
        {src: '31.webp'},
        {src: '32.webp'},
        {src: '33.webp'},
        {src: '34.webp'},
        {src: '35.webp'},
      ],
      customersImageBottom: [
        {src: '14.webp'},
        {src: '15.webp'},
        {src: '16.webp'},
        {src: '21.webp'},
        {src: '01.webp'},
        {src: '02.webp'},
        {src: '03.webp'},
        {src: '04.webp'},
        {src: '23.webp'},
        {src: '25.webp'},
        {src: '11.webp'},
        {src: '12.webp'},
        {src: '26.webp'},
        {src: '27.webp'},
        {src: '28.webp'},
      ],
      fadeThreshold: 200,
      timeline: null,
      panelsScrollTrigger: null,
      bannerScrollTrigger: null,
      fadeInScrollTrigger: null,
    };
  },

  setup() {
    const {t, locale} = useI18n();
    return {
      t,
      locale,
      modules: [Autoplay, Mousewheel, Pagination],
    };
  },
  watch: {
    $route(to, from) {
      this.anime();
      this.bannerAnime();
      this.setupFadeInAnimation();
    }
  },
  mounted() {
    this.anime();
    this.bannerAnime();
    this.checkDevice();
    this.setupFadeInAnimation();
  },
  methods: {
    setupFadeInAnimation() {
      gsap.registerPlugin(ScrollTrigger);

      const imageContainer = this.$refs.imageContainer;
      const fadeInElements = document.querySelectorAll('.fade-in');

      if (!imageContainer || !fadeInElements.length) {
        console.error('imageContainer or fadeInElements not found');
        return;
      }

      if (this.fadeInScrollTrigger) {
        this.fadeInScrollTrigger.kill();
      }

      this.fadeInScrollTrigger = gsap.timeline({
        scrollTrigger: {
          trigger: imageContainer,
          start: 'top 80%',
          end: 'center center',
          scrub: true,
        },
      })
          .from(fadeInElements, {
            opacity: 0,
            duration: 1,
            stagger: 0.5,
          });
    },
    checkDevice() {
      this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    anime() {
      gsap.registerPlugin(ScrollTrigger);

      const panelsContainer = this.$refs.panelsContainer;
      const panels = panelsContainer?.querySelectorAll('.panel');

      console.log('panelsContainer:', panelsContainer);
      console.log('panels:', panels);

      if (!panelsContainer || !panels) {
        console.error('panelsContainer or panels not found');
        return;
      }

      panelsContainer.style.width = '500%';
      gsap.set(panels, { xPercent: 0 });

      if (this.panelsScrollTrigger) {
        this.panelsScrollTrigger.kill();
      }

      const directionMultiplier = this.locale === 'fa' ? 1 : -1;
      const xPercentNum = 100 * directionMultiplier * (panels.length - 1);

      console.log('directionMultiplier:', directionMultiplier);
      console.log('xPercentNum:', xPercentNum);

      this.panelsScrollTrigger = gsap.to(panels, {
        xPercent: xPercentNum,
        ease: 'none',
        scrollTrigger: {
          trigger: panelsContainer,
          pin: true,
          start: 'center center',
          scrub: 1,
          end: () => '+=' + (panelsContainer.offsetWidth - window.innerWidth),
        },
      });

      console.log('panelsScrollTrigger:', this.panelsScrollTrigger);
    },
    bannerAnime() {
      gsap.registerPlugin(ScrollTrigger);

      const svgParent = document.querySelector('.svg-parent');
      const svgAnimation = document.querySelector('.svg-animation');

      if (!svgParent || !svgAnimation) {
        console.error('svgParent or svgAnimation not found');
        return;
      }

      // Kill existing ScrollTrigger instances for this animation
      if (this.bannerScrollTrigger) {
        this.bannerScrollTrigger.kill();
      }

      // Create the GSAP animation and store the ScrollTrigger instance
      this.bannerScrollTrigger = gsap.timeline({
        scrollTrigger: {
          trigger: svgParent,
          start: '-100px center',
          end: '+200px center',
          scrub: true,
        },
      })
          .from(svgAnimation, {
            translateY: -450,
            opacity: 1,
            scale: 0.08,
            stagger: {
              amount: 9,
              ease: 'Power3.easeOut',
              from: 'random',
            },
          });
    },
  },

}
</script>


<style scoped lang="scss">
.astro {
  width: 300px;
  animation: float linear 2.5s infinite alternate;
  left: 16px !important;
}

@keyframes float {
  0% {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(0, 30px, 0);
  }
}

.bg-secondary {
  background-color: var(--ar-secondary-bg) !important;
}

.col-lg-5 .card {
  background-image: url('~@/assets/images/home/hero-bg.webp');

  .opacity-background {
    background-color: rgba(255, 255, 255, 0.04);
    display: block;
    border-radius: 32px;
    padding: 8px;
  }
}

.card {
  --ar-card-spacer-y: 2.375rem;
  --ar-card-spacer-x: 2.375rem;
  --ar-card-title-spacer-y: 1rem;
  --ar-card-title-color: var(--ar-gray-900);
  --ar-card-border-width: var(--ar-border-width);
  --ar-card-border-color: var(--ar-border-color);
  --ar-card-border-radius: var(--ar-border-radius-xxl);
  --ar-card-inner-border-radius: calc(var(--ar-border-radius-xxl) - (var(--ar-border-width)));
  --ar-card-cap-padding-y: 1.5rem;
  --ar-card-cap-padding-x: 2.375rem;
  --ar-card-cap-bg: transparent;
  --ar-card-color: var(--ar-body-color);
  --ar-card-bg: var(--ar-body-bg);
  --ar-card-img-overlay-padding: 1rem;
  --ar-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--ar-card-height);
  color: var(--ar-body-color);
  word-wrap: break-word;
  background-color: var(--ar-card-bg);
  background-clip: border-box;
  border: var(--ar-card-border-width) solid var(--ar-card-border-color);
  border-radius: var(--ar-card-border-radius);
  background-image: url("~@/assets/images/home/hero-bg2.webp");
  background-size: cover;
  background-position: top;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--ar-card-spacer-y) var(--ar-card-spacer-x);
  color: var(--ar-card-color);
}

.typewriter h1 {
  color: #fff;
  direction: ltr;
  overflow: hidden;
  border-right: .15em solid var(--color-2);
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .15em;
  animation: typing 2.5s steps(30, end),
  blink-caret .5s step-end infinite;
  font-family: Pelak, sans-serif;
  padding: .5rem 0;
  font-weight: normal;
}

.per h1 {
  color: #fff;
  direction: rtl;
  overflow: hidden;
  border-right: .15em solid var(--color-2);
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0;
  animation: typing 2.5s steps(30, end),
  blink-caret .5s step-end infinite;

}

@keyframes typing {
  from {
    width: 0
  }
  to {
    width: 100%
  }
}

@keyframes blink-caret {
  from, to {
    border-color: transparent
  }
  50% {
    border-color: var(--color-2)
  }
}

.rounded-pill {
  border-radius: var(--ar-border-radius-pill) !important;
}

.btn {
  --ar-btn-padding-x: 1.75rem;
  --ar-btn-padding-y: 0.825rem;
  --ar-btn-font-size: calc(var(--ar-body-font-size) * 0.875);
  --ar-btn-font-weight: 600;
  --ar-btn-line-height: 1.4;
  --ar-btn-color: #fff;
  --ar-btn-bg: transparent;
  --ar-btn-border-width: var(--ar-border-width);
  --ar-btn-border-color: transparent;
  --ar-btn-border-radius: var(--ar-border-radius);
  --ar-btn-hover-border-color: transparent;
  --ar-btn-box-shadow: unset;
  --ar-btn-disabled-opacity: 0.65;
  --ar-btn-focus-box-shadow: 0 0 0 0 rgba(var(--ar-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--ar-btn-padding-y) var(--ar-btn-padding-x);
  font-family: var(--ar-btn-font-family);
  font-size: var(--ar-btn-font-size);
  font-weight: var(--ar-btn-font-weight);
  line-height: var(--ar-btn-line-height);
  color: var(--ar-btn-color);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--ar-btn-border-width) solid var(--ar-btn-border-color);
  border-radius: var(--ar-btn-border-radius);
  background-color: var(--ar-btn-bg);
  transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
}

.btn-outline-primary {
  --ar-btn-color: #d6b36a;
  --ar-btn-border-color: #d6b36a;
  --ar-btn-hover-bg: #d6b36a;
  --ar-btn-hover-border-color: #d6b36a;
  --ar-btn-active-bg: #d6b36a;
  --ar-btn-active-border-color: #d6b36a;
  --ar-btn-disabled-color: #d6b36a;
  --ar-btn-disabled-border-color: #d6b36a;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

[class^="btn-outline-"], [class*=" btn-outline-"] {
  --ar-btn-hover-color: #fff;
  --ar-btn-active-color: #fff;
}

.btn-outline-primary {
  --ar-btn-color: #d6b36a;
  --ar-btn-border-color: #d6b36a;
  --ar-btn-hover-bg: #d6b36a;
  --ar-btn-hover-border-color: #d6b36a;
  --ar-btn-active-bg: #d6b36a;
  --ar-btn-active-border-color: #d6b36a;
  --ar-btn-disabled-color: #d6b36a;
  --ar-btn-disabled-border-color: #d6b36a;
}

.btn-outline-warning {
  --ar-btn-color: #edcb50;
  --ar-btn-border-color: #edcb50;
  --ar-btn-hover-color: #ffffff;
  --ar-btn-hover-bg: #edcb50;
  --ar-btn-hover-border-color: #edcb50;
  --ar-btn-focus-shadow-rgb: 237, 203, 80;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #edcb50;
  --ar-btn-active-border-color: #edcb50;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #edcb50;
  --ar-btn-disabled-bg: transparent;
  --ar-btn-disabled-border-color: #edcb50;
  --ar-gradient: none;
}

.btn-warning {
  --ar-btn-hover-color: #fff;
  --ar-btn-active-color: #fff;
  --ar-btn-hover-bg: #e9c02b;
  --ar-btn-active-bg: #e9c02b;
  --ar-btn-hover-border-color: #e9c02b;
  --ar-btn-active-border-color: #e9c02b;
  --ar-btn-disabled-color: #fff;
}

.btn-warning {
  --ar-btn-color: #000;
  --ar-btn-bg: #edcb50;
  --ar-btn-border-color: #edcb50;
  --ar-btn-hover-color: #fff;
  --ar-btn-hover-bg: #f0d36a;
  --ar-btn-hover-border-color: #efd062;
  --ar-btn-focus-shadow-rgb: 201, 173, 68;
  --ar-btn-active-color: #000;
  --ar-btn-active-bg: #f1d573;
  --ar-btn-active-border-color: #efd062;
  --ar-btn-active-shadow: unset;
  --ar-btn-disabled-color: #000;
  --ar-btn-disabled-bg: #edcb50;
  --ar-btn-disabled-border-color: #edcb50;
}

.btn:hover {
  color: var(--ar-btn-hover-color);
  background-color: var(--ar-btn-hover-bg);
  border-color: var(--ar-btn-hover-border-color);
}

.bg-primary {
  --ar-bg-opacity: 1;
  background-color: rgba(var(--ar-primary-rgb), var(--ar-bg-opacity)) !important;
}

.text-primary {
  --ar-text-opacity: 1;
  color: rgba(var(--ar-primary-rgb), var(--ar-text-opacity)) !important;
}

.parallax {
  max-width: 740px;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  pointer-events: none;
}

.parallax-layer {
  width: 100%;
  height: 100%;
}

.parallax-layer > img {
  display: block;
  width: 100%;
}

.rotate-cw {
  animation: rotate-cw 100s linear infinite;
}

.rotate-ccw {
  animation: rotate-ccw 50s linear infinite;
}

.layer-general {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
}

@keyframes rotate-cw {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-ccw {
  to {
    transform: rotate(-360deg);
  }
}


.noise_effect {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.noise_effect::after {
  animation: grain .5s steps(1) infinite;
  background-image: url('~@/assets/images/home/noise.png');
  content: "";
  height: 300%;
  left: -50%;
  position: fixed;
  top: -110%;
  width: 300%;
}

.bg-opacity-10 {
  --ar-bg-opacity: 0.1;
}

.badge {
  --ar-badge-padding-x: 0.75em;
  --ar-badge-padding-y: 0.5em;
  --ar-badge-font-size: 0.75em;
  --ar-badge-font-weight: 600;
  --ar-badge-color: #fff;
  --ar-badge-border-radius: 50rem;
  display: inline-block;
  padding: var(--ar-badge-padding-y) var(--ar-badge-padding-x);
  font-size: var(--ar-badge-font-size);
  font-weight: var(--ar-badge-font-weight);
  line-height: 1;
  color: var(--ar-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--ar-badge-border-radius);
}

.tarsim-item {
  margin-bottom: 7rem;
  padding: 0 2rem;
}

.icon-copy-wrapper {
  position: relative;
  z-index: 1;
}

.icon-copy__svg--tools {
  width: 1.7rem;
  height: 1.7rem;
}

.icon-copy__svg {
  margin-bottom: 1rem;
  width: 2.2rem;
  height: 1.9rem;
}

.icon-copy-content {
  width: 28rem;
  margin-bottom: 3rem;
}

.text-body {
  --ar-text-opacity: 1;
  color: var(--ar-body-color) !important;
}

.icon-copy-fig {
  border-radius: 3.7rem;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
}

.base-video {
  position: relative;

  video {
    position: absolute;
    top: 0;
    right: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.tarsim-item-images {
  z-index: 1;
  display: flex;

  img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.tarsim-item-fig {
  border-radius: .6rem;
  overflow: hidden;
}

.tarsim-item-fig:nth-child(1) {
  filter: drop-shadow(0 4.4rem 2rem rgba(9, 25, 51, .18));
  z-index: 1;
}

.base-image {
  display: block;
}


@media only screen and (min-width: 1024px) {
  .tarsim-list {
    display: flex;
    align-items: center;
    height: 100vh;
  }
  .tarsim-item {
    display: flex;
    align-items: center;
    margin-left: 12.13592vw;
    margin-bottom: unset;
    padding: unset;
    height: 100%;
  }
  .icon-copy {
    position: relative;
  }
  .icon-copy__svg--tools {
    width: 1.69903vw;
    height: 1.69903vw;
  }
  .icon-copy__svg {
    margin-bottom: .97087vw;
  }
  .icon-copy-content {
    width: 27.06311vw;
  }
  .icon-copy-fig {
    position: absolute;
    right: calc(100% - 8.07039vw);
    top: calc(50% + 1.33495vw);
    transform: translateY(-50%);
    width: 42.35437vw;
    border-radius: 8rem;
  }
  .tarsim-item-images {
    align-items: flex-start;
    margin-right: 28.51942vw;
    height: 100%;
  }
  .tarsim-item-fig:nth-child(1) {
    width: 29.97573vw;
    margin-top: 32.16019vw;
  }
  .tarsim-item:first-child .tarsim-item-fig:nth-child(1) {
    margin-top: 36.40777vw;
  }
  .tarsim-item-fig:nth-child(2) {
    width: 46.84466vw;
    margin-top: 7.88835vw;
    margin-left: -11.52913vw;
  }
  .tarsim-item-fig:nth-child(3) {
    width: 15.23058vw;
    margin-left: 2.24515vw;
    margin-top: 25.06068vw;
  }
  .tarsim-item-fig:nth-child(4) {
    width: 18.68932vw;
    margin-left: 2.00243vw;
    margin-top: 11.8932vw;
  }
  .tarsim-item-fig:nth-child(6) {
    width: 27.30583vw;
    margin-left: 2.18447vw;
    margin-top: 16.26214vw;
  }
}

@media only screen and (min-width: 768px) {
  .tarsim-item {
    padding: 0 8rem;
  }
}

#panels #panels-container {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
  align-items: center;
}

#panels #panels-container .panel {
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #333;
  text-align: left;

}

#panels #panels-container .panel img {
  max-width: 100%;
  height: auto;
  display: block;
}

#panels #panels-container .panel .panels-navigation {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

#panels #panels-container .panel .anchor-panel,
#panels #panels-container .panel .nav-panel {
  color: #000;
  text-transform: uppercase;
  margin-right: 2rem;
}

#panels #panels-container .panels-navigation {
  position: absolute;
  width: 100%;
  bottom: 2rem;
  right: 2rem;
}

.svg-animation {
  background-image: url("~@/assets/images/home/hero-bg-empty.webp");
  background-size: cover;
  position: relative;

  img {
    margin-top: -12rem;
    position: relative;
    z-index: 99;
  }
}

.video-faded {
  filter: blur(20px);
  opacity: .95;
}

@media only screen and (max-width: 1399px) {
  .typewriter h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1120px) {
  .last-section {
    .astro {
      width: 150px;
    }

    .display-1 {
      font-size: 30px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .pin-wrapper {
    visibility: hidden;
    opacity: 0;
    display: none;
  }
  .typewriter h1 {
    border-right: 0;
    font-size: 40px;
  }
}

@media only screen and (max-width: 850px) {
  @media only screen and (max-width: 1120px) {
    .last-section {
      .astro {
        display: none;
      }

      .display-1 {
        font-size: 24px;
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .typewriter h1 {
    border-right: 0;
    font-size: 30px;
    white-space: break-spaces;
    animation: none;
  }
  .svg-animation {
    padding: 1rem !important;

    img {
      margin-top: 0;
    }

    p {
      font-size: .8rem !important;
    }
  }
}

@media only screen and (max-width: 450px) {
  .mySwiperHome img {
    max-width: 100%;
  }
}

#astronaut > img {
  padding-top: 150px;
  display: block;
  right: 0;
  animation: anim 20s linear infinite;
  position: absolute;
  z-index: 88;
  user-select: none;
  pointer-events: none;
}

#astronaut2 > img {
  padding-top: 150px;
  display: block;
  left: 0;
  position: absolute;
  animation: anim2 20s linear infinite;
  animation-delay: 1s;
  z-index: 88;
  user-select: none;
  pointer-events: none;
}

@keyframes anim {
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(30px) rotate(10deg);
  }
  20% {
    transform: translateY(60px) rotate(20deg);
  }
  30% {
    transform: translateY(90px) rotate(30deg);
  }
  40% {
    transform: translateY(120px) rotate(20deg);
  }
  50% {
    transform: translateY(150px) rotate(30deg);
  }
  60% {
    transform: translateY(120px) rotate(40deg);
  }
  70% {
    transform: translateY(90px) rotate(10deg);
  }
  80% {
    transform: translateY(60px) rotate(20deg);
  }
  90% {
    transform: translateY(30px) rotate(10deg);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes anim2 {
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(-30px) rotate(-10deg);
  }
  20% {
    transform: translateY(-60px) rotate(-20deg);
  }
  30% {
    transform: translateY(-90px) rotate(-10deg);
  }
  40% {
    transform: translateY(-120px) rotate(0deg);
  }
  50% {
    transform: translateY(-150px) rotate(-10deg);
  }
  60% {
    transform: translateY(-120px) rotate(0deg);
  }
  70% {
    transform: translateY(-90px) rotate(20deg);
  }
  80% {
    transform: translateY(-60px) rotate(10deg);
  }
  90% {
    transform: translateY(-30px) rotate(0deg);
  }
  100% {
    transform: translateY(0px);
  }
}

.ltr {
  .per h1 {
    direction: ltr;;
  }
}
</style>
