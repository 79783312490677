<template>
  <div class="container py-5 d-xl-none d-block">
    <section class="mt-5">
      <div class="text-center">
        <h2 class="h1 pb-2 pb-sm-0 text-sub">{{ t("tarsimSys.title") }}</h2>
        <p class="pb-3 pb-xl-4 mb-1 text-justify">
        {{t("tarsimSys.description")}}
        </p>
      </div>
    </section>
    <section class="mt-5">
      <h1 class="text-sub mb-0 l-h-1">EAM</h1>
      <span class="badge bg-1 text-black mb-2">Enterprise Asset Management</span>
      <h3 class="text-white mb-2">{{ t("tarsimSys.EAM") }}</h3>
      <p class="text-justify">
        {{t("tarsimSys.EAM_des")}}
      </p>
      <ul class="ps-0 mt-2 d-flex flex-wrap justify-content-center">
        <li class="m-1" v-for="(step, index) in EAM" :key="index">
          <span class="badge bg-1 text-black py-2 px-4">{{ step.label }}</span>
        </li>
      </ul>
    </section>
    <section class="mt-5">
      <h1 class="text-sub mb-0 l-h-1">CMMS</h1>
      <span class="badge bg-2 text-black mb-2">Computerized maintenance Management System</span>
      <h3 class="text-white mb-2">{{ t("tarsimSys.CMMS") }}</h3>
      <p class="text-justify">
      {{ t("tarsimSys.CMMS_des") }}
      </p>
      <ul class="ps-0 mt-2 d-flex flex-wrap justify-content-center">
        <li class="m-1" v-for="(step, index) in CMMS" :key="index">
          <span class="badge bg-2 text-black py-2 px-4">{{ step.label }}</span>
        </li>
      </ul>
    </section>
    <section class="mt-5">
      <h1 class="text-sub mb-0 l-h-1">RBI</h1>
      <span class="badge bg-1 text-black mb-2">Risk Based Inspection</span>
      <h3 class="text-white mb-2">{{ t("tarsimSys.RBI") }}</h3>
      <p class="text-justify">
        {{ t("tarsimSys.RBI_des") }}
      </p>
      <ul class="ps-0 mt-2 d-flex flex-wrap justify-content-center">
        <li class="m-1" v-for="(step, index) in RBI" :key="index">
          <span class="badge bg-1 text-black py-2 px-4">{{ step.label }}</span>
        </li>
      </ul>
    </section>
    <section class="mt-5">
      <h1 class="text-sub mb-0 l-h-1">PSM</h1>
      <span class="badge bg-5 text-black mb-2">Process Safety Management</span>
      <h3 class="text-white mb-2">{{ t("tarsimSys.PSM") }}</h3>
      <p class="text-justify">
        {{ t("tarsimSys.PSM_des") }}
      </p>
      <ul class="ps-0 mt-2 d-flex flex-wrap justify-content-center">
        <li class="m-1" v-for="(step, index) in PSM" :key="index">
          <span class="badge bg-5 text-black py-2 px-4">{{ step.label }}</span>
        </li>
      </ul>
    </section>
    <section class="mt-5">
      <h1 class="text-sub mb-0 l-h-1">HSE</h1>
      <span class="badge bg-3 text-black mb-2">Health, Safety, Environment</span>
      <h4 class="text-white mb-2">{{ t("tarsimSys.HSE") }}</h4>
      <p class="text-justify">
       {{ t("tarsimSys.HSE_des") }}
      </p>
      <ul class="ps-0 mt-2 d-flex flex-wrap justify-content-center">
        <li class="m-1" v-for="(step, index) in HSE" :key="index">
          <span class="badge bg-3 text-black py-2 px-4">{{ step.label }}</span>
        </li>
      </ul>
    </section>
    <section class="mt-5">
      <h1 class="text-sub mb-0 l-h-1">RMS</h1>
      <span class="badge bg-4 text-black mb-2">Risk Management System</span>
      <h3 class="text-white mb-2">{{ t("tarsimSys.RMS") }}</h3>
      <p class="text-justify">
        {{ t("tarsimSys.RMS_des") }}
      </p>
      <ul class="ps-0 mt-2 d-flex flex-wrap justify-content-center">
        <li class="m-1" v-for="(step, index) in RMS" :key="index">
          <span class="badge bg-4 text-black py-2 px-4">{{ step.label }}</span>
        </li>
      </ul>
    </section>
    <section class="mt-5">
      <h1 class="text-sub mb-0 l-h-1">DCC</h1>
      <span class="badge bg-4 text-black mb-2">Document Control Center</span>
      <h3 class="text-white mb-2">{{ t("tarsimSys.DCC") }}</h3>
      <p class="text-justify">
        {{ t("tarsimSys.DCC_des") }}
      </p>
      <ul class="ps-0 mt-2 d-flex flex-wrap justify-content-center">
        <li class="m-1" v-for="(step, index) in DCC" :key="index">
          <span class="badge bg-4 text-black py-2 px-4">{{ step.label }}</span>
        </li>
      </ul>
    </section>
    <section class="mt-5">
      <h1 class="text-sub mb-0 l-h-1">LMS</h1>
      <span class="badge bg-6 text-black mb-2">Learning Management System</span>
      <h3 class="text-white mb-2"> {{ t("tarsimSys.LMS") }}</h3>
      <p class="text-justify">
       {{ t("tarsimSys.LMS_des") }}
      </p>
      <ul class="ps-0 mt-2 d-flex flex-wrap justify-content-center">
        <li class="m-1" v-for="(step, index) in LMS" :key="index">
          <span class="badge bg-6 text-black py-2 px-4">{{ step.label }}</span>
        </li>
      </ul>
    </section>
    <section class="mt-5">
      <h3 class="text-sub mb-0 l-h-1">{{ t("tarsimSys.modules") }}</h3>
      <ul class="ps-0 mt-3 d-flex flex-wrap justify-content-center">
        <li class="m-1" v-for="(step, index) in moduleItems" :key="index">
          <span class="badge bg-gradient-info text-black py-2 px-4">{{ step }}</span>
        </li>
      </ul>
    </section>
    <section class="mt-5">
      <div class="tarsim-system-schematic">
        <img class="w-100 h-100 object-fit-contain" :src="require(`@/assets/images/tarsim/shematic-03.webp`)"
             v-if="locale === 'fa'" alt="">
        <img class="w-100 h-100 object-fit-contain" :src="require(`@/assets/images/tarsim/shematic-03-en.webp`)"
             v-if="locale === 'en'" alt="">
      </div>
    </section>
  </div>
  <div class="vh-100 d-none d-xl-block">
    <swiper
        :direction="'vertical'"
        :slidesPerView="1"
        :initialSlide="currentIndex"
        :spaceBetween="0"
        :mousewheel="true"
        :keyboard="{
          enabled: true
        }"
        :zoom="true"
        :pagination="false"
        :modules="modules"
        class="mySwiper"
        @swiper="onSwipers"
        @slideChange="onSwiper">
      <swiper-slide>
        <div class="position-relative h-100 tarsim-system">
          <div class="position-absolute top-0 end-0 w-50 h-100 d-none d-xl-block"></div>
          <div class="d-flex flex-column flex-xl-row align-items-center h-100 position-relative z-2">
            <div class="position-relative w-100 order-xl-2">
              <div class="position-absolute top-0 end-0 w-100 h-100 d-xl-none"></div>
              <div
                  class="d-flex flex-wrap position-relative justify-content-center align-items-center"
                  style="direction: ltr">
                <div class="cursor-pointer" @click="slider.slideTo(1)" id='cube-container'>
                  <div class="face top"></div>
                  <div class="face right"></div>
                  <div class="face bottom"></div>
                  <div class="face left"></div>
                  <div class="face back"></div>
                  <div class="face front">EAM</div>
                </div>
                <div id='cube-container' class="cursor-pointer" @click="slider.slideTo(2)">
                  <div class="face top"></div>
                  <div class="face right"></div>
                  <div class="face bottom"></div>
                  <div class="face left"></div>
                  <div class="face back"></div>
                  <div class="face front">CMMS</div>
                </div>
                <div id='cube-container' class="cursor-pointer" @click="slider.slideTo(3)">
                  <div class="face top"></div>
                  <div class="face right"></div>
                  <div class="face bottom"></div>
                  <div class="face left"></div>
                  <div class="face back"></div>
                  <div class="face front">RBI</div>
                </div>
                <div id='cube-container' class="cursor-pointer" @click="slider.slideTo(4)">
                  <div class="face top"></div>
                  <div class="face right"></div>
                  <div class="face bottom"></div>
                  <div class="face left"></div>
                  <div class="face back"></div>
                  <div class="face front">PSM</div>
                </div>
                <div id='cube-container' class="cursor-pointer" @click="slider.slideTo(5)">
                  <div class="face top"></div>
                  <div class="face right"></div>
                  <div class="face bottom"></div>
                  <div class="face left"></div>
                  <div class="face back"></div>
                  <div class="face front">HSE</div>
                </div>
                <div id='cube-container' class="cursor-pointer" @click="slider.slideTo(6)">
                  <div class="face top"></div>
                  <div class="face right"></div>
                  <div class="face bottom"></div>
                  <div class="face left"></div>
                  <div class="face back"></div>
                  <div class="face front">RMS</div>
                </div>
                <div id='cube-container' class="cursor-pointer" @click="slider.slideTo(7)">
                  <div class="face top"></div>
                  <div class="face right"></div>
                  <div class="face bottom"></div>
                  <div class="face left"></div>
                  <div class="face back"></div>
                  <div class="face front">DCC</div>
                </div>
                <div id='cube-container' class="cursor-pointer" @click="slider.slideTo(8)">
                  <div class="face top"></div>
                  <div class="face right"></div>
                  <div class="face bottom"></div>
                  <div class="face left"></div>
                  <div class="face back"></div>
                  <div class="face front">LMS</div>
                </div>
              </div>
            </div>
            <div class="w-100 order-xl-1 px-3 px-xl-4 py-4 pt-xl-0">
              <div class="text-center text-sm-start mx-auto" style="max-width: 450px;">
                <h2 class="h1 pb-2 pb-sm-0">{{ t("tarsimSys.title") }}</h2>
                <p class="d-none d-sm-block pb-3 pb-xl-4 mb-1 text-justify">
                  {{t("tarsimSys.description")}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="position-relative h-100">
          <div class="bg-1 position-absolute top-0 end-0 w-50 h-100 d-none d-xl-block"></div>
          <div class="bg-1-img position-absolute top-0 end-0 w-50 h-100 d-xl-block"></div>
          <div class="d-flex flex-column flex-xl-row align-items-center h-100 position-relative z-2">
            <div class="position-relative w-100 order-xl-2">
              <div class="bg-1 position-absolute top-0 end-0 w-100 h-100 d-xl-none"></div>
              <a href="javascript:void(0)">
                <div class="d-block position-relative z-2 m-0 ms-auto">
                  <ul class="schematic-row">
                    <li v-for="(step, index) in EAM" :key="index" :class="step.direction">
                      <button class="schematic-button">{{ step.label }}</button>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
            <div class="w-100 order-xl-1 px-3 px-xl-4 py-4 pt-xl-0">
              <div class="text-center text-sm-start mx-auto from-bottom-main" style="max-width: 450px;">
                <h1 class="text-sub mb-0 l-h-1">EAM</h1>
                <span class="badge bg-1 text-black mb-2">Enterprise Asset Management</span>
                <h3 class="text-white mb-2">{{ t("tarsimSys.EAM") }}</h3>
                <p class="text-justify">
                  {{t("tarsimSys.EAM_des")}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="position-relative h-100">
          <div class="bg-2 position-absolute top-0 end-0 w-50 h-100 d-none d-xl-block"></div>
          <div class="bg-1-img position-absolute top-0 end-0 w-50 h-100 d-xl-block"></div>
          <div class="d-flex flex-column flex-xl-row align-items-center h-100 position-relative z-2">
            <div class="position-relative w-100 order-xl-2">
              <div class="bg-2 position-absolute top-0 end-0 w-100 h-100 d-xl-none"></div>
              <a href="javascript:void(0)">
                <div class="d-block position-relative z-2 m-0 ms-auto">
                  <ul class="schematic-row">
                    <li v-for="(step, index) in CMMS" :key="index" :class="step.direction">
                      <button class="schematic-button" :class="{'fs-12px': index === 24}">{{ step.label }}</button>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
            <div class="w-100 order-xl-1 px-3 px-xl-4 py-4 pt-xl-0">
              <div class="text-center text-sm-start mx-auto from-bottom-main" style="max-width: 450px;">
                <h1 class="text-sub mb-0 l-h-1">CMMS</h1>
                <span class="badge bg-2 text-black mb-2">Computerized maintenance Management System</span>
                <h3 class="text-white mb-2">{{ t("tarsimSys.CMMS") }}</h3>
                <p class="text-justify">
                  {{ t("tarsimSys.CMMS_des") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="position-relative h-100">
          <div class="bg-7 position-absolute top-0 end-0 w-50 h-100 d-none d-xl-block"></div>
          <div class="bg-1-img position-absolute top-0 end-0 w-50 h-100 d-xl-block"></div>
          <div class="d-flex flex-column flex-xl-row align-items-center h-100 position-relative z-2">
            <div class="position-relative w-100 order-xl-2">
              <div class="bg-7 position-absolute top-0 end-0 w-100 h-100 d-xl-none"></div>
              <a href="javascript:void(0)">
                <div class="d-block position-relative z-2 m-0 ms-auto">
                  <ul class="schematic-row">
                    <li v-for="(step, index) in RBI" :key="index" :class="step.direction">
                      <button class="schematic-button" :class="{'fs-12px': index === 1 || index === 2}">{{ step.label }}</button>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
            <div class="w-100 order-xl-1 px-3 px-xl-4 py-4 pt-xl-0">
              <div class="text-center text-sm-start mx-auto from-bottom-main" style="max-width: 450px;">
                <h1 class="text-sub mb-0 l-h-1">RBI</h1>
                <span class="badge bg-7 text-black mb-2">Risk Based Inspection</span>
                <h3 class="text-white mb-2">{{ t("tarsimSys.RBI") }}</h3>
                <p class="text-justify">
                 {{ t("tarsimSys.RBI_des") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="position-relative h-100">
          <div class="bg-5 position-absolute top-0 end-0 w-50 h-100 d-none d-xl-block"></div>
          <div class="bg-1-img position-absolute top-0 end-0 w-50 h-100 d-xl-block"></div>
          <div class="d-flex flex-column flex-xl-row align-items-center h-100 position-relative z-2">
            <div class="position-relative w-100 order-xl-2">
              <div class="bg-5 position-absolute top-0 end-0 w-100 h-100 d-xl-none"></div>
              <a href="javascript:void(0)">
                <div class="d-block position-relative z-2 m-0 ms-auto">
                  <ul class="schematic-row">
                    <li v-for="(step, index) in PSM" :key="index" :class="step.direction">
                      <button class="schematic-button">{{ step.label }}</button>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
            <div class="w-100 order-xl-1 px-3 px-xl-4 py-4 pt-xl-0">
              <div class="text-center text-sm-start mx-auto from-bottom-main" style="max-width: 450px;">
                <h1 class="text-sub mb-0 l-h-1">PSM</h1>
                <span class="badge bg-5 text-black mb-2">Process Safety Management</span>
                <h3 class="text-white mb-2">{{ t("tarsimSys.PSM") }}</h3>
                <p class="text-justify">
                 {{ t("tarsimSys.PSM_des") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="position-relative h-100">
          <div class="bg-3 position-absolute top-0 end-0 w-50 h-100 d-none d-xl-block"></div>
          <div class="bg-1-img position-absolute top-0 end-0 w-50 h-100 d-xl-block"></div>
          <div class="d-flex flex-column flex-xl-row align-items-center h-100 position-relative z-2">
            <div class="position-relative w-100 order-xl-2">
              <div class="bg-3 position-absolute top-0 end-0 w-100 h-100 d-xl-none"></div>
              <a href="javascript:void(0)">
                <div class="d-block position-relative z-2 m-0 ms-auto">
                  <ul class="schematic-row">
                    <li v-for="(step, index) in HSE" :key="index" :class="step.direction">
                      <button class="schematic-button">{{ step.label }}</button>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
            <div class="w-100 order-xl-1 px-3 px-xl-4 py-4 pt-xl-0">
              <div class="text-center text-sm-start mx-auto from-bottom-main" style="max-width: 450px;">
                <h1 class="text-sub mb-0 l-h-1">HSE</h1>
                <span class="badge bg-3 text-black mb-2">Health, Safety, Environment</span>
                <h4 class="text-white mb-2">{{ t("tarsimSys.HSE") }}</h4>
                <p class="text-justify">
                  {{ t("tarsimSys.HSE_des") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="position-relative h-100">
          <div class="bg-4 position-absolute top-0 end-0 w-50 h-100 d-none d-xl-block"></div>
          <div class="bg-1-img position-absolute top-0 end-0 w-50 h-100 d-xl-block"></div>
          <div class="d-flex flex-column flex-xl-row align-items-center h-100 position-relative z-2">
            <div class="position-relative w-100 order-xl-2">
              <div class="bg-4 position-absolute top-0 end-0 w-100 h-100 d-xl-none"></div>
              <a href="javascript:void(0)">
                <div class="d-block position-relative z-2 m-0 ms-auto">
                  <ul class="schematic-row">
                    <li v-for="(step, index) in RMS" :key="index" :class="step.direction">
                      <button class="schematic-button">{{ step.label }}</button>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
            <div class="w-100 order-xl-1 px-3 px-xl-4 py-4 pt-xl-0">
              <div class="text-center text-sm-start mx-auto from-bottom-main" style="max-width: 450px;">
                <h1 class="text-sub mb-0 l-h-1">RMS</h1>
                <span class="badge bg-4 text-black mb-2">Risk Management System</span>
                <h3 class="text-white mb-2">{{ t("tarsimSys.RMS") }}</h3>
                <p class="text-justify">
                  {{ t("tarsimSys.RMS_des") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="position-relative h-100">
          <div class="bg-8 position-absolute top-0 end-0 w-50 h-100 d-none d-xl-block"></div>
          <div class="bg-1-img position-absolute top-0 end-0 w-50 h-100 d-xl-block"></div>
          <div class="d-flex flex-column flex-xl-row align-items-center h-100 position-relative z-2">
            <div class="position-relative w-100 order-xl-2">
              <div class="bg-8 position-absolute top-0 end-0 w-100 h-100 d-xl-none"></div>
              <a href="javascript:void(0)">
                <div class="d-block position-relative z-2 m-0 ms-auto">
                  <ul class="schematic-row">
                    <li v-for="(step, index) in DCC" :key="index" :class="step.direction">
                      <button class="schematic-button">{{ step.label }}</button>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
            <div class="w-100 order-xl-1 px-3 px-xl-4 py-4 pt-xl-0">
              <div class="text-center text-sm-start mx-auto from-bottom-main" style="max-width: 450px;">
                <h1 class="text-sub mb-0 l-h-1">DCC</h1>
                <span class="badge bg-8 text-black mb-2">Document Control Center</span>
                <h3 class="text-white mb-2">{{ t("tarsimSys.DCC") }}</h3>
                <p class="text-justify">
                  {{ t("tarsimSys.DCC_des") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="position-relative h-100">
          <div class="bg-6 position-absolute top-0 end-0 w-50 h-100 d-none d-xl-block"></div>
          <div class="bg-1-img position-absolute top-0 end-0 w-50 h-100 d-xl-block"></div>
          <div class="d-flex flex-column flex-xl-row align-items-center h-100 position-relative z-2">
            <div class="position-relative w-100 order-xl-2">
              <div class="bg-6 position-absolute top-0 end-0 w-100 h-100 d-xl-none"></div>
              <a href="javascript:void(0)">
                <div class="d-block position-relative z-2 m-0 ms-auto">
                  <ul class="schematic-row">
                    <li v-for="(step, index) in LMS" :key="index" :class="step.direction">
                      <button class="schematic-button">{{ step.label }}</button>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
            <div class="w-100 order-xl-1 px-3 px-xl-4 py-4 pt-xl-0">
              <div class="text-center text-sm-start mx-auto from-bottom-main" style="max-width: 450px;">
                <h1 class="text-sub mb-0 l-h-1">LMS</h1>
                <span class="badge bg-6 text-black mb-2">Learning Management System</span>
                <h3 class="text-white mb-2"> {{ t("tarsimSys.LMS") }}</h3>
                <p class="text-justify">
                  {{ t("tarsimSys.LMS_des") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="position-relative h-100 d-flex justify-content-center align-items-center tarsim-system-bottom">
          <div class="icon">
            {{ t("tarsimSys.modules") }}
            <ul class="menu">
              <li v-for="(item, index) in moduleItems" :key="index" class="spread">
                <a class="unit from-top" href="javascript:void(0)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
            class="position-relative h-100 d-flex justify-content-center align-items-center tarsim-system-schematic swiper-zoom-container">
          <img class="w-100 h-100 object-fit-contain" :src="require(`@/assets/images/tarsim/shematic-03.webp`)" alt=""
               v-if="locale === 'fa'">
          <img class="w-100 h-100 object-fit-contain" :src="require(`@/assets/images/tarsim/shematic-03-en.webp`)"
               alt="" v-if="locale === 'en'">
        </div>
      </swiper-slide>
      <div v-show="activeIndex < 10"
           class="position-fixed start-50 bottom-0 translate-middle-x rounded-4 rounded-bottom-0 bg-light shadow z-5 fs-sm fw-medium text-dark py-2 px-3 z-3 cursor-pointer"
           @click="slider.slideNext()">
      <span>
        <i class="fas fa-arrow-down"></i>
      </span>
      </div>
      <div v-show="activeIndex === 10" @click="slider.slidePrev()"
           class="position-fixed start-50 cursor-pointer bottom-0 translate-middle-x rounded-4 rounded-bottom-0 bg-light shadow z-5 fs-sm fw-medium text-dark py-2 px-3 z-3">
      <span>
        <i class="fas fa-arrow-up"></i>
      </span>
      </div>
    </swiper>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import {Zoom, Keyboard, Mousewheel, Pagination, Navigation, FreeMode, Thumbs} from 'swiper/modules';
import {ref, computed} from 'vue';
import {useI18n} from "vue-i18n";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      activeIndex: 0,
      currentIndex: 0,
      thumbsSwiper: {},
      thumbsSwiper2: {},
      thumbsSwiper3: {},
      thumbsSwiper4: {},
      thumbsSwiper5: {},
      thumbsSwiper6: {},
      moduleTitle: '',
    }
  },
  setup() {
    const {t, locale} = useI18n();
    const slider = ref(null);
    const onSwipers = (swiper) => {
      slider.value = swiper;
      console.log(slider.value)
    };
    const RMS_EN = ref([
      {label: 'Risk planning', direction: 'from-top'},
      {label: 'Risk Identification', direction: 'from-bottom'},
      {label: 'Dynamic Model Builder FMEA,JHA,Bow Tie,Wiliam Fine', direction: 'from-end'},
      {label: 'Risk Assessment and Evaluation', direction: 'from-start'},
      {label: 'Dynamic Matrix Builder', direction: 'from-top'},
      {label: 'Intelligent and Dynamic Formula Builder', direction: 'from-end'},
      {label: 'Risk Calculation', direction: 'from-end'},
      {label: 'Failure Probability Calculation', direction: 'from-top'},
      {label: 'Control and Corrective Action', direction: 'from-start'},
    ])
    const RBI_EN = ref([
      {label: 'Dynamic Calculation Using Rule Engine', direction: 'from-top'},
      {
        label: 'Calculation Of Various Degradation Mechanisms Based on the Dynamic Formula Builder Engine',
        direction: 'from-start'
      },
      {
        label: 'Calculation Of Degradation Mechanisms such as corrosion, cracking, creep, Erosion, Fatigue and fracture',
        direction: 'from-bottom'
      },
      {label: 'Probability Of Failure(Pof) Assessment Based On Risk Matrix', direction: 'from-end'},
      {label: 'Consequence Of Failure (Cof) Assessment', direction: 'from-start'},
      {label: 'Inspection Scheduling and Planning', direction: 'from-top'},
      {label: 'Development Of Risk and Hazard Mitigation Guidelines', direction: 'from-end'},
      {label: 'Identification and Determination Of Failure Factors', direction: 'from-top'},
      {label: 'Determination Of Risk-Based Tolerance Thresholds', direction: 'from-start'},
      {label: 'Configurable Dimensions and Parameters For Risk Matrix', direction: 'from-bottom'},
    ])
    const DCC_EN = ref([
      {label: 'unlimited File Upload with various Formats', direction: 'from-top'},
      {label: 'Transmittal and datasheet Management For Technical Document', direction: 'from-bottom'},
      {label: 'Workflow and Lifecycle Management For Technical Document', direction: 'from-end'},
      {label: 'Version Control For Technical Document', direction: 'from-start'},
      {label: 'Multi-Dimensional Document Categorization and Access Levels', direction: 'from-top'},
      {label: 'Content Extraction From Files (OCR)', direction: 'from-end'},
      {label: 'Integrated Search and Document Retrieval Based on Content', direction: 'from-top'},
      {label: 'Integration with Project Management Module', direction: 'from-start'},
      {label: 'Integration with Physical Asset Management and Work Order Modules', direction: 'from-bottom'},
    ])
    const LMS_EN = ref([
      {label: 'Competency assessment', direction: 'from-top'},
      {label: 'On-the-Job training (OJT)', direction: 'from-bottom'},
      {label: 'Knowledge Management', direction: 'from-top'},
      {label: 'Training Needs Assessment', direction: 'from-end'},
      {label: 'Virtual Classroom Management(online&offline)', direction: 'from-start'},
      {label: 'Training Course Management', direction: 'from-top'},
      {label: 'Assignment,Examination and Certification Management', direction: 'from-end'},
      {label: 'Course Analysis and Evaluation Management', direction: 'from-bottom'},
      {label: 'course archiving and Retrieval management', direction: 'from-start'},
      {label: 'Instructor Evaluation Management', direction: 'from-end'},
      {label: 'Dynamic Exam Builder', direction: 'from-end'},
      {label: 'Training Calendar', direction: 'from-end'},
      {label: 'Training Package selection Management', direction: 'from-end'},
      {label: 'course Preparation', direction: 'from-end'},
    ])
    const PSM_EN = ref([
      {label: 'Current organizational status', direction: 'from-top'},
      {label: 'Process safety culture', direction: 'from-bottom'},
      {label: 'process safety competency', direction: 'from-end'},
      {label: 'Employee and stakeholder Engagement', direction: 'from-start'},
      {label: 'Process Knowledge Management', direction: 'from-end'},
      {label: 'contractor Safety Management', direction: 'from-bottom'},
      {label: 'Process Hazard Analysis', direction: 'from-start'},
      {label: 'Process safety Operating procedures (SOP)', direction: 'from-top'},
      {label: 'Work Permit Management', direction: 'from-bottom'},
      {label: 'Mechanical Integrity and Reliability', direction: 'from-end'},
      {label: 'Training and Performance', direction: 'from-start'},
      {label: 'Management Of Change (MOC)', direction: 'from-end'},
      {label: 'Pre-Startup safety Review (PSSR)', direction: 'from-bottom'},
      {label: 'Crisis Management and Emergency Response', direction: 'from-top'},
      {label: 'Incident Investigation/Root Cause Analysis ', direction: 'from-bottom'},
      {label: 'Performance Measurement', direction: 'from-end'},
      {label: 'Charging and Calibration', direction: 'from-start'},
      {label: 'Compliance Audit', direction: 'from-bottom'},
      {label: 'Management Review and Continuous Improvement', direction: 'from-bottom'}
    ])
    const HSE_EN = ref([
      {label: 'Risk Management', direction: 'from-top'},
      {label: 'Incident and Near-Miss Management', direction: 'from-bottom'},
      {label: 'Crisis Management and Emergency Response Plan(ERP)', direction: 'from-end'},
      {label: 'Machinery Safety', direction: 'from-start'},
      {label: ' Work Permit Management', direction: 'from-top'},
      {label: 'Anomaly Reporting', direction: 'from-end'},
      {label: 'Management Of Change (MOC)', direction: 'from-bottom'},
      {label: 'Safety Data Sheet (SDS)', direction: 'from-start'},
      {label: 'Firefighting', direction: 'from-top'},
      {label: 'Warehouse and Goods Safety Management ', direction: 'from-bottom'},
      {label: 'Personal Protective Equipment(PPE)', direction: 'from-end'},
      {label: 'Corrective Actions', direction: 'from-start'},
      {label: 'Monitoring and inspection', direction: 'from-end'},
      {label: 'Ergonomics', direction: 'from-bottom'},
      {label: 'Asset Integrity', direction: 'from-start'},
      {label: 'Occupational Medicine', direction: 'from-top'},
      {label: 'Health (occupational, public, Environmental, Mental)', direction: 'from-bottom'},
      {label: 'Diet therapy and nutrition', direction: 'from-end'},
      {label: 'clinic and Medical Center', direction: 'from-start'},
      {label: 'Laboratory', direction: 'from-bottom'},
      {label: 'Pharmacy', direction: 'from-start'},
      {label: 'Emission Management', direction: 'from-bottom'},
      {label: 'Conventions (Rio, Basel, Stockholm, Vienna, Rotterdam, Minamata)', direction: 'from-end'},
      {label: 'Waste Management', direction: 'from-bottom'},
      {label: 'Green Space Management', direction: 'from-bottom'},
      {label: 'life cycle perspective(LCP)', direction: 'from-bottom'},
      {label: 'Energy Management', direction: 'from-bottom'},
    ])
    const CMMS_EN = ref([
      {label: 'Equipment Management', direction: 'from-top'},
      {label: 'Work Order and Corrective Action Management', direction: 'from-bottom'},
      {label: 'Inventory Management', direction: 'from-end'},
      {label: 'Equipment Prioritization Based On AHP and Risk techniques', direction: 'from-start'},
      {label: 'Work Order Prioritization Based on NUCREC and Risk Techniques', direction: 'from-top'},
      {label: ' Condition-Based Maintenance(CBM)', direction: 'from-end'},
      {label: 'Technical Inspection Management', direction: 'from-bottom'},
      {label: 'Calibration Management', direction: 'from-start'},
      {label: 'Downtime Management', direction: 'from-top'},
      {label: 'Opportunity Maintenance (OM)', direction: 'from-bottom'},
      {label: 'Work permit Management', direction: 'from-end'},
      {label: 'Specialized TSR module (Technical Service Request)', direction: 'from-start'},
      {label: 'Project,overhaul,Development and improvement management', direction: 'from-end'},
      {label: 'Log Sheet', direction: 'from-bottom'},
      {label: 'Interlock', direction: 'from-start'},
      {label: 'contractor Management', direction: 'from-top'},
      {label: 'Key Performance Indicators(KPI)', direction: 'from-bottom'},
      {label: 'Customer Relationship Management (CRM)', direction: 'from-end'},
      {label: 'Workshop Capacity and Resource Allocation', direction: 'from-start'},
      {label: 'Base Equipment Management', direction: 'from-bottom'},
      {label: 'Maintenance Budget and Cost Management', direction: 'from-bottom'},
      {label: 'Equipment life cycle cost(LCC)  NPV,NFV,NEUAC,ROI', direction: 'from-bottom'},
      {label: 'Predictive Maintenance (Pdm)', direction: 'from-bottom'},
      {label: 'Reliability centered Maintenance (RM)', direction: 'from-bottom'},
      {
        label: 'Root Couse Failure Analysis and Failure Frequency Analysis (FMEA,RCM,RCA,RCFA)',
        direction: 'from-bottom'
      },
      {label: 'Maintenance Strategy Management', direction: 'from-bottom'},
    ])
    const EAM_EN = ref([
      {label: 'Asset Management Program Planning (SAMP)', direction: 'from-top'},
      {label: 'Asset Information Management', direction: 'from-bottom'},
      {label: 'Equipment Design and construction', direction: 'from-end'},
      {label: 'Procurement and Supply chain Management', direction: 'from-start'},
      {label: 'Warehouse and Inventory management', direction: 'from-top'},
      {label: 'Equipment and Machinery Insurance', direction: 'from-end'},
      {label: 'Maintenance and Reliability Management', direction: 'from-bottom'},
      {label: 'Risk Management', direction: 'from-start'},
      {label: 'Change Management', direction: 'from-bottom'},
      {label: 'Disposal and Sales Management', direction: 'from-top'}
    ])
    const moduleItems_EN = ref([
      'Contract Management Subsystem',
      'Human Resource Management Subsystem',
      'Dynamic Report Generator Subsystem',
      'Search Engine Subsystem',
      'Process Management and Workflow Subsystem',
      'Monitoring and Business Intelligence (BI) Subsystem ',
      'Integrated Financial Subsystem',
      'Warehouse Management Subsystem',
      'Artificial Intelligence and Smart Assistant (AI) Subsystem',
      'Big Data Processing Subsystem',
      'Document Management System (DMS) Subsystem ',
      'Knowledge Management System (KMS) Subsystem',
      'Commission, Meeting, and Resolution Management Subsystem',
      'Business Rules Management Subsystem'
    ])
    const CMMS_FA = ref([
      {label: 'مدیریت تجهیزات', direction: 'from-top'},
      {label: 'مدیریت دستورکار و اقدامات اصلاحی', direction: 'from-bottom'},
      {label: 'مدیریت انبار', direction: 'from-end'},
      {label: 'اولویت بندی تجهیزات بر مبنای تکنیک های AHP و ریسک', direction: 'from-start'},
      {label: 'اولویت بندی دستورکارهای تعمیراتی بر مبنای تکنیک های NUCREC و ریسک', direction: 'from-top'},
      {label: ' مدیریت نگهداری و تعمیرات مبتنی بر پایش وضعیت(CBM)', direction: 'from-end'},
      {label: 'مدیریت بازرسی فنی', direction: 'from-bottom'},
      {label: 'مدیریت کالیبراسیون', direction: 'from-start'},
      {label: 'مدیریت توقفات', direction: 'from-top'},
      {label: 'نگهداری و تعمیرات مبتنی بر فرصت (OM)', direction: 'from-bottom'},
      {label: 'مدیریت مجوزکار', direction: 'from-end'},
      {label: 'ماژول تخصصی TSR (Technical Service Request)', direction: 'from-start'},
      {label: 'مدیریت پروژه ،مدیریت اورهال،توسعه و بهبود', direction: 'from-end'},
      {label: 'لاگ شیت', direction: 'from-bottom'},
      {label: 'اینترلاک', direction: 'from-start'},
      {label: 'مدیریت قرارداد پیمانکاران', direction: 'from-top'},
      {label: 'شاخص های عملکردی', direction: 'from-bottom'},
      {label: 'مدیریت ارتباط با مشتری (CRM)', direction: 'from-end'},
      {label: 'توان کارگاهی و تخصیص منابع', direction: 'from-start'},
      {label: 'مدیریت تجهیزات پایه کار', direction: 'from-bottom'},
      {label: 'مدیریت بودجه و هزینه تعمیرات', direction: 'from-bottom'},
      {label: 'چرخه عمرمفید تجهیزات(LCC)  NPV,NFV,NEUAC,ROI', direction: 'from-bottom'},
      {label: 'نگهداری و تعمیرات قابل پیش بینی (Pdm)', direction: 'from-bottom'},
      {label: 'نگهداری و تعمیرات مبتنی بر قابلیت اطمینان (RM)', direction: 'from-bottom'},
      {label: 'تحلیل عل ریشه ای خرابی و فراوانی خرابی(FMEA,RCM,RCA,RCFA)', direction: 'from-bottom'},
      {label: 'استراتژی نت', direction: 'from-bottom'},
    ])
    const HSE_FA = ref([
      {label: 'مدیریت ریسک', direction: 'from-top'},
      {label: 'مدیریت حوادث و شبه حوادث', direction: 'from-bottom'},
      {label: 'مدیریت بحران و طرح واکنش اضطراری(ERP)', direction: 'from-end'},
      {label: 'ایمنی ماشین آلات', direction: 'from-start'},
      {label: 'مدیریت جواز کار ', direction: 'from-top'},
      {label: 'گزارشات آنومالی', direction: 'from-end'},
      {label: 'مدیریت تغییرات (MOC)', direction: 'from-bottom'},
      {label: 'برگه اطلاعات ایمنی مواد شیمیایی (SDS)', direction: 'from-start'},
      {label: 'آتشنشانی', direction: 'from-top'},
      {label: 'مدیریت ایمنی انبار و کالا ', direction: 'from-bottom'},
      {label: 'مدیریت تجهیزات حفاظت فردی(PPE)', direction: 'from-end'},
      {label: 'اقدامات اصلاحی', direction: 'from-start'},
      {label: 'پایش و بازرسی', direction: 'from-end'},
      {label: 'ارگونومی', direction: 'from-bottom'},
      {label: 'یکپارچگی سرمایه', direction: 'from-top'},
      {label: 'طب کار', direction: 'from-bottom'},
      {label: 'بهداشت (حرفه ای،عمومی،محیط،روان)', direction: 'from-end'},
      {label: 'رژیم درمانی و تغذیه', direction: 'from-start'},
      {label: 'کلینیک و درمانگاه', direction: 'from-bottom'},
      {label: 'آزمایشگاه', direction: 'from-start'},
      {label: 'داروخانه', direction: 'from-bottom'},
      {label: 'مدیریت آلایندگی', direction: 'from-end'},
      {label: 'کنوانسیون ها(ریو،بازل،استکهلم،وین،رتردام،میناماتا)', direction: 'from-bottom'},
      {label: 'مدیریت پسماند', direction: 'from-bottom'},
      {label: 'مدیریت فضای سبز', direction: 'from-bottom'},
      {label: 'منظر چرخه حیات(LCP)', direction: 'from-bottom'},
      {label: 'مدیریت انرژی', direction: 'from-bottom'},
    ])
    const PSM_FA = ref([
      {label: 'وضعیت فعلی سازمانی', direction: 'from-top'},
      {label: 'فرهنگ ایمنی فرآیند', direction: 'from-bottom'},
      {label: 'صلاحیت و شایستگی ایمنی فرآیند', direction: 'from-end'},
      {label: 'مشارکت کارکنان و ذینفعان', direction: 'from-start'},
      {label: 'مدیریت دانش فرآیند', direction: 'from-end'},
      {label: 'مدیریت ایمنی پیمانکاران', direction: 'from-bottom'},
      {label: 'تجزیه و تحلیل خطرات فرآیند', direction: 'from-start'},
      {label: 'دستورالعمل های عملیاتی ایمنی فرآیند (SOP)', direction: 'from-top'},
      {label: 'مدیریت جواز کار', direction: 'from-bottom'},
      {label: 'یکپارچگی مکانیکی و قابلیت اطمینان', direction: 'from-end'},
      {label: 'آموزش و عملکرد', direction: 'from-start'},
      {label: 'مدیریت تغییرات (MOC)', direction: 'from-end'},
      {label: 'ایمنی پیش راه اندازی (PSSR)', direction: 'from-bottom'},
      {label: 'مدیریت بحران و واکنش در شرایط اضطراری', direction: 'from-top'},
      {label: 'تحقیق و بررسی حوادث/ ریشه یابی رویداد', direction: 'from-bottom'},
      {label: 'اندازه گیری و سنجش', direction: 'from-end'},
      {label: 'شارژ و کالیبراسیون', direction: 'from-start'},
      {label: 'ممیزی انطباق', direction: 'from-bottom'},
      {label: 'بازنگری مدیریت و بهبود مستمر', direction: 'from-bottom'}
    ])
    const LMS_FA = ref([
      {label: 'صلاحیت سنجی', direction: 'from-top'},
      {label: 'آموزش ضمن کار(OJT)', direction: 'from-bottom'},
      {label: 'مدیریت دانش', direction: 'from-top'},
      {label: 'نیازسنجی آموزشی', direction: 'from-end'},
      {label: 'مدیریت کلاس مجازی(آنلاین و آفلاین)', direction: 'from-start'},
      {label: 'برگزاری دوره های آموزشی', direction: 'from-top'},
      {label: 'مدیریت تکالیف،آزمون ها و صدورگواهینامه', direction: 'from-end'},
      {label: 'مدیریت تحلیل وارزشیابی دوره', direction: 'from-bottom'},
      {label: 'مدیریت آرشیو و بازیابی دوره ها', direction: 'from-start'},
      {label: 'مدیریت ارزشیابی اساتید', direction: 'from-end'},
      {label: 'آزمون ساز پویا', direction: 'from-end'},
      {label: 'تقویم آموزش', direction: 'from-end'},
      {label: 'مدیریت انتخاب بسته های آموزشی', direction: 'from-end'},
      {label: 'آماده سازی دوره ها', direction: 'from-end'},
    ])
    const RMS_FA = ref([
      {label: 'طرح ریزی برنامه ریسک', direction: 'from-top'},
      {label: 'شناسایی ریسک', direction: 'from-bottom'},
      {label: 'تکنیک ساز پویا', direction: 'from-end'},
      {label: 'ارزیابی و ارزشیابی ریسک', direction: 'from-start'},
      {label: 'ماتریس ساز پویا', direction: 'from-top'},
      {label: 'فرمول ساز هوشمند و پویا', direction: 'from-end'},
      {label: 'محاسبه ریسک', direction: 'from-end'},
      {label: 'محاسبه احتمال خرابی', direction: 'from-top'},
      {label: 'اقدامات کنترلی و اصلاحی', direction: 'from-start'},
    ])
    const RBI_FA = ref([
      {label: 'محاسبه پویا به وسیله موتور قواعد', direction: 'from-top'},
      {label: 'محاسبه انواع مکانیزم های تخریب مبتنی بر موتور فرمول ساز پویا', direction: 'from-start'},
      {label: 'محاسبه انواع مکانیزم های تخریب نظیر خوردگی، ترک، خزش، فرسایش، خستگی', direction: 'from-bottom'},
      {label: 'ارزیابی احتمال وقوع خرابی (pof) مبتنی بر ماتریس ریسک', direction: 'from-end'},
      {label: 'ارزیابی نتیجه وقوع خرابی (cof)', direction: 'from-start'},
      {label: 'ارائه برنامه زمانبندی بازرسی', direction: 'from-top'},
      {label: 'تدوین دستورالعمل های کاهش خطر و ریسک', direction: 'from-end'},
      {label: 'شناسایی و تعیین انواع فاکتورهای خرابی', direction: 'from-top'},
      {label: 'تعیین آستانه تحمل خطر مبتنی بر ریسک', direction: 'from-start'},
      {label: 'ابعاد و پارامترهای قابل تنظیم برای ماتریس ریسک', direction: 'from-bottom'},
    ])
    const DCC_FA = ref([
      {label: 'بارگذاری نامحدود فایل با انواع فرمت', direction: 'from-top'},
      {label: 'مدیریت ترانسمیتال ها و دیتاشیت ها', direction: 'from-bottom'},
      {label: 'مدیریت گردش کار و چرخه عمر مدارک فنی', direction: 'from-end'},
      {label: 'ورژن گذاری مدارک فنی', direction: 'from-start'},
      {label: 'ابعاد متنوع دسته بندی مدرک و سطوح دسترسی', direction: 'from-top'},
      {label: 'استخراج محتوا از فایل از طریق (OCR)', direction: 'from-end'},
      {label: 'جستجوی یکپارچه و ارائه اسناد متنوع بر مبنای محتوا', direction: 'from-top'},
      {label: 'ارتباط با ماژول مدیریت پروژه', direction: 'from-start'},
      {label: 'ارتباط با ماژول مدیریت دارایی فیزیکی و دستورکارهای تعمیراتی', direction: 'from-bottom'},
    ])
    const EAM_FA = ref([
      {label: 'طرح ریزی برنامه مدیریت دارایی (SAMP)', direction: 'from-top'},
      {label: 'مدیریت اطلاعات دارایی', direction: 'from-bottom'},
      {label: 'طراحی و ساخت تجهیزات', direction: 'from-end'},
      {label: 'مدیریت خرید و تدارکات (داخلی و خارجی)', direction: 'from-start'},
      {label: 'مدیریت انبار و کالا', direction: 'from-top'},
      {label: 'مدیریت بیمه تجهیزات و ماشین‌آلات', direction: 'from-end'},
      {label: 'مدیریت نگهداری و تعمیرات', direction: 'from-bottom'},
      {label: 'مدیریت ریسک', direction: 'from-start'},
      {label: 'مدیریت تغییرات', direction: 'from-bottom'},
      {label: 'مدیریت اسقاط و فروش', direction: 'from-top'}
    ])
    const moduleItems_FA = ref([
      'زیر سیستم مدیریت قرارداد',
      'زیر سیستم مدیریت منابع انسانی',
      'زیر سیستم گزارش ساز پویا',
      'زیر سیستم موتور جستجو',
      'زیر سیستم مدیریت فرآیند ها و گردش کار',
      'زیر سیستم مانیتورینگ و هوش مصنوعی (BI)',
      'زیر سیستم یکپارچه مالی',
      'زیر سیستم مدیریت انبار',
      'زیر سیستم هوش مصنوعی و دستیار هوشمند (AI)',
      'زیر سیستم پردازش داده های حجیم (Big Data)',
      'زیر سیستم مدیریت اسناد و مدارک (DMS)',
      'زیر سیستم مدیریت دانش (KMS)',
      'زیر سیستم کمیسیون و مدیریت جلسات و مصوبات',
      'زیر سیستم مدیریت قوانین کسب و کار'
    ])
    const PSM = computed(() => (locale.value === 'fa' ? PSM_FA.value : PSM_EN.value));
    const RMS = computed(() => (locale.value === 'fa' ? RMS_FA.value : RMS_EN.value));
    const RBI = computed(() => (locale.value === 'fa' ? RBI_FA.value : RBI_EN.value));
    const DCC = computed(() => (locale.value === 'fa' ? DCC_FA.value : DCC_EN.value));
    const LMS = computed(() => (locale.value === 'fa' ? LMS_FA.value : LMS_EN.value));
    const HSE = computed(() => (locale.value === 'fa' ? HSE_FA.value : HSE_EN.value));
    const CMMS = computed(() => (locale.value === 'fa' ? CMMS_FA.value : CMMS_EN.value));
    const EAM = computed(() => (locale.value === 'fa' ? EAM_FA.value : EAM_EN.value));
    const moduleItems = computed(() => (locale.value === 'fa' ? moduleItems_FA.value : moduleItems_EN.value));
    return {
      t,
      locale,
      PSM,
      RMS,
      RBI,
      DCC,
      LMS,
      HSE,
      CMMS,
      EAM,
      moduleItems,
      slider,
      onSwipers,
      modules: [Zoom, Keyboard, Mousewheel, Pagination, FreeMode, Thumbs, Navigation],
    };
  },
  methods: {
    onSwiper(swiper) {
      this.activeIndex = swiper.activeIndex;
    },
  }
}
</script>

<style lang="scss" scoped>
.astronaut {
  width: 200px;
  animation: float linear 2.5s infinite alternate;
}

@keyframes float {
  0% {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(0, 30px, 0);
  }
}

.tarsim-system {
  background-image: url("@/assets/images/home/hero-bg.webp");
  background-size: 50%;
  background-repeat: no-repeat;
  background-color: #0c1015;
}

.tarsim-system-bottom {
  background-image: url("@/assets/images/home/hero-bg2.webp");
  background-size: 50%;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-color: #0c1015;
}

.tarsim-system-schematic {
  background-color: #010d20;
  z-index: 10;
  cursor: zoom-in;
}

.bg-1-img {
  background-image: url("@/assets/images/home/hero-bg2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  opacity: 0.1;
}

.schematic-button {
  $btn-color: #000;
  position: relative;
  font-size: 1em;
  line-height: 1.5;
  color: $btn-color;
  margin: 0;
  padding: 1em;
  background: none;
  border: none;
  cursor: pointer;
  width: 200px;

  &:active {
    color: $btn-color;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;

  }

  &:before {
    top: 0;
    left: 0;
    box-shadow: inset 1px 1px 0 0 $btn-color;
    //animation: hoverShadowBefore 10s forwards infinite;
  }

  &:after {
    right: 0;
    bottom: 0;
    box-shadow: inset -1px -1px 0 0 $btn-color;
    //animation: hoverShadowAfter 10s forwards infinite;
  }

  &:hover {
    &:before {
      animation: hoverShadowBefore 1s forwards;
    }

    &:after {
      animation: hoverShadowAfter 1s forwards;
    }
  }
}

@keyframes hoverShadowBefore {
  0% {
    width: 100%;
    height: 1px;

    top: 0;
    left: 0;
  }
  33% {
    width: 1px;
    height: 100%;

    top: 0;
    left: 0;
  }
  66% {
    width: 1px;
    height: 1px;

    top: calc(100% - 1px);
    left: 0;
  }
  100% {
    width: 100%;
    height: 1px;

    top: calc(100% - 1px);
    left: 0;
  }
}

@keyframes hoverShadowAfter {
  0% {
    width: 100%;
    height: 1px;
  }
  33% {
    width: 1px;
    height: 100%;

    bottom: 0;
    right: 0;
  }
  66% {
    width: 1px;
    height: 1px;

    bottom: calc(100% - 1px);
    right: 0;
  }
  100% {
    width: 100%;
    height: 1px;

    bottom: calc(100% - 1px);
    right: 0;
  }
}

.schematic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;

  li {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.schematic-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;

  li {
    text-align: center;
    margin-bottom: 1rem;

    .schematic-button {
      height: 80px;
      line-height: 1.5;
      font-size: 14px;
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}

.from-top, .from-bottom, .from-start, .from-end, .scale-up, .scale-down {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 1.4s .4s ease-in-out;
}

.from-bottom-main {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all .2s .2s ease-in-out;
}

.scale-up, .scale-down {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all .4s .4s ease-in-out;
}

.scale-up {
  transform: scale(0.85);

}

.from-end {
  transform: translateX(-12.25rem);
}

.from-top {
  transform: translateY(-12.25rem);
}

.from-bottom {
  transform: translateY(12.25rem);
}

.from-bottom-main {
  transform: translateY(6.25rem);
}

.from-start {
  transform: translateX(12.25rem);
}

.swiper-slide-active .scale-up, .swiper-slide-active .scale-down, .active .scale-up, .active .scale-down {
  opacity: 1;
  transform: scale(1);
}

.swiper-slide-active .from-start, .swiper-slide-active .from-end, .active .from-start, .active .from-end {
  opacity: 1;
  transform: translateX(0);
}

.swiper-slide-active .from-top, .swiper-slide-active .from-bottom, .active .from-top, .active .from-bottom {
  opacity: 1;
  transform: translateY(0);
}

.swiper-slide-active .from-bottom-main, .active .from-bottom-main {
  opacity: 1;
  transform: translateY(0);
}

.bg-info {
  --ar-bg-opacity: 1;
  background-color: rgba(var(--ar-info-rgb), var(--ar-bg-opacity)) !important;
}

.bg-danger {
  --ar-bg-opacity: 1;
  background-color: rgba(var(--ar-danger-rgb), var(--ar-bg-opacity)) !important;
}

.bg-primary {
  --ar-bg-opacity: 1;
  background-color: rgba(var(--ar-primary-rgb), var(--ar-bg-opacity)) !important;
}

#cube-container {
  box-sizing: border-box;
  transform-style: preserve-3d;
  position: relative;
  width: 200px;
  height: 200px;
  transition: 2s cubic-bezier(.68, -0.55, .27, 1.55);
  z-index: 8;
  animation: startAnime 12s cubic-bezier(.68, -0.55, .27, 1.55);

  &:nth-child(2) {
    animation: startAnime 10s cubic-bezier(.68, -0.55, .27, 1.55);

    .face {
      border: 2px solid #0bbbab;
      background: #0bbbab70;
      box-shadow: 0 0 150px #0bbbab;
    }
  }

  &:nth-child(3) {
    animation: startAnime 8s cubic-bezier(.68, -0.55, .27, 1.55);

    .face {
      border: 2px solid #fc7f73;
      background: rgba(252, 127, 115, 0.56);
      box-shadow: 0 0 150px #fc7f73;
    }
  }

  &:nth-child(4) {
    animation: startAnime 6s cubic-bezier(.68, -0.55, .27, 1.55);

    .face {
      border: 2px solid #8077b0;
      background: #8077b070;
      box-shadow: 0 0 150px #8077b0;
    }
  }

  &:nth-child(5) {
    animation: startAnime 4s cubic-bezier(.68, -0.55, .27, 1.55);

    .face {
      border: 2px solid #429cbd;
      background: #429cbd70;
      box-shadow: 0 0 150px #429cbd;
    }
  }

  &:nth-child(6) {
    animation: startAnime 3s cubic-bezier(.68, -0.55, .27, 1.55);

    .face {
      border: 2px solid #c160a1;
      background: #c160a170;
      box-shadow: 0 0 150px #c160a1;
    }
  }

  &:nth-child(7) {
    animation: startAnime 2.5s cubic-bezier(.68, -0.55, .27, 1.55);

    .face {
      border: 2px solid #c5002c;
      background: rgba(197, 0, 44, 0.47);
      box-shadow: 0 0 150px #c5002c;
    }
  }

  &:nth-child(8) {
    animation: startAnime 2s cubic-bezier(.68, -0.55, .27, 1.55);

    .face {
      border: 2px solid #f5aa2d;
      background: #f5aa2d70;
      box-shadow: 0 0 150px #f5aa2d;
    }
  }

  .face {
    width: 200px;
    height: 200px;
    position: absolute;
    border: 2px solid #9bc147;
    background: #9bc14770;
    box-shadow: 0 0 150px #9bc147;

    &.top {
      transform: rotateX(90deg);
      margin-top: -50%;
    }

    &.right {
      transform: rotateY(90deg);
      margin-left: 50%;
    }

    &.left {
      transform: rotateY(-90deg);
      margin-left: -50%;
    }

    &.bottom {
      transform: rotateX(90deg);
      margin-top: 50%;
    }

    &.back {
      transform: translateZ(125px);
    }

    &.front {
      transform: translateZ(-125px);
      color: #ffffff;
      position: relative;
      z-index: 3;
      font-size: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  //&:hover {
  //  transform: rotateX(-25deg) rotateY(-40deg) translate(-50%, -50%);
  //  transition: 2s cubic-bezier(.68, -0.55, .27, 1.55);
  //}
  &:hover {
    transform: translateY(-20px);
    transition: all .5s;
  }
}

@keyframes startAnime {
  from {
    transform: rotateX(-25deg) rotateY(-40deg) translate(-50%, -50%);
  }
  to {
    transform: none;
  }
}

.bg-1 {
  background-color: #9bc147;
}

.bg-2 {
  background-color: #0bbbab;
}

.bg-3 {
  background-color: #429cbd;
}

.bg-4 {
  background-color: #c160a1;
}

.bg-5 {
  background-color: #8077b0;
}

.bg-6 {
  background-color: #f5aa2d;
}

.bg-7 {
  background-color: #fc7f73;
}

.bg-8 {
  background-color: #c5002c;
}

$li: 14;
$icon-size: 200px;
$menu-size: 300px / 2;
$unit-size: 120px;
$menu-position: (($menu-size * 2) - $icon-size) / -2;
$deg: 360deg / $li;

.icon {
  position: relative;
  background: transparent;
  border: 4px solid var(--color-2);
  cursor: pointer;
  margin: auto;
  color: #ffffff;
  border-radius: 50%;
  height: $icon-size;
  width: $icon-size;
  line-height: 1.5;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  transition: 0.24s 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}


.menu {
  position: absolute;
  top: $menu-position;
  left: $menu-position;
  border: $menu-size solid transparent;
  cursor: default;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 1.4s 0.07s;
  z-index: -5;
}

.spread {
  position: absolute;
  top: -190px;
  left: -190px;
  transform-origin: 190px 190px;
  transition: all 0.5s 0.1s;
}
.ltr .spread {
  position: absolute;
  top: -250px;
  left: -250px;
  transform-origin: 250px 250px;
  transition: all 0.5s 0.1s;
}

.icon {
  .menu {
    transition: transform 0.4s 0.08s,
    z-index 0s 0.5s;
    transform: scale(1);
    z-index: 5;
  }

  .spread, .unit {
    transition: all 0.6s;
  }

  @for $m from 1 through $li {
    .spread:nth-child(#{$m}) {
      transition-delay: $m * 0.02s;
      transform: rotate(45 + $deg * $m);

      .unit {
        transition-delay: $m * 0.04s;
        animation: backgroundChange 2s infinite;
        animation-delay: $m * 0.1s;
        transform: rotate(-$deg * $m + 720 - 45);
      }
    }
  }
}

.unit {
  position: absolute;
  background: #1e3252;
  font-size: 13px;
  text-decoration: none;
  width: $unit-size;
  height: $unit-size;
  line-height: 1.5;
  color: #ffffff;
  border-radius: 50%;
  transition: 0.6s;
  border: 2px solid var(--color-2);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: normal;
  padding: 4px;
}

@keyframes backgroundChange {
  from {
    background-color: #1e3252;
  }
  to {
    background-color: var(--color-1);
  }
}

//gallery
.modal-body {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }


  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: contain;
    background-position: center;
  }

  .mySwiper2 {
    height: 80%;
    width: 100%;
  }

  .mySwiper3 {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .mySwiper3 .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .mySwiper3 .swiper-slide-thumb-active {
    opacity: 1;
  }

  .swiper-slide3 img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.l-h-1 {
  line-height: 1;
}

@media only screen and (max-width: 1224px) {
  .schematic-row {
    li {
      margin-bottom: .2rem;

      .schematic-button {
        height: 70px;
        padding: .5rem;
      }
    }
  }
}
.badge{
  white-space: break-spaces;
}
.fs-12px{
  font-size: 12px!important;
}
</style>