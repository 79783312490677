import { createI18n } from 'vue-i18n';

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            const module = locales(key);
            messages[locale] = module.default || module; // Handle both ES modules and CommonJS
        }
    });
    return messages;
}

const i18n = createI18n({
    locale: 'fa', // Default locale
    fallbackLocale: 'fa', // Fallback locale
    messages: loadLocaleMessages(),
    legacy: false, // Use Composition API
});

export default i18n;