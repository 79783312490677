<template>
  <div>
    <section class="position-relative d-flex align-items-center min-vh-100 py-5 mb-3 overflow-hidden">
      <div class="container position-relative mt-5 mb-lg-5 mb-md-4 mb-3 pt-3 pb-xl-3" data-bs-theme="dark">
        <div class="row gy-5 align-items-center">
          <div class="col-xl-5 col-lg-6">
            <div class="text-lg-start text-center">
              <h1 class="display-4 mb-0 pb-0 text-center">
                {{ t("xstone.heroTitle") }}
                <span class="text-sub" style="white-space: nowrap">
                  <svg height="80" width="100%">
                    <text x="50%" y="70%"  text-anchor="middle">
                      {{ t("xstone.heroSubtitle") }}
                    </text>
                  </svg>
                </span>
              </h1>
              <p class="mb-0 pb-lg-1 pb-md-1 text-justify">
                <span class="me-2 text-light fs-7">
                  {{ t("xstone.heroDescription1") }}
                </span>
              </p>
              <p class="mb-0 pb-lg-1 pb-md-1 text-justify">
                <span class="me-2 text-light fs-7">
                  {{ t("xstone.heroDescription2") }}
                </span>
              </p>
              <div>
                <object class="svg-container" type="image/svg+xml" :data="require(`@/assets/images/astro/space-robot-animation.svg`)"  width="100%"></object>
              </div>
            </div>
          </div>
          <div class="col-lg-6 offset-xl-1 d-mo-none">
            <div class="position-relative ms-xl-0 ms-lg-4">
              <div class="position-absolute top-50 start-50 translate-middle ratio ratio-1x1 absolute-bg">
                <div class="p-md-0 p-5">
                  <img :src="require(`@/assets/images/xstone/xstone-hero-bg.png`)" alt="Shape"
                       class="hero-animation-spin p-md-0 p-5">
                </div>
              </div>
              <div class="position-relative row row-cols-sm-2 row-cols-1 gx-xl-4 gx-lg-3 gx-md-4 gx-3">
                <div class="col">
                  <div class="d-sm-grid d-flex gap-xl-4 gap-lg-3 gap-md-4 gap-sm-3 gap-2">
                    <div class="d-flex align-items-center justify-content-center bg-secondary rounded-3"
                         style="min-height: 176px; backdrop-filter: blur(6px);">
                      <div class="p-xl-4 p-sm-3 p-2 fs-xl fw-semibold text-center">
                        <span class="hero-animation-fade text-white">HRM</span>
                      </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center bg-secondary rounded-3"
                         style="min-height: 176px; backdrop-filter: blur(6px);">
                      <div class="p-xl-4 p-sm-3 p-2 fs-xl fw-semibold text-center">
                        <span class="hero-animation-fade hero-animation-delay-2 text-white">Form Generator</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col mt-sm-5 mt-2 pt-sm-5">
                  <div class="d-sm-grid d-flex gap-xl-4 gap-lg-3 gap-md-4 gap-sm-3 gap-2">
                    <div class="d-flex align-items-center justify-content-center bg-secondary rounded-3"
                         style="min-height: 176px; backdrop-filter: blur(6px);">
                      <div class="p-xl-4 p-sm-3 p-2 fs-xl fw-semibold text-center">
                        <span
                            class="hero-animation-fade hero-animation-delay-1 text-white">Workflow</span>
                      </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center bg-secondary rounded-3"
                         style="min-height: 176px; backdrop-filter: blur(6px);">
                      <div class="p-xl-4 p-sm-3 p-2 fs-xl fw-semibold text-center">
                        <span
                            class="hero-animation-fade hero-animation-delay-3 text-white">BI And Report Generator</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container position-relative z-2 svg-section">
        <h2 class="mb-1">{{ t('xstone.configurationManagementTitle') }}</h2>
        <h5 class="mb-4">{{ t('xstone.configurationManagementSubtitle') }}</h5>
        <div class="steps">
          <div class="col-md-6 position-relative">
            <div class="step pt-0 pt-md-3 pb-5">
              <div class="step-number">
                <div class="step-number-inner">1</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">
                    {{ t('xstone.coreSystem') }}
                  </h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">3</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.workflowEngine') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">5</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.bi') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">7</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.performanceEvaluation') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">9</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.userElectronicService') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">11</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.documentManagementSystem') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">13</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.notification') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">15</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.chatBot') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">17</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.batchProcessing') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">19</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.hrm') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">21</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.apm') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 last-child">
              <div class="step-number">
                <div class="step-number-inner">23</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.ai') }}</h3>
                </div>
              </div>
            </div>
            <svg class="active" viewBox="0 0 9 699" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="scroll-line" d="M 5 4 V 694.5" stroke="url(#paint0_linear_102_3)" stroke-width="3"
                    stroke-linecap="round"></path>
              <defs>
                <linearGradient id="paint0_linear_102_3" x1="-33.4736" y1="-33.5001" x2="53.1928" y2="1169.5"
                                gradientUnits="userSpaceOnUse">
                  <stop stop-color="#d6b36a"></stop>
                  <stop offset="1" stop-color="#d6b36a"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="col-md-6 position-relative">
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">2</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.bpm') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">4</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.ruleEngine') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">6</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.security') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">8</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.reportGenerator') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">10</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.kms') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">12</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.formGenerator') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">14</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.searchEngine') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">16</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.mobile') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">18</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.codeGenerator') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 pb-5">
              <div class="step-number">
                <div class="step-number-inner">20</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.userManagement') }}</h3>
                </div>
              </div>
            </div>
            <div class="step pt-0 pt-md-4 last-child">
              <div class="step-number">
                <div class="step-number-inner">22</div>
              </div>
              <div class="step-body d-flex align-items-center">
                <div class="ps-md-4 ps-xl-5">
                  <h3 class="h5">{{ t('xstone.kafka') }}</h3>
                </div>
              </div>
            </div>
            <svg class="active" viewBox="0 0 9 699" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="scroll-line-2" d="M 5 4 V 694.5" stroke="url(#paint0_linear_102_3)" stroke-width="3"
                    stroke-linecap="round"></path>
              <defs>
                <linearGradient id="paint0_linear_102_3" x1="-33.4736" y1="-33.5001" x2="53.1928" y2="1169.5"
                                gradientUnits="userSpaceOnUse">
                  <stop stop-color="#d6b36a"></stop>
                  <stop offset="1" stop-color="#d6b36a"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </section>
    <section class="mt-5 bg-section supportive-modules">
      <div class="container position-relative z-2 svg-section">
        <h2 class="mb-1 pt-4">{{ t('xstone.supportiveModulesTitle') }}</h2>
        <h5 class="mb-4">{{ t('xstone.supportiveModulesSubtitle') }}</h5>
        <div class="row">
          <svg class="active" viewBox="0 0 476 927" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="scroll-line-3"
                  d="M238.458 4C238.458 4 240 28.1667 245 52.5C250 76.8333 276.758 123.7 369.958 160.5C407.458 175.307 455.501 200.5 464.501 258C468.444 283.193 462 334 424 366C390.211 394.454 302.972 416.847 238.458 436C142.458 464.5 121 471.14 65.5 516C46 531.762 18.5001 559 6.99998 605C-1.63757 639.55 11.4091 672 29 693.5C65 737.5 107.235 746.5 147.5 762.5C187.765 778.5 199 786.5 199 786.5C237.5 811 238.458 860.5 238.458 860.5V916.5"
                  stroke="url(#paint0_linear_2_3)" stroke-width="8" stroke-linecap="round"></path>
            <defs>
              <linearGradient id="paint0_linear_2_3" x1="199.027" y1="-6.29894e-06" x2="285.693" y2="1203"
                              gradientUnits="userSpaceOnUse">
                <stop stop-color="#d6b36a"></stop>
                <stop offset="1" stop-color="#d6b36a"></stop>
              </linearGradient>
            </defs>
          </svg>
          <div class="col-md-6 p-5">
            <div
                class="d-flex flex-column h-100 bg-gradient-faded-primary  rounded-3 text-center p-4 position-relative z-3">
              <div class="h5 mt-1 mb-0">{{ t('xstone.batch') }}</div>
              <div class="fs-lg mb-1">X-Stone Batch</div>
            </div>
          </div>
          <div class="col-md-6"></div>
          <div class="col-md-6"></div>
          <div class="col-md-6 p-5">
            <div
                class="d-flex flex-column h-100 bg-gradient-faded-info  rounded-3 text-center p-4 position-relative z-3">
              <div class="h5 mt-1 mb-0">
                {{ t('xstone.testLab') }}
              </div>
              <div class="fs-lg mb-1">X-Stone Test Lab</div>
            </div>
          </div>
          <div class="col-md-6 p-5">
            <div
                class="d-flex flex-column h-100 bg-gradient-faded-warning  rounded-3 text-center p-4 position-relative z-3">
              <div class="h5 mt-1 mb-0">
                {{ t('xstone.training') }}
              </div>
              <div class="fs-lg mb-1">X-Stone Training</div>
            </div>
          </div>
          <div class="col-md-6">
          </div>
          <div class="col-md-6"></div>
          <div class="col-md-6 p-5">
            <div
                class="d-flex flex-column h-100 bg-gradient-faded-danger  rounded-3 text-center p-4 position-relative z-3">
              <div class="h5 mt-1 mb-0">
                {{ t('xstone.support') }}
              </div>
              <div class="fs-lg mb-1">Report Support</div>
            </div>
          </div>
          <div class="col-md-6 p-5">
            <div
                class="d-flex flex-column h-100 bg-gradient-faded-secondary  rounded-3 text-center p-4 position-relative z-3">
              <div class="h5 mt-1 mb-0">
                {{ t('xstone.integration') }}
              </div>
              <div class="fs-lg mb-1">X-Stone Integration</div>
            </div>
          </div>
          <div class="col-md-6"></div>
          <div class="col-md-6"></div>
          <div class="col-md-6 p-5">
            <div
                class="d-flex flex-column h-100 bg-gradient-faded-success  rounded-3 text-center p-4 position-relative z-3">
              <div class="h5 mt-1 mb-0">
                {{ t('xstone.cacheManager') }}
              </div>
              <div class="fs-lg mb-1">X-Stone Cache Manager</div>
            </div>
          </div>
          <div class="col-md-6 p-5">

            <div
                class="d-flex flex-column h-100 bg-gradient-faded-dark  rounded-3 text-center p-4 position-relative z-3">
              <div class="h5 mt-1 mb-0">
                {{ t('xstone.configManager') }}
              </div>
              <div class="fs-lg mb-1">
                X-Stone Configuration Manager
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-bubble-wrapper">
      <div class="mo-title">
        <h2 class="mb-1 pt-4">
          {{ t('xstone.systemImplementationTitle') }}
        </h2>
        <h5 class="mb-4">{{ t('xstone.systemImplementationSubtitle') }}</h5>
      </div>
      <div class="mt-5 p-5 section-bubble">
        <div speech-bubble pbottom aright>
          <div class="title">{{ t('xstone.projectManagementPlan') }}</div>
          <code>Project Management Plan</code>
        </div>
        <div speech-bubble pbottom acenter>
          <div class="title">
            {{ t('xstone.updateProcess') }}
          </div>
          <code>Update Process</code>
        </div>
        <div speech-bubble pbottom aleft>
          <div class="title">{{ t('xstone.qualityManagementPlan') }}</div>
          <code>Quality Management Plan</code>
        </div>
        <div speech-bubble pleft abottom>
          <div class="title">{{ t('xstone.riskManagementPlan') }}</div>
          <code>Risk Management Plan</code>
        </div>
        <div speech-bubble pleft acenter>
          <div class="title">{{ t('xstone.implementationMethod') }}</div>
          <code>
            Application Implementation Method
          </code>
        </div>
        <div speech-bubble pleft atop>
          <div class="title">{{ t('xstone.supportProcess') }}</div>
          <code>Support Process</code>
        </div>
        <div speech-bubble ptop aleft flip>
          <div class="title">{{ t('xstone.forum') }}</div>
          <code>X-Stone Forum</code>
        </div>
        <div speech-bubble ptop aright flip>
          <div class="title">{{ t('xstone.kms_') }}</div>
          <code>KMS</code>
        </div>
        <div speech-bubble pright atop>
          <div class="title">{{ t('xstone.issueTracker') }}</div>
          <code>X-Stone Issue Tracker</code>
        </div>
        <div speech-bubble pright acenter>
          <div class="title">
            {{ t('xstone.task_') }}
          </div>
          <code>X-Stone Issue Tracker</code>
        </div>
        <div speech-bubble pright abottom>
          <div class="title"> {{ t('xstone.importFromExcel') }}</div>
          <code>Import from Exel</code>
        </div>
        <div class="middle" v-if="locale === 'fa'">
          <h2 class="h3">
            اﺳﺘﻘﺮار و
            <br>
            پشتیبانی ﺳﯿﺴﺘﻢ
          </h2>
          <code>System Implementation</code>
        </div>
        <div class="middle" v-if="locale === 'en'">
          <h2 class="h3">
            System
            <br>
            Implementation
          </h2>
        </div>
      </div>
    </section>
    <section class="mt-5 mb-5 tools-section">
      <div class="container">
        <h2 class="mb-1 pt-4"> {{ t('xstone.softwareProductionLineTitle') }}</h2>
        <h5 class="mb-4"> {{ t('xstone.softwareProductionLineSubtitle') }}</h5>
        <div class="row">
          <div class="col-md-3">
            <img class="m-0" :src="require(`@/assets/images/xstone/scrum.webp`)" alt="">
            <h2 class="h5 mt-3"> {{ t('xstone.scrum') }}</h2>
          </div>
          <div class="col-md-3">
            <img class="m-0" :src="require(`@/assets/images/xstone/sonarqube.webp`)" alt="">
            <h2 class="h5 mt-3"> {{ t('xstone.codeReview') }}</h2>
          </div>
          <div class="col-md-3">
            <img class="m-0" :src="require(`@/assets/images/xstone/nexus.webp`)" alt="">
            <h2 class="h5 mt-3"> {{ t('xstone.repository') }}</h2>
          </div>
          <div class="col-md-3">
            <img class="m-0" :src="require(`@/assets/images/xstone/maven.webp`)" alt="">
            <h2 class="h5 mt-3"> {{ t('xstone.moduleIntegration') }}</h2>
          </div>
          <div class="col-md-3 mt-4">
            <img class="m-0" :src="require(`@/assets/images/xstone/greenhopper.webp`)" alt="">
            <h2 class="h5 mt-3"> {{ t('xstone.scrum_') }}</h2>
          </div>
          <div class="col-md-3 mt-4">
            <img class="m-0" :src="require(`@/assets/images/xstone/jenkins.webp`)" alt="">
            <h2 class="h5 mt-3"> {{ t('xstone.automatedBuild') }}</h2>
          </div>
          <div class="col-md-3 mt-4">
            <img class="m-0" :src="require(`@/assets/images/xstone/svn.webp`)" alt="">
            <h2 class="h5 mt-3"> {{ t('xstone.versionControl') }}</h2>
          </div>
          <div class="col-md-3 mt-4">
            <img class="m-0" :src="require(`@/assets/images/xstone/ubi.webp`)" alt="">
            <h2 class="h5 mt-3"> {{ t('xstone.versionControl') }}</h2>
          </div>
        </div>
      </div>
    </section>
    <div v-if="!isMobile">
      <Cursor/>
    </div>
  </div>
</template>

<script>
import Cursor from "@/components/Cursor";
import scrollSvg from 'scroll-svg'
import {useI18n} from "vue-i18n";

export default {
  components: {Cursor},
  data() {
    return {
      svg: null,
      svg2: null,
      svg3: null,
      isMobile: false,
    }
  },
  setup() {
    const {t, locale} = useI18n();

    return {
      t,
      locale,
    };
  },
  mounted() {
    const svgPath = document.querySelector('#scroll-line')
    this.svg = scrollSvg(svgPath)
    this.svg.animate();
    const svgPath2 = document.querySelector('#scroll-line-2')
    this.svg2 = scrollSvg(svgPath2)
    this.svg2.animate();
    const svgPath3 = document.querySelector('#scroll-line-3')
    this.svg3 = scrollSvg(svgPath3)
    this.svg3.animate();
  },
  unmounted() {
    this.svg.remove();
    this.svg2.remove();
    this.svg3.remove();
  },
  methods: {
    checkDevice() {
      this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  }
}
</script>

<style lang="scss" scoped>
.tools-section {
  img {
    width: 220px;
    height: 100px;
    object-fit: contain;
    object-position: right center;
  }
}

.section-bubble-wrapper {
  background-color: var(--color-2);
}

.section-bubble {
  margin-inline: auto;
  display: grid;
  grid-template-areas:
    'br bc bl'
    'rb cc lb'
    'rc cc lc'
    'rt cc lt'
    'tr tc tl';
  padding: 2rem;
  gap: 2rem;
  direction: ltr;
}

[speech-bubble] {
  --bbArrowSize: 1.5rem;
  --bbBorderRadius: 0.25rem;
  --bbPadding: 1rem;
  background: var(--color-1);
  border-radius: var(--bbBorderRadius);
  padding: var(--bbPadding);
  position: relative;
  text-align: center;
  font-family: Pelak, sans-serif;
}

[speech-bubble]::before {
  content: '';
  position: absolute;
  background: var(--color-1);
}

[speech-bubble][pbottom] {
  margin-bottom: var(--bbArrowSize)
}

[speech-bubble][ptop] {
  margin-top: var(--bbArrowSize);
}

[speech-bubble][pleft] {
  margin-left: var(--bbArrowSize);
}

[speech-bubble][pright] {
  margin-right: var(--bbArrowSize);
}

[speech-bubble][pbottom]::before,
[speech-bubble][ptop]::before {
  --width: calc(var(--bbArrowSize) / 2 * 3);
  height: var(--bbArrowSize);
  width: var(--width);
}

[speech-bubble][pbottom]::before {
  top: calc(100% - 2px);
}

[speech-bubble][pbottom][aleft]::before {
  left: 1rem;
  clip-path: polygon(25% 0, 100% 0, 0% 100%)
}

[speech-bubble][pbottom][acenter]::before {
  left: calc(50% - var(--width) / 2);
  clip-path: polygon(12.5% 0, 87.5% 0, 50% 100%)
}

[speech-bubble][pbottom][aright]::before {
  right: 1rem;
  clip-path: polygon(0 0, 75% 0, 100% 100%)
}

[speech-bubble][ptop]::before {
  bottom: calc(100% - 2px);
}

[speech-bubble][ptop][aleft]::before {
  left: var(--bbPadding);
  clip-path: polygon(0 0, 100% 100%, 25% 100%)
}

[speech-bubble][ptop][acenter]::before {
  left: calc(50% - var(--width) / 2);
  clip-path: polygon(12.5% 100%, 50% 0, 87.5% 100%)
}

[speech-bubble][ptop][aright]::before {
  right: var(--bbPadding);
  clip-path: polygon(0 100%, 100% 0, 75% 100%)
}

[speech-bubble][pleft]::before,
[speech-bubble][pright]::before {
  --height: calc(var(--bbArrowSize) / 2 * 3);
  width: var(--bbArrowSize);
  height: var(--height);
}

[speech-bubble][pright]::before {
  left: calc(100% - 2px);
}

[speech-bubble][pright][atop]::before {
  top: var(--bbPadding);
  clip-path: polygon(100% 0, 0 100%, 0 25%)
}

[speech-bubble][pright][acenter]::before {
  top: calc(50% - var(--height) / 2);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%)
}

[speech-bubble][pright][abottom]::before {
  bottom: var(--bbPadding);
  clip-path: polygon(0 0, 100% 100%, 0 75%)
}

[speech-bubble][pleft]::before {
  right: calc(100% - 2px);
}

[speech-bubble][pleft][atop]::before {
  top: var(--bbPadding);
  clip-path: polygon(0 0, 100% 25%, 100% 100%)
}

[speech-bubble][pleft][acenter]::before {
  top: calc(50% - var(--height) / 2);
  clip-path: polygon(0 50%, 100% 12.5%, 100% 87.5%);
}

[speech-bubble][pleft][abottom]::before {
  bottom: var(--bbPadding);
  clip-path: polygon(0 100%, 100% 0, 100% 75%)
}

[speech-bubble][pbottom][flip]::before,
[speech-bubble][ptop][flip]::before {
  transform: scaleX(-1)
}

[speech-bubble][pleft][flip]::before,
[speech-bubble][pright][flip]::before {
  transform: scaleY(-1)
}

.middle {
  grid-area: cc;
  align-self: center;
  justify-self: center;
  text-align: center;
  color: var(--color-1);
  font-size: 1.2rem;

  h2, code {
    font-family: Pelak, sans-serif;
  }
}

[speech-bubble][pbottom][aleft] {
  grid-area: bl
}

[speech-bubble][pbottom][acenter] {
  grid-area: bc
}

[speech-bubble][pbottom][aright] {
  grid-area: br
}

[speech-bubble][pright][atop] {
  grid-area: rt
}

[speech-bubble][pright][acenter] {
  grid-area: rc
}

[speech-bubble][pright][abottom] {
  grid-area: rb
}

[speech-bubble][pleft][atop] {
  grid-area: lt
}

[speech-bubble][pleft][acenter] {
  grid-area: lc
}

[speech-bubble][pleft][abottom] {
  grid-area: lb
}

[speech-bubble][ptop][aleft] {
  grid-area: tl
}

[speech-bubble][ptop][acenter] {
  grid-area: tc
}

[speech-bubble][ptop][aright] {
  grid-area: tr
}

[speech-bubble][pbottom],
[speech-bubble][ptop] {
  margin: 0
}


[speech-bubble] .title {
  color: white;
  margin-bottom: 0.5rem
}

[speech-bubble] code {
  color: #ffffff;
  margin: 0.125rem;
  white-space: nowrap;
  font-size: .9rem
}

.middle code {
  font-size: 1rem;
  color: var(--color-1);
}


.bg-section {
  background-image: url("@/assets/images/home/hero-bg3.webp");
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
}

.svg-section svg {
  position: absolute;
  right: 3rem;
  height: 100%;
  opacity: 1;
  display: block;
  top: 0;
}

.last-child {
  &::after {
    top: 0 !important;
  }
}

#scroll-line {
  transition: stroke-dashoffset 20ms ease-in-out;
}

p, li {
  text-align: justify;
  color: #e9ecef;
}

.steps {
  --si-steps-number-bg: #181b24;
  --si-steps-number-inner-bg: rgba(255, 255, 255, 0.04);
  --si-steps-padding-y: 1.5rem;
  --si-steps-padding-x: 1.5rem;
  --si-steps-number-size: 7.625rem;
  --si-steps-number-inner-size: 5.625rem;
  --si-steps-number-size-sm: 5rem;
  --si-steps-number-inner-size-sm: 3.5rem;
  --si-steps-number-border-radius: 50%;
  --si-steps-number-inner-box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(11, 15, 25, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(11, 15, 25, 0.03);
  --si-steps-number-font-size: 2rem;
  --si-steps-number-font-size-sm: 1.5rem;
  --si-steps-number-color: #fff;
  --si-steps-connect-width: 1px;
  --si-steps-connect-color: rgba(255, 255, 255, 0.14);
  display: flex;
  flex-wrap: wrap;
}

.step {
  position: relative;
  display: flex;
  width: 100%;
  padding: var(--si-steps-padding-y) 0;
}

.step::before, .step::after {
  position: absolute;
  right: calc(var(--si-steps-number-size) * .5);
  width: var(--si-steps-connect-width);
  height: 50%;
  content: "";
  z-index: 3;
}

.step::before {
  top: 0;
}

.step::after {
  top: 50%;
}

.step:not(:first-child)::before {
  background-color: var(--si-steps-connect-color);
}

.step:first-child {
  padding-top: 0 !important;
}

.step-number {
  position: relative;
  z-index: 4;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: var(--si-steps-number-size);
  height: var(--si-steps-number-size);
  border-radius: var(--si-steps-number-border-radius);
  color: var(--si-steps-number-color);
  background-color: var(--si-steps-number-bg);
  font-size: var(--si-steps-number-font-size);
  font-weight: 800;

  .step-number-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--si-steps-number-inner-size);
    height: var(--si-steps-number-inner-size);
    border-radius: var(--si-steps-number-border-radius);
    background-color: var(--si-steps-number-inner-bg);
    box-shadow: var(--si-steps-number-inner-box-shadow);
  }
}

.step-body {
  padding-left: var(--si-steps-padding-x);
}

.step:not(:last-child)::after {
  background-color: var(--si-steps-connect-color);
}

.x-stone-list {
  list-style-type: disc;
  list-style-position: inside;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: .5rem;
  }
}

h3 {
  color: var(--color-2);
}

.bg-secondary {
  background-color: rgba(255, 255, 255, 0.04) !important;
}

@-webkit-keyframes hero-spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes hero-spin {
  100% {
    transform: rotate(360deg);
  }
}

.hero-animation-spin {
  -webkit-animation: hero-spin 35s linear infinite;
  animation: hero-spin 35s linear infinite;
}


@-webkit-keyframes hero-fade {
  0%, 100% {
    opacity: 0
  }
  50% {
    opacity: 1
  }
}

@keyframes hero-fade {
  0%, 100% {
    opacity: 0
  }
  50% {
    opacity: 1
  }
}

.hero-animation-fade {
  -webkit-animation: hero-fade 4s ease-in infinite;
  animation: hero-fade 4s ease-in infinite;
}

.hero-animation-delay-1,
.hero-animation-delay-2,
.hero-animation-delay-3 {
  opacity: 0;
}

.hero-animation-delay-1 {
  animation-delay: .75s;
}

.hero-animation-delay-2 {
  animation-delay: 1.5s;
}

.hero-animation-delay-3 {
  animation-delay: 2s;
}

.absolute-bg {
  width: 125%;
  max-width: 49.75rem;
}

.mo-title {
  display: none;
}
.svg-container {
  display: inline-block;
  background-color: transparent;
  outline: none;
  border: none;
}
.text-sub{
  svg text {
    font-family: Tahoma,sans-serif;
    letter-spacing: 10px;
    stroke: #D6B36AFF;
    font-size: 50px;
    font-weight: 700;
    stroke-width: 3;
    animation: textAnimate 5s infinite alternate;
  }

  @keyframes textAnimate {
    0% {
      stroke-dasharray: 0 50%;
      stroke-dashoffset: 20%;
      fill: hsl(0, 0%, 100%)

    }

    100% {
      stroke-dasharray: 50% 0;
      stroke-dashoffstet: -20%;
      fill: hsla(189, 68%, 75%, 0%)
    }

  }
}
@media only screen and (max-width: 991px) {
  .absolute-bg {
    width: 100%;
    max-width: 30rem;
  }
}

@media only screen and (max-width: 940px) {
  .section-bubble {
    display: block;
    direction: rtl;
    margin-top: 0 !important;
    padding: 1rem !important;
  }
  .mo-title {
    display: block;
    text-align: center;
    margin-top: 1rem;
    color: var(--color-1);
  }
  .middle {
    display: none;
  }
  [speech-bubble] {
    margin: 0 0 .5rem !important;

    &:before {
      content: none !important;
    }
  }
}
.ltr{
  .tools-section img{
    width: 220px;
    height: 100px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: left center;
    object-position: left center;
  }
}
@media only screen and (max-width: 767px) {
  .tools-section {
    text-align: center;

    img {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
}

@media only screen and (max-width: 575px) {
  .d-mo-none {
    display: none;
  }
  .display-4 .text-sub {
    display: block;
  }
  .step-body {
    padding: 0;
  }
  .supportive-modules .col-md-6.p-5 {
    padding: 1rem !important;
  }
}
</style>