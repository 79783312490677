<template>
  <div class="tarsimate">
    <section class="position-relative pt-lg-3 pt-xl-4 pt-xxl-5 bg-space">
      <div class="bg-primary position-absolute start-0 bottom-0 w-100 d-none d-xl-block" style="height: 920px;"></div>
      <div class="bg-primary position-absolute start-0 bottom-0 w-100 d-none d-lg-block d-xl-none"
           style="height: 830px;"></div>
      <div class="bg-primary position-absolute start-0 bottom-0 w-100 d-lg-none d-xl-none" style="height: 62%;"></div>
      <div class="bg-opacity-10 rounded-circle position-absolute start-50 d-none d-lg-block"
           style="bottom: 220px; width: 480px; height: 480px; margin-left: -240px;"></div>
      <div class="container position-relative z-5 mt-2 pt-5 pb-2 pb-sm-4 pb-lg-5">
        <h1 class="display-3 text-center mx-auto pt-5 my-2 my-sm-1 main-title" style="max-width: 680px;">Tarsimate</h1>
        <h3 class="main-title text-center fw-normal my-2 my-sm-3">{{ t("tarsimate.assistant") }}</h3>
        <svg class="d-block mx-auto text-sub" width="511" height="40" viewBox="0 0 511 40" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M385.252 0.160149C313.41 0.614872 292.869 0.910678 253.008 2.06539C211.7 3.26203 182.137 4.46154 135.231 6.84429C124.358 7.39665 112.714 7.92087 99.0649 8.47247C48.9242 10.4987 39.1671 11.0386 22.9579 12.6833C14.9267 13.4984 7.98117 14.0624 4.08839 14.2162C0.627411 14.3527 0 14.4386 0 14.7762C0 15.0745 5.53537 15.3358 8.56298 15.1804C9.64797 15.1248 12.5875 14.9887 15.0956 14.8782C17.6037 14.7676 23.123 14.4706 27.3608 14.2183C37.3399 13.6238 42.1312 13.4363 59.2817 12.9693C88.1133 12.1844 109.893 11.43 136.647 10.2898C146.506 9.86957 159.597 9.31166 165.737 9.04993C212.308 7.06466 269.195 5.29439 303.956 4.74892C346.139 4.08665 477.094 3.50116 474.882 3.98441C474.006 4.17607 459.021 5.6015 450.037 6.34782C441.786 7.03345 428 8.02235 411.041 9.14508C402.997 9.67773 391.959 10.4149 386.51 10.7832C366.042 12.1673 359.3 12.5966 347.67 13.2569C294.096 16.2987 258.708 18.9493 209.451 23.6091C180.174 26.3788 156.177 29.5584 129.396 34.2165C114.171 36.8648 112.687 37.3352 114.186 39.0402C115.161 40.1495 122.843 40.2933 138.338 39.492C166.655 38.0279 193.265 36.8923 219.043 36.048C235.213 35.5184 237.354 35.4296 253.795 34.6075C259.935 34.3005 270.549 33.8517 277.382 33.6105L289.804 33.1719L273.293 32.999C248.274 32.7369 221.435 32.7528 212.596 33.035C183.334 33.9693 167.417 34.6884 141.419 36.2506C135.222 36.623 129.994 36.8956 129.801 36.8566C127.94 36.4786 169.612 30.768 189.492 28.6769C234.369 23.956 280.582 20.4337 351.602 16.3207C358.088 15.9451 371.108 15.1233 380.535 14.4947C389.962 13.866 404.821 12.8761 413.556 12.2946C447.177 10.057 457.194 9.22358 489.506 5.97543C510.201 3.895 510.311 3.8772 510.875 2.50901C511.496 1.00469 509.838 0.322131 505.088 0.127031C500.576 -0.0584957 416.098 -0.0351424 385.252 0.160149ZM291.144 33.02C291.536 33.0658 292.102 33.0641 292.402 33.0162C292.703 32.9683 292.383 32.9308 291.691 32.9329C290.999 32.935 290.753 32.9743 291.144 33.02Z"></path>
        </svg>
        <div class="row justify-content-center pt-3 pt-sm-4 pt-md-5 mt-sm-1">
          <div class="col-8 col-lg-4 order-lg-2" style="margin-top: -105px;">
            <div class="position-lg-sticky top-0 mb-5 pb-sm-2 pb-xl-4" style="padding-top: 110px;">
              <img class="d-block mx-auto" :src="require(`@/assets/images/tarsimateNew/astrotarsimate-03.webp`)"
                   width="322" v-if="locale === 'fa'"
                   alt="">
              <img class="d-block mx-auto" :src="require(`@/assets/images/tarsimateEN/astrotarsimate-03.webp`)"
                   width="322" v-if="locale === 'en'"
                   alt="">
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 order-lg-1 overflow-hidden mt-lg-4 pt-xl-3">
            <img class="d-none d-lg-block rounded-4 ms-auto"
                 :src="require(`@/assets/images/tarsimateNew/issue.webp`)" width="297" alt="" v-if="locale === 'fa'"
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto"
                 :src="require(`@/assets/images/tarsimateEN/issue.webp`)" width="297" alt="" v-if="locale === 'en'"
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5" v-if="locale === 'fa'"
                 :src="require(`@/assets/images/tarsimateNew/assesment.webp`)" width="297" alt=""
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5" v-if="locale === 'en'"
                 :src="require(`@/assets/images/tarsimateEN/assesment.webp`)" width="297" alt=""
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5" v-if="locale === 'fa'"
                 :src="require(`@/assets/images/tarsimateNew/crm.webp`)" width="297" alt=""
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5" v-if="locale === 'en'"
                 :src="require(`@/assets/images/tarsimateEN/crm.webp`)" width="297" alt=""
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5" v-if="locale === 'fa'"
                 :src="require(`@/assets/images/tarsimateNew/dashboard.webp`)" width="297" alt=""
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5" v-if="locale === 'en'"
                 :src="require(`@/assets/images/tarsimateEN/dashboard.webp`)" width="297" alt=""
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5" v-if="locale === 'fa'"
                 :src="require(`@/assets/images/tarsimateNew/kms.webp`)" width="297" alt=""
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5" v-if="locale === 'en'"
                 :src="require(`@/assets/images/tarsimateEN/kms.webp`)" width="297" alt=""
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5" v-if="locale === 'fa'"
                 :src="require(`@/assets/images/tarsimateNew/meeting.webp`)" width="297" alt=""
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5" v-if="locale === 'en'"
                 :src="require(`@/assets/images/tarsimateEN/meeting.webp`)" width="297" alt=""
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <div class="d-none d-xxl-block" style="height: 310px;"></div>
            <div class="d-none d-xl-block d-xxl-none" style="height: 280px;"></div>
            <div class="d-none d-lg-block d-xl-none" style="height: 200px;"></div>
            <div class="text-center text-sm-start mb-5 mx-auto mx-sm-0 pb-lg-2 pb-xl-4"
                 style="max-width: 340px;" data-disable-parallax-down="lg">
              <div class="d-table bg-opacity-10 rounded-1 p-2 mx-auto mx-sm-0 mb-3 mb-lg-4">
                <svg class="d-block m-1 text-warning" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12.0005 7.99608C11.897 7.99608 11.795 7.97508 11.699 7.93308L5.4485 5.18358C5.177 5.06358 5 4.79508 5 4.49658C5 4.19808 5.1755 3.92958 5.4485 3.80958L11.699 1.06007C11.891 0.97607 12.1115 0.97607 12.3035 1.06007L18.554 3.80958C18.8255 3.92958 19.0025 4.19808 19.0025 4.49658C19.0025 4.79508 18.827 5.06358 18.554 5.18358L12.3035 7.93308C12.206 7.97508 12.104 7.99608 12.0005 7.99608Z"></path>
                  <path
                      d="M12.907 9.3054C12.616 9.4329 12.313 9.4959 12.001 9.4959C11.689 9.4959 11.386 9.43291 11.098 9.30691L7 7.50391V8.19241C7 8.80291 7.3195 9.3759 7.831 9.6879C9.2725 10.5624 10.636 10.9989 11.9995 10.9989C13.363 10.9989 14.7265 10.5609 16.168 9.6879C16.681 9.3759 16.999 8.80291 16.999 8.19241V7.50391L12.907 9.3054Z"></path>
                  <path
                      d="M22.2495 4.99909H20.949C20.79 5.87959 20.2095 6.62959 19.38 7.00009H22.0005V19.0001H1.9995V7.00009H4.62C3.7905 6.62959 3.21 5.87959 3.0495 5.00059H1.7505C0.7905 4.99909 0 5.78959 0 6.74959V19.2491C0 20.2091 0.7905 20.9996 1.7505 20.9996H10.0005V22.9991H8.001C7.4505 22.9991 7.0005 23.4491 7.0005 23.9996C7.0005 24.5501 7.4505 25.0001 8.001 25.0001H16.0005C16.551 25.0001 17.001 24.5501 17.001 23.9996C17.001 23.4491 16.551 22.9991 16.0005 22.9991H14.001V20.9996H22.251C23.211 20.9996 24.0015 20.2091 24.0015 19.2491V6.74959C24 5.78959 23.2095 4.99909 22.2495 4.99909Z"></path>
                </svg>
              </div>
              <h2 class="h4 text-white mb-2 mb-lg-3">{{ t("tarsimate.access") }}</h2>
              <p class="text-white opacity-80 mb-0">

              </p>
            </div>
            <div class="text-center text-sm-start mb-5 mx-auto mx-sm-0 pb-lg-2 pb-xl-4 "
                 style="max-width: 340px;"
                 data-disable-parallax-down="lg">
              <div class="d-table bg-opacity-10 rounded-1 p-2 mx-auto mx-sm-0 mb-3 mb-lg-4">
                <svg class="d-block m-1 text-warning" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M17.4995 10.9995C13.916 10.9995 11 13.9155 11 17.499C11 21.0825 13.9145 24 17.4995 24C21.0845 24 23.999 21.084 23.999 17.5005C23.999 13.917 21.083 10.9995 17.4995 10.9995ZM21.803 15H19.9985V13.1955C20.7485 13.632 21.3665 14.2515 21.803 15ZM22.499 17.5005C22.499 17.8425 22.4645 18.177 22.3985 18.501H20V16.5H22.3985C22.4645 16.824 22.499 17.157 22.499 17.5005ZM12.4985 17.5005C12.4985 17.1585 12.533 16.824 12.599 16.5H14.999V18.4995H12.6005C12.5345 18.177 12.4985 17.8425 12.4985 17.5005ZM16.499 16.5H18.4985V18.4995H16.499V16.5ZM18.4985 12.6015V15H16.499V12.6015C16.8215 12.5355 17.156 12.501 17.4995 12.501C17.843 12.501 18.176 12.5355 18.4985 12.6015ZM14.999 13.1955V15H13.1945C13.631 14.2515 14.2505 13.632 14.999 13.1955ZM13.1945 19.9995H14.999V21.804C14.2505 21.3675 13.631 20.7495 13.1945 19.9995ZM16.499 22.3995V20.001H18.4985V22.3995C18.176 22.4655 17.8415 22.5 17.4995 22.5C17.1575 22.5 16.823 22.4655 16.499 22.3995ZM19.9985 21.804V19.9995H21.803C21.3665 20.7495 20.7485 21.3675 19.9985 21.804Z"></path>
                  <path
                      d="M16.0005 4.0005H15V1.0005C15 0.45 14.55 0 13.9995 0H3C2.1795 0 1.4205 0.3405 0.8805 0.8805C0.3405 1.4205 0 2.1705 0 3V19.0005C0 20.6505 1.35 22.0005 3 22.0005H10.29C9.4695 20.7 9 19.1505 9 17.5005C9 12.981 12.54 9.27 16.9995 9.0105V5.001C16.9995 4.4505 16.5495 4.0005 16.0005 4.0005ZM13.0005 4.0005H3C2.4495 4.0005 1.9995 3.5505 1.9995 3C1.9995 2.4495 2.4495 1.9995 3 1.9995H13.0005V4.0005Z"></path>
                </svg>
              </div>
              <h2 class="h4 text-white mb-2 mb-lg-3">{{ t("tarsimate.library") }}</h2>
              <p class="text-white opacity-80 mb-0">

              </p>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 order-lg-3 overflow-hidden mt-lg-4 pt-xl-3">
            <img class="d-none d-lg-block rounded-4" :src="require(`@/assets/images/tarsimateNew/dms.webp`)"
                 v-if="locale === 'fa'"
                 width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4" :src="require(`@/assets/images/tarsimateEN/dms.webp`)"
                 v-if="locale === 'en'"
                 width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5" v-if="locale === 'fa'"
                 :src="require(`@/assets/images/tarsimateNew/project.webp`)"
                 width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5" v-if="locale === 'en'"
                 :src="require(`@/assets/images/tarsimateEN/project.webp`)"
                 width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5" :src="require(`@/assets/images/tarsimateNew/social.webp`)"
                 v-if="locale === 'fa'"
                 width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5" :src="require(`@/assets/images/tarsimateEN/social.webp`)"
                 v-if="locale === 'en'"
                 width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5" :src="require(`@/assets/images/tarsimateNew/wallet.webp`)"
                 v-if="locale === 'fa'"
                 width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5" :src="require(`@/assets/images/tarsimateEN/wallet.webp`)"
                 v-if="locale === 'en'"
                 width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5"
                 :src="require(`@/assets/images/tarsimateNew/workCalendar.webp`)" v-if="locale === 'fa'"
                 width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5"
                 :src="require(`@/assets/images/tarsimateEN/workCalendar.webp`)" v-if="locale === 'en'"
                 width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5" :src="require(`@/assets/images/tarsimateNew/workFlow.webp`)"
                 v-if="locale === 'fa'"
                 width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5" :src="require(`@/assets/images/tarsimateEN/workFlow.webp`)"
                 v-if="locale === 'en'"
                 width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <div class="d-none d-xxl-block" style="height: 520px;"></div>
            <div class="d-none d-xl-block d-xxl-none" style="height: 490px;"></div>
            <div class="d-none d-lg-block d-xl-none" style="height: 400px;"></div>
            <div class="text-center text-sm-start mb-5 mx-auto me-sm-0 pb-lg-2 pb-xl-4"
                 style="max-width: 340px;"
                 data-disable-parallax-down="lg">
              <div class="d-table bg-opacity-10 rounded-1 p-2 mx-auto mx-sm-0 mb-3 mb-lg-4">
                <svg class="d-block m-1 text-warning" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M11.9986 0C5.38318 0 0 5.38318 0 11.9986C0 18.6141 5.38041 24 11.9986 24C18.6168 24 23.9972 18.6168 23.9972 12.0014C23.9972 5.38595 18.6141 0 11.9986 0ZM16.7837 17.0052C16.5095 17.3541 16.1025 17.5396 15.6899 17.5396C15.3936 17.5396 15.0945 17.4427 14.8397 17.2489L10.6861 14.0173C10.3482 13.7542 10.1516 13.3499 10.1516 12.9235V6.92281C10.1516 6.15853 10.7719 5.53825 11.5362 5.53825C12.3005 5.53825 12.9207 6.15853 12.9207 6.92281V12.2451L16.54 15.0613C17.1437 15.5292 17.2517 16.4015 16.7837 17.0052Z"></path>
                </svg>
              </div>
              <h2 class="h4 text-white mb-2 mb-lg-3">{{ t("tarsimate.easy") }}</h2>
              <p class="text-white opacity-80 mb-0">

              </p>
            </div>
            <div class="text-center text-sm-start mb-5 mx-auto me-sm-0 pb-lg-2 pb-xl-4 "
                 style="max-width: 340px;" data-disable-parallax-down="lg">
              <div class="d-table  bg-opacity-10 rounded-1 p-2 mx-auto mx-sm-0 mb-3 mb-lg-4">
                <svg class="d-block m-1 text-warning" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M19.9524 20.9857C17.8306 22.8676 15.057 24 12.0132 24C5.38642 24 0 18.6128 0 11.985C0 5.60651 4.98548 0.379975 11.2624 0V5.02678C7.74852 5.39775 5.005 8.38048 5.005 11.985C5.005 15.8493 8.14795 18.9942 12.0132 18.9942C13.6755 18.9942 15.2072 18.413 16.4085 17.4428L19.9524 20.9857Z"></path>
                  <path
                      d="M24.0042 12.7358C23.8436 15.4797 22.7534 17.9818 21.0205 19.9253L17.4766 16.3808C18.298 15.3701 18.8386 14.1085 18.9782 12.7358H24.0042Z"></path>
                  <path
                      d="M23.998 11.234H18.9719C18.6311 7.97046 16.0287 5.36621 12.7656 5.02678V0C18.7917 0.360451 23.6376 5.20701 23.998 11.234Z"></path>
                </svg>
              </div>
              <h2 class="h4 text-white mb-2 mb-lg-3">{{ t("tarsimate.integrated") }}</h2>
              <p class="text-white opacity-80 mb-0">

              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="content-section">
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in issueTracker" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light border-start">
              <div class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">{{ t("tarsimate.issue") }}</h2>
                  <p class="pb-3 mb-lg-4">Issue Tracker</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateNew/issue.webp`)" v-if="locale === 'fa'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateEN/issue.webp`)" v-if="locale === 'en'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5 bg-third">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in EDMS" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div
                  class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5 border-start">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">{{ t("tarsimate.dms") }}</h2>
                  <p class="pb-3 mb-lg-4">EDMS</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateNew/dms.webp`)" v-if="locale === 'fa'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateEN/dms.webp`)" v-if="locale === 'en'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in projectManagement" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div
                  class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5 border-start">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">{{ t("tarsimate.project") }}</h2>
                  <p class="pb-3 mb-lg-4">Project Management</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateNew/project.webp`)" v-if="locale === 'fa'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateEN/project.webp`)" v-if="locale === 'en'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5 bg-third">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in CRM" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div
                  class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5 border-start">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">{{ t("tarsimate.crm") }}</h2>
                  <p class="pb-3 mb-lg-4">CRM</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateNew/crm.webp`)" v-if="locale === 'fa'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateEN/crm.webp`)" v-if="locale === 'en'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in wallet" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div
                  class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5 border-start">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">{{ t("tarsimate.wallet") }}</h2>
                  <p class="pb-3 mb-lg-4">Wallet</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateNew/wallet.webp`)" v-if="locale === 'fa'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateEN/wallet.webp`)" v-if="locale === 'en'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5 bg-third">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in assessment" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div
                  class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5 border-start">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">{{ t("tarsimate.assessment") }}</h2>
                  <p class="pb-3 mb-lg-4">Assessment</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateNew/assesment.webp`)" v-if="locale === 'fa'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateEN/assesment.webp`)" v-if="locale === 'en'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in KMS" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div
                  class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5 border-start">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">{{ t("tarsimate.kms") }}</h2>
                  <p class="pb-3 mb-lg-4">KMS</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateNew/kms.webp`)" v-if="locale === 'fa'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateEN/kms.webp`)" v-if="locale === 'en'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5 bg-third">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in social" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div
                  class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5 border-start">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">{{ t("tarsimate.social") }}</h2>
                  <p class="pb-3 mb-lg-4">Social</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateNew/social.webp`)" v-if="locale === 'fa'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateEN/social.webp`)" v-if="locale === 'en'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in meeting" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div
                  class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5 border-start">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h3">{{ t("tarsimate.meeting") }}</h2>
                  <p class="pb-3 mb-lg-4">Meeting</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateNew/meeting.webp`)" v-if="locale === 'fa'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateEN/meeting.webp`)" v-if="locale === 'en'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5 bg-third">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in BPMS" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div
                  class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5 border-start">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">{{ t("tarsimate.bpms") }}</h2>
                  <p class="pb-3 mb-lg-4">BPMS</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateNew/workFlow.webp`)" v-if="locale === 'fa'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateEN/workFlow.webp`)" v-if="locale === 'en'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in BI" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div
                  class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5 border-start">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">{{ t("tarsimate.bi") }}</h2>
                  <p class="pb-3 mb-lg-4">BI</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateNew/dashboard.webp`)"  v-if="locale === 'fa'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimateEN/dashboard.webp`)" v-if="locale === 'en'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5 bg-third">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in calender" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div
                  class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5 border-start">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">{{ t("tarsimate.calendar") }}</h2>
                  <p class="pb-3 mb-lg-4">Calender</p>
                  <img class="d-block rounded-4"
                       :src="require(`@/assets/images/tarsimateNew/workCalendar.webp`)" v-if="locale === 'fa'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                  <img class="d-block rounded-4"
                       :src="require(`@/assets/images/tarsimateEN/workCalendar.webp`)" v-if="locale === 'en'"
                       width="297" alt="" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="aa-co position-fixed end-0 m-3">
      <img class="aa-el" alt="" style="width: 50px;" :src="require(`@/assets/images/home/astro2.webp`)"/>
    </div>
  </div>
</template>

<script>
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {useI18n} from "vue-i18n";
import {ref, computed} from "vue";

gsap.registerPlugin(ScrollTrigger);
export default {
  data() {
    return {}
  },
  setup() {
    const {t, locale} = useI18n();

    const issueTracker_FA = ref([
      {
        title: 'مدیریت بورد و لیست',
        description: 'امکان ایجاد،ویرایش و سازماندهی بوردها و لیست های وظایف برای دسته بندی بهتر کارها.'
      },
      {
        title: 'ثبت نامحدود وظایف',
        description: 'امکان ایجاد و مدیریت تعداد نامحدود وظایف توسط کاربران'
      },
      {
        title: 'آرشیو وظایف',
        description: 'امکان بایگانی وظایف تکمیل شده یا غیرفعال برای دسترسی در آینده'
      },
      {
        title: 'اولویت بندی وظایف',
        description: 'قابلیت تعیین اولویت وظایف برای بهینه سازی مدیریت زمان و تمرکز روی کارهای مهم تر'
      },
      {
        title: 'مدیریت اعضا',
        description: 'افزودن،حذف و تنظیم نقش اعضای تیم برای همکاری بهتر'
      },
      {
        title: 'ثبت و مدیریت برچسب',
        description: 'ایجاد و استفاده از برچسب ها برای دسته بندی و جستجوی سریع تر وظایف'
      },
      {
        title: 'افزودن انواع پیوست',
        description: 'امکان ضمیمه کردن فایل ها و اسناد مرتبط به هر وظیفه'
      },
      {
        title: 'ثبت چک لیست برای هر وظیفه',
        description: 'تعریف لیست اقدامات مورد نیاز برای تکمیل یک وظیفه'
      },
      {
        title: 'درج کامنت بصورت نامحدود',
        description: 'اعضای تیم می توانند بدون محدودیت برای هر وظیفه نظر یا توضیح اضافه کنند'
      },
      {
        title: 'مشاهده روند پیشرفت وظیفه',
        description: 'نمایش میزان پیشرفت و وضعیت هر وظیفه در طول انجام آن'
      },
      {
        title: 'ثبت تاریخ و ساعت انجام وظیفه',
        description: 'تعیین زمان دقیق انجام وظایف برای برنامه ریزی بهتر'
      },
      {
        title: 'مدیریت سطوح دسترسی اعضا در هر بورد',
        description: 'تنظیم سطح دسترسی هر عضو به وظایف و اطلاعات بوردها.'
      },
      {
        title: 'مشاهده لیست وظایف من',
        description: 'مدیران می توانند لیست وظایف سایر اعضای تیم را مشاهده کنند.'
      },
      {
        title: 'مشاهده لیست وظایف اعضا',
        description: 'مدیران می توانند لیست وظایف سایر اعضای تیم را مشاهده کنند.'
      },
      {
        title: 'جستجوی پیشرفته',
        description: 'یافتن سریع وظایف با استفاده از فیلتر های متنوع'
      }
    ]);
    const issueTracker_EN = ref([
      {
        title: 'Board and list Management',
        description: 'Ability to create, edit, and organize boards and task lists for better task categorization'
      },
      {
        title: 'Unlimited Task Creation',
        description: 'Users can create and manage an unlimited number of tasks'
      },
      {
        title: 'Task Archiving',
        description: 'Ability to archive completed or inactive tasks for futures reference'
      },
      {
        title: 'Task Prioritization',
        description: 'Ability to set task priorities for better time management and focus on important tasks'
      },
      {
        title: 'Member Management',
        description: 'Adding, removing, and assigning roles to team members for better collaboration '
      },
      {
        title: 'Tag Creation and Management',
        description: 'Create and use tags for better categorization and faster task searching'
      },
      {
        title: 'Adding Various Attachments',
        description: 'Attach files and documents related to each task.'
      },
      {
        title: 'Creating a Checklist For each Task',
        description: 'Define a list of required actions to complete a task.'
      },
      {
        title: 'Unlimited Comments',
        description: 'team members can add unlimited comments or notes to each task.'
      },
      {
        title: 'Task Progress Tracking',
        description: 'Monitor task progress and status throughout its lifecycle'
      },
      {
        title: 'Setting Task Due Date and Time',
        description: 'set specific due dates and times for better scheduling.'
      },
      {
        title: 'Managing member access levels in each board',
        description: 'control access levels of each member to tasks and board information'
      },
      {
        title: 'viewing “my task” list',
        description: 'view all tasks assigned to the user.'
      },
      {
        title: 'viewing team members Task list',
        description: 'Managers can view task list of other team members'
      },
      {
        title: 'Advanced Search',
        description: 'Quickly find tasks using various filters'
      }
    ]);
    const EDMS_FA = ref([
      {
        title: 'مدیریت انواع فایل پیوست',
        description: 'امکان پشتیبانی و سازماندهی انواع فایل های پیوست شده به وظایف'
      },
      {
        title: 'مدیریت Mime/ Type هر پیوست',
        description: 'کنترل و تنظیم نوع MIMEبرای اطمینان از سازگاری و امنیت فایل ها'
      },
      {
        title: 'تعریف محدودیت تعداد و حجم',
        description: 'امکان تعیین حداکثرتعداد و حجم فایل های پیوست شده'
      },
      {
        title: 'مدیریت دسترسی اعضا',
        description: 'کنترل سطح دسترسی کاربران به اسناد و فایل های پیوست شده'
      },
      {
        title: 'مدیریت رمزگذاری',
        description: 'رمز گذاری فایل های حساس برای افزایش امنیت اطلاعات .'
      },
      {
        title: 'مدیریت تغییرات نسخه',
        description: 'ثبت وپیگیری تغییرات هر نسخه از اسناد پیوست شده'
      },
      {
        title: 'تعریف کدینگ',
        description: 'امکان اختصاص کدهای منحصربفرد به اسناد برای دسته بندی بهتر'
      },
      {
        title: 'تعریف و مدیریت گردش اسناد',
        description: 'ایجاد فرآیندهای مشخص برای بررسی،تایید و به روزرسانی اسناد'
      }
    ]);
    const EDMS_EN = ref([
      {
        title: 'Managing Attachment File Types',
        description: 'Support and organization of various attached file types.'
      },
      {
        title: ' Managing MIME Type for Attachments',
        description: 'control and configure MIME types to ensure file compatibility and security'
      },
      {
        title: 'Defining Attachment Quantity and Size Limits',
        description: 'Set maximum number and size limits for attached files.'
      },
      {
        title: 'Managing Member Access',
        description: 'Control User access levels to documents and attached files.'
      },
      {
        title: 'Encryption Management',
        description: 'Encrypt sensitive Files to enhance data security'
      },
      {
        title: 'Version Control Management',
        description: ' Track and manage changes in each version of attached documents'
      },
      {
        title: 'Defining document Coding',
        description: 'Assign unique codes to documents for better categorization'
      },
      {
        title: 'Defining and managing Document Workflow',
        description: 'Establish structured processes for document review, approval, and updates'
      }
    ]);
    const projectManagement_FA = ref([
      {
        title: 'تعریف نامحدود پروژه',
        description: 'امکان ایجاد و مدیریت تعداد نامحدودی پروژه بدون محدودیت.'
      },
      {
        title: 'تعریف WBS فعالیت ها',
        description: 'ساختار شکست کار(WBS)را برای سازماندهی فعالیت های پروژه تعریف کنید'
      },
      {
        title: 'تعریف روابط بین فعالیت ها',
        description: 'ایجاد وابستگی ها و روابط منطقی بین فعالیت های پروژه '
      },
      {
        title: 'وزن دهی به فعالیت ها',
        description: 'اختصاص وزن به فعالیت ها برای تعیین اهمیت و تاثیر هریک در پروژه'
      },
      {
        title: 'محاسبه درصد پیشرفت پروژه',
        description: 'محاسبه خودکار میزان پیشرفت پروژه بر اساس داده های ثبت شده.'
      },
      {
        title: 'نمایش گانت چارت پیشرفت پروژه',
        description: 'ارائه نمای گرافیکی از برنامه زمان بندی و پیشرفت پروژه'
      },
      {
        title: 'دریافت گزارش',
        description: 'امکان دریافت گزارش های متنوع برای بررسی عملکرد پروژه'
      },
      {
        title: 'تخصیص نامحدود منابع',
        description: 'مدیریت و تخصیص تعداد نامحدودی از منابع به پروژه ها'
      },
      {
        title: 'نمایش تاخیرها',
        description: 'شناسایی و نمایش تاخیرهای پروژه برای مدیریت بهتر زمان'
      },
      {
        title: 'تعریف و مدیریت گردش فعالیت ها',
        description: 'ایجاد فرآیندهای مشخص برای انجام،بررسی و تایید فعالیت ها.'
      },
      {
        title: 'تقویم کاری یکپارچه',
        description: 'تعریف ومدیریت یک تقویم کاری هماهنگ برای تمام فعالیت های پروژه.'
      }
    ]);
    const projectManagement_EN = ref([
      {
        title: 'Unlimited Project Definition',
        description: 'Create and manage an unlimited number of projects.'
      },
      {
        title: 'Defining WBS for Activities ',
        description: 'Define the Work Breakdown Structure (WBS) to organize project activities'
      },
      {
        title: 'Defining Relationships Between Activities',
        description: 'Establish dependencies and logical relationships between project activities.'
      },
      {
        title: 'Assigning Weights to Activities',
        description: 'Assign weights to activities to determine their importance and impact on the project'
      },
      {
        title: 'Project Progress percentage Calculation',
        description: 'Automatically Calculate project progress based on recorded data.'
      },
      {
        title: 'Displaying Project Progress Gantt chart',
        description: 'Provide a visual representation of project schedule and progress'
      },
      {
        title: 'Generating Reports',
        description: 'Generate various report to analyze project performance'
      },
      {
        title: 'Unlimited Resource Allocation',
        description: 'Manage and allocate an unlimited number of resources to projects.'
      },
      {
        title: 'Displaying Delays',
        description: 'Identify and display project delays for better time management'
      },
      {
        title: 'Defining and Managing Activity Workflow',
        description: 'Establish structured processes for executing, reviewing, and approving activities'
      },
      {
        title: 'Integrated Work Calendar',
        description: 'Define and Manage a unified work calendar for all project activities.'
      }
    ]);
    const CRM_FA = ref([
      {
        title: 'گروه بندی مشتریان',
        description: 'دسته بندی مشتریان براساس معیارهای مختلف برای مدیریت بهتر.'
      },
      {
        title: 'مدیریت پرونده مشتریان',
        description: 'ایجاد، ذخیره و ویرایش اطاعات مشتریان در یک سیستم متمرکز'
      },
      {
        title: 'مدیریت تیکتینگ',
        description: 'ثبت،پیگیری و رسیدگی به درخواست ها و مشکلات مشتریان'
      },
      {
        title: 'تعریف و مدیریت گردش تیکت ها',
        description: 'ایجاد فرایند مشخص برای ارجاع و پیگیری تیکت ها تا حل کامل آنها'
      },
      {
        title: 'مشاهده و مدیریت روند پیگیری مشتری',
        description: 'کنترل و بررسی تاریخچه تعاملات و پیگیری های انجام شده برای هر مشتری '
      },
      {
        title: 'دریافت گزارش',
        description: 'تولید گزارش های تحلیلی برای بررسی عملکرد و روند رسیدگی به مشتریان'
      },
      {
        title: 'تقویم کاری یکپارچه',
        description: 'برنامه ریزی و زمانبندی پیگیری ها و تعاملات مشتریان در یک تقویم مشترک.'
      },
      {
        title: 'مدیریت و تعریف انواع فرم نظرسنجی',
        description: 'ایجاد و مدیریت فرم های نظرسنجی برای جمع آوری بازخورد مشتریان.'
      },
      {
        title: 'مدیریت بانک پیام',
        description: 'ذخیره و سازماندهی پیام های آماده برای ارتباط سریع با مشتریان.'
      },
      {
        title: 'فراخوانی پیام / فرم در مراحل مختلف',
        description: 'امکان ارسال پیام ها و فرم ها در نقاط مختلف فرایند خدمات رسانی.'
      },
      {
        title: 'مدیریت گارانتی / وارانتی',
        description: 'ثبت، پیگیری و کنترل شرایط گارانتی و وارانتی محصولات و خدمات.'
      }
    ]);
    const CRM_EN = ref([
      {
        title: 'Customer Grouping',
        description: 'Categorize customers based on various criteria for better management.'
      },
      {
        title: 'Customer File Management',
        description: 'Create, store, and edit customer information in a centralized system.'
      },
      {
        title: 'Ticketing Management',
        description: 'log, track, and resolve customer requests and issues.'
      },
      {
        title: 'Defining and Managing Ticket Workflow',
        description: 'Establish Structured processes for ticket assignment and resolution.'
      },
      {
        title: 'Tracking and Managing Customer Follow-ups',
        description: 'Monitor and review the history of interactions and follow-ups for each customer. '
      },
      {
        title: 'Generating Reports',
        description: 'Generate analytical reports to evaluate performance and customer interactions.'
      },
      {
        title: 'Integrated work calendar',
        description: 'Plan and schedule customer follow-ups and interaction in a unified calendar.'
      },
      {
        title: 'Survey Form Management',
        description: 'Create and manage survey forms to collect customer feedback.'
      },
      {
        title: 'Message Bank Management',
        description: 'Store and organize predefined messages for quick customer feedback'
      },
      {
        title: ' Triggering Messages/Forms at Different Stages.',
        description: 'Send messages and forms at various stages of the service process.'
      },
      {
        title: 'Warranty/Guarantee Management.',
        description: 'Record, Track, and manage warranty and guarantee conditions for products and services.'
      }
    ]);
    const wallet_FA = ref([
      {
        title: 'مدیریت حساب ها',
        description: 'ایجاد، کنترل و مدیریت حساب های مالی مختلف در سیستم '
      },
      {
        title: 'مدیریت انواع فرآیندهای مالی',
        description: 'تعریف و پیگیری انواع فرآیندهای مالی شامل پرداخت ها، دریافت ها و هزینه ها'
      },
      {
        title: 'تسهیم',
        description: 'تقسیم و تخصیص هزینه ها یا درآمدها بین بخش ها و افراد مختلف.'
      },
      {
        title: 'مدیریت روش های تسویه',
        description: 'تعریف و کنترل روش های مختلف تسویه حساب،مانند نقدی،کارت خوان و آنلاین.'
      },
      {
        title: 'مدیریت درگاه',
        description: 'اتصال، کنترل و مدیریت درگاه های پرداخت برای انجام تراکنش های مالی.'
      },
      {
        title: 'دریافت گزارش',
        description: 'تولید گزارش های مالی برای تحلیل تراکنش ها و عملکرد مالی.'
      },
      {
        title: 'تعریف و مدیریت فرآیندهای مالی',
        description: 'ایجاد، اصلاح و بهینه سازی جریان های مالی در سازمان.'
      }
    ]);
    const wallet_EN = ref([
      {
        title: 'Account Management',
        description: 'Create, control, and manage various financial accounts in the system.'
      },
      {
        title: 'Managing Financial Processes',
        description: 'Define and track various financial Processes, including payment, receipts, and expenses..'
      },
      {
        title: ' Cost/Revenue Allocation ',
        description: 'Distribute and allocate costs or revenues among different departments and individuals.'
      },
      {
        title: 'settlement Method Management',
        description: 'Define and control different settlement methods, such as cash, Pos, and online Payments.'
      },
      {
        title: 'Gateway Management',
        description: 'connect, monitor and manage payment gateways for financial transactions'
      },
      {
        title: 'Generating Reports',
        description: 'Generate financial reports to analyze transactions and financial transactions.'
      },
      {
        title: 'Defining and Managing Financial Processes',
        description: 'create, modify, and optimize financial workflows within the organization.'
      }
    ]);
    const assessment_FA = ref([
      {
        title: 'تعریف انواع ارزیابی',
        description: 'ایجاد و مدیریت انواع روش های ارزیابی برای سنجش عملکرد'
      },
      {
        title: 'ثبت و محاسبه شاخص های عملکردی',
        description: 'اندازه گیری و تخصیص شاخص ای عملکردی(KPIs)برای ارزیابی عملکرد'
      },
      {
        title: 'تعریف انواع سوال و جواب',
        description: 'ایجاد و مدیریت انواع سوالات و پاسخ های مورد نیاز در ارزیابی ها. '
      },
      {
        title: 'تخصیص امتیاز به سوال و جواب',
        description: 'وزن دهی و تعیین امتیاز برای هر سوال و پاسخ به نظور ارزیابی دقیق تر.'
      },
      {
        title: 'دریافت گزارش های عملکردی',
        description: 'ایجاد و تحلیل گزارش های عملکردی برای بررسی نتایج ارزیابی ها. '
      }
    ]);
    const assessment_EN = ref([
      {
        title: 'Defining Evaluation Types',
        description: 'Create and Manage different evaluation methods for performance assessment.'
      },
      {
        title: 'Recording and calculating performance indicators',
        description: 'Measure and analyze key performance indicators(KPIs)for evaluation.'
      },
      {
        title: 'Defining Question and Answer types',
        description: 'create and manage various question and answer formats for evaluations. '
      },
      {
        title: 'Assigning scores to questions and answers',
        description: 'Assign weights and scores to questions and answer for more precise evaluation.'
      },
      {
        title: 'Generating performance Reports',
        description: 'Create and analyze performance reports to review evaluation results. '
      }
    ]);
    const KMS_FA = ref([
      {
        title: 'ایجاد درختواره دانش',
        description: 'ساختاردهی و سازماندهی دانش در قالب یک درختواره برای دسترسی بهتر.'
      },
      {
        title: 'نشر دانش',
        description: 'اشتراک گذاری و انتشار دانش در سازمان برای استفاده کاربران.'
      },
      {
        title: 'ثبت و مدیریت سوال',
        description: 'ایجاد،ذخیره و مدیریت سوالات مرتبط با دانش سازمانی.'
      },
      {
        title: 'ارزیابی دانش',
        description: 'سنجش و تحلیل کیفیت و میزان تاثیر گذاری دانش منتشر شده.'
      },
      {
        title: 'جست و جوی دانش',
        description: 'امکان جستجوی سریع و دقیق  در پایگاه دانش سازمان.'
      },
      {
        title: 'مدیریت دسترسی اعضا',
        description: 'کنترل سطح دسترسی کاربران به اطلاعات و منابع دانش.'
      },
      {
        title: 'مدیریت و تعریف گردش دانش',
        description: 'ایجاد فرآیندهای مشخص برای جمع آوری، تایید وانتشار دانش. '
      },
      {
        title: 'ارتباط یکپارچه با مدیریت اسناد',
        description: 'اتصال سیستم مدیریت دانش به مدیریت اسناد برای دسترسی بهتر'
      }
    ]);
    const KMS_EN = ref([
      {
        title: 'creating a knowledge tree',
        description: 'structure and organize knowledge in a tree format for better accessibility.'
      },
      {
        title: 'Knowledge Publishing',
        description: 'share and distribute knowledge within the organization for user access.'
      },
      {
        title: 'Recording and Managing Questions',
        description: 'create, store, and manage questions related to organizational knowledge.'
      },
      {
        title: 'Knowledge Evaluation',
        description: 'Assess and analyze the quality and impact of published knowledge.'
      },
      {
        title: 'Knowledge Search',
        description: 'Enable quick and precise searching within the organizational knowledge. '
      },
      {
        title: 'Managing Member Access',
        description: 'Control user access levels to knowledge resources and information.'
      },
      {
        title: 'Defining and Managing Knowledge Flow',
        description: 'Establish structured processes for knowledge collection approval, and distribution.'
      },
      {
        title: 'Seamless Integration with Document Management',
        description: 'Integrate the knowledge management system with document management for better accessibility.'
      }
    ]);
    const social_FA = ref([
      {
        title: 'ثبت و ارسال پست',
        description: 'امکان ایجاد، ویرایش و انتشار پست های جدید در سیستم.'
      },
      {
        title: 'درج نظر کاربران',
        description: 'کاربران می توانند نظرات خود را درباره پست ها ارسال و مشاهده کنند.'
      },
      {
        title: 'رزومه ساز',
        description: 'ایجاد و ویرایش رزومه حرفه ای با اطلاعات شخصی و کاری.'
      },
      {
        title: 'ارسال پیام خصوصی',
        description: 'کاربران می توانند به یکدیگر پیام خصوصی ارسال کنند.'
      },
      {
        title: 'مدیریت دنبال کنندگان',
        description: 'مشاهده و کنترل فهرست دنبال کنندگان و دنبال شوندگان'
      },
      {
        title: 'ساخت رویداد',
        description: 'ایجاد  مدیریت رویدادهای مختلف با قابلیت اشتراک گذاری'
      }
    ]);
    const social_EN = ref([
      {
        title: 'post creation and publishing',
        description: 'Create, edit, and publish new posts in the system.'
      },
      {
        title: 'User Commenting',
        description: 'Users can submit and view comments on posts.'
      },
      {
        title: 'Resume Builder',
        description: 'Create and edit a professional resume with personal and work details.'
      },
      {
        title: 'Private Messaging',
        description: 'Users can send Private messages to each other.'
      },
      {
        title: 'follower Management',
        description: 'view and manage follower and following lists.'
      },
      {
        title: 'Event Creation',
        description: 'Create and manage various events with sharing options.'
      }
    ]);
    const meeting_FA = ref([
      {
        title: 'مدیریت صورتجلسات',
        description: 'ایجاد، ذخیره و پیگیری صورتجلسات برای مستندسازی جلسات'
      },
      {
        title: 'مدیریت دعوت',
        description: 'برنامه ریزی و ارسال دعوت نامه برای شرکت کنندگان در جلسات.'
      },
      {
        title: 'مدیریت نقش ها',
        description: 'تعریف و تخصیص نقش های مختلف به اعضای جلسه برای کنترل دسترسی'
      },
      {
        title: 'ارتباط یکپارچه با مدیریت اسناد',
        description: 'اتصال و یکپارچه سازی صورتجلسات با سیستم مدیریت اسناد.'
      },
      {
        title: 'مدیریت مصوبات',
        description: 'ثبت، پیگیری و اجرای مصوبات جلسه بهصورت ساختارمند'
      },
      {
        title: 'ارتباط یکپارچه با موتور گردش کار',
        description: 'هماهنگسازی مصوبات و فرآیندهای جلسه با موتور گردش کار سازمانی'
      }
    ]);
    const meeting_EN = ref([
      {
        title: ' Meeting Minutes Management',
        description: ' Create, store, and track meeting minutes for documentation.'
      },
      {
        title: ' Invitation Management',
        description: ' Schedule and send invitations for meeting participants.'
      },
      {
        title: ' Role Management',
        description: ' Define and assign different roles to meeting participants for access control.'
      },
      {
        title: ' Seamless Integration with Document Management',
        description: ' Integrate meeting minutes with the document management system.'
      },
      {
        title: ' Resolution Management',
        description: ' Record, track, and implement meeting resolutions systematically.'
      },
      {
        title: ' Seamless Integration with Workflow Engine',
        description: ' Align meeting resolutions and processes with the organization’s workflow engine.'
      }
    ]);
    const BPMS_FA = ref([
      {
        title: 'محیط گرافیکی',
        description: ' ارائه یک رابط کاربری بصری برای طراحی و مدیریت فرآیندها'
      },
      {
        title: 'مطابق با استاندارد BPMN2',
        description: ' پشتیبانی از استاندارد BPMN2 برای مدلسازی فرآیندهای کسبوکار'
      },
      {
        title: 'ارتباط یکپارچه با سایر سیستم ها',
        description: ' امکان تبادل داده و هماهنگی با دیگر سیستمهای سازمانی'
      },
      {
        title: 'مدیریت ارتباط فعالیت ها',
        description: ' تعریف و کنترل روابط بین فعالیتهای مختلف در فرآیندها'
      },
      {
        title: 'مدیریت کلیدهای داینامیک',
        description: ' ایجاد و استفاده از کلیدهای متغیر برای انعطافپذیری در فرآیندها'
      },
      {
        title: 'تعریف زیرفرآیند',
        description: ' امکان تعریف و مدیریت زیر فرآیندها برای بهینهسازی گردش کار'
      }
    ]);
    const BPMS_EN = ref([
      {
        title: ' Graphical Interface',
        description: ' Provide a visual interface for designing and managing processes.'
      },
      {
        title: 'Compliance with BPMN2 Standard',
        description: ' Support BPMN2 standard for business process modeling.'
      },
      {
        title: ' Seamless Integration with Other Systems',
        description: ' Enable data exchange and coordination with other enterprise systems.'
      },
      {
        title: ' Activity Relationship Management',
        description: ' Define and control dependencies between various activities in processes.'
      },
      {
        title: ' Dynamic Key Management',
        description: ' Create and utilize variable keys for process flexibility.'
      },
      {
        title: ' Sub-process Definition',
        description: ' Define and manage sub-processes to optimize workflow.'
      }
    ]);
    const BI_FA = ref([
      {
        title: 'ایجاد گزارشات متنوع',
        description: ' امکان تولید گزارشهای سفارشی بر اساس دادههای مختلف'
      },
      {
        title: 'فراخوانی گزارشات در داشبورد',
        description: ' قابلیت نمایش و مدیریت گزارشها مستقیماً در داشبورد'
      },
      {
        title: 'افزودن شروط',
        description: ' امکان فیلتر کردن دادهها بر اساس شرایط مشخص برای تحلیل دقیقتر'
      },
      {
        title: 'نمایش به صورت چارت',
        description: ' ارائه دادههای گزارش بهصورت نمودارهای گرافیکی برای درک بهتر'
      },
      {
        title: 'نماش Pivot',
        description: ' تحلیل و مشاهده دادهها بهصورت جداول Pivot برای بررسی چندبعدی'
      },
      {
        title: 'سطوح دسترسی داشبورد',
        description: ' تعریف و مدیریت سطح دسترسی کاربران به گزارشها و داشبورد'
      }
    ]);
    const BI_EN = ref([
      {
        title: ' Generating Various Reports',
        description: ' Create customized reports based on different data sets.'
      },
      {
        title: ' Create customized reports based on different data sets.',
        description: ' View and manage reports directly within the dashboard.'
      },
      {
        title: ' Adding Conditions',
        description: ' Filter data based on specific conditions for more precise analysis.'
      },
      {
        title: ' Chart Visualization',
        description: ' Present report data as graphical charts for better insights.'
      },
      {
        title: ' Pivot Table Display',
        description: ' Analyze and view data in pivot tables for multidimensional insights.'
      },
      {
        title: ' Dashboard Access Levels',
        description: ' Define and manage user access levels to reports and dashboards.'
      }
    ]);
    const calender_FA = ref([
      {
        title: 'مشاهده وظایف تخصیص داده شده',
        description: ' دسترسی به لیست وظایف اختصاصیافته به هر کاربر برای پیگیری بهتر'
      },
      {
        title: 'مشاهده سر رسید گارانتی/ وارانتی',
        description: ' پیگیری تاریخ انقضای گارانتی و وارانتی برای مدیریت خدمات پس از فروش'
      },
      {
        title: 'جابه جایی فعالیت ها',
        description: ' امکان تغییر ترتیب و اولویتبندی فعالیتها در فرآیندهای کاری'
      },
      {
        title: 'حالت های نمایش مختلف',
        description: ' مشاهده وظایف و فعالیتها در قالبهای متنوع مانند لیست، تقویم یا بورد'
      },
      {
        title: 'جست و جوی فعالیت ها',
        description: ' یافتن سریع فعالیتهای موردنظر بر اساس فیلترهای مختلف'
      },
      {
        title: 'مدیریت هشدار',
        description: ' تعریف و تنظیم اعلانها برای پیگیری وظایف و رویدادهای مهم'
      }
    ]);
    const calender_EN = ref([
      {
        title: ' View Assigned Tasks',
        description: 'Access a list of tasks assigned to each user for better tracking.'
      },
      {
        title: ' View Warranty/Guarantee Expiry',
        description: 'Track warranty and guarantee expiration dates for after-sales management.'
      },
      {
        title: ' Rearrange Activities',
        description: ' Modify the order and prioritization of activities in workflows. '
      },
      {
        title: ' Multiple Display Modes',
        description: ' View tasks and activities in various formats such as list, calendar, or board.'
      },
      {
        title: ' Search Activities',
        description: ' Quickly find specific activities using various filters .'
      },
      {
        title: ' Alert Management',
        description: ' Define and configure notifications for task tracking and important events.'
      }
    ]);


    const issueTracker = computed(() => (locale.value === 'fa' ? issueTracker_FA.value : issueTracker_EN.value));
    const EDMS = computed(() => (locale.value === 'fa' ? EDMS_FA.value : EDMS_EN.value));
    const projectManagement = computed(() => (locale.value === 'fa' ? projectManagement_FA.value : projectManagement_EN.value));
    const CRM = computed(() => (locale.value === 'fa' ? CRM_FA.value : CRM_EN.value));
    const wallet = computed(() => (locale.value === 'fa' ? wallet_FA.value : wallet_EN.value));
    const assessment = computed(() => (locale.value === 'fa' ? assessment_FA.value : assessment_EN.value));
    const KMS = computed(() => (locale.value === 'fa' ? KMS_FA.value : KMS_EN.value));
    const social = computed(() => (locale.value === 'fa' ? social_FA.value : social_EN.value));
    const meeting = computed(() => (locale.value === 'fa' ? meeting_FA.value : meeting_EN.value));
    const BPMS = computed(() => (locale.value === 'fa' ? BPMS_FA.value : BPMS_EN.value));
    const BI = computed(() => (locale.value === 'fa' ? BI_FA.value : BI_EN.value));
    const calender = computed(() => (locale.value === 'fa' ? calender_FA.value : calender_EN.value));

    return {
      issueTracker,
      EDMS,
      projectManagement,
      CRM,
      wallet,
      assessment,
      KMS,
      social,
      meeting,
      BPMS,
      BI,
      calender,
      t,
      locale,
    };
  },
  mounted() {
    this.$watch(
        () => this.$route.name,
        () => {
          this.scrollAnimation2();
        },
        {immediate: true}
    )
  },
  methods: {
    scrollAnimation2() {
      gsap.timeline({
        scrollTrigger: {
          trigger: "html",
          scrub: 0.2,
          start: "top top",
          end: "+=10000"
        }
      })
          .to(".aa-el", {
            rotation: 360 * 5,
            duration: 1,
            ease: "none"
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.aa-co {
  animation: float linear 2.5s infinite alternate;
  bottom: 28px;
  z-index: 11;
}

@keyframes float {
  0% {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(0, 30px, 0);
  }
}

.card-body-main {
  flex: unset;
  top: 64px;
}

.bg-primary {
  --td-primary-rgb: 68, 140, 116;
  --td-bg-opacity: 1;
  background-color: var(--color-2) !important;
}

.bg-third {
  background-color: #0c1015 !important;
}

.bg-opacity-10 {
  background-color: rgba(18, 21, 25, 0.10) !important;
}

.bg-dark {
  background-color: rgba(18, 21, 25, 1) !important;
}

.bg-noise {
  mix-blend-mode: soft-light;
  opacity: 12%;
  background-image: url("@/assets/images/tarsimate/noise.png");
}

.bg-secondary {
  background-color: rgb(32, 34, 36) !important;
  color: #ffffff;
}

.card-footer {
  padding: var(--ar-card-cap-padding-y) var(--ar-card-cap-padding-x);
  color: var(--ar-card-cap-color);
  background-color: transparent;
  border-top: var(--ar-card-border-width) solid var(--ar-card-border-color);
}

.border-light {
  border-color: rgba(255, 255, 255, .13) !important;
}

.main-title {
  font-family: Pelak, sans-serif;
}

img {
  margin: 0;
}

.bg-space {
  background-image: url("~@/assets/images/home/hero-bg-empty.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
}

.card.bg-secondary{
  background-image: url("~@/assets/images/home/hero-bg-empty.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

@media only screen and (min-width: 992px) {
  .position-lg-sticky {
    position: sticky !important;
  }
}

@media only screen and (max-width: 991px) {
  .content-section {
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 767px) {
  .content-section {
    .col-md-5 .card-body {
      .h2, p {
        text-align: center;
      }

      img {
        margin-right: auto;
        margin-left: auto;
      }
    }

    .col-md-7 {
      order: 2;
    }
  }
}
</style>